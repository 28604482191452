import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdAdd, MdCancel, MdFilterList } from "react-icons/md";

import "../../styles/CalenderConfig.css";
import { useNavigate } from "react-router";

export default function CalenderServiceCreateConfig({
  authToken,
  setCreateNewQuestion,
  feedData,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
  title,
  hours,
  minutes,
  index,
  removeServiceFromState,
  mutateObjectInState,
  durationHours,
  durationMinutes,
  priceEuro,
  priceCent,
  type,
  typeDetails,
}) {
  const doNothing = () => {
    return;
  };

  return (
    <div className="calenderConfig__scrollContent_contentBox_content">
      <div className="calenderConfig__scrollContent_contentBox_content_service">
        <div className="calenderConfig__scrollContent_contentBox_content_service_titleBox">
          <div className="calenderConfig__scrollContent_contentBox_content_service_titleBox_number">
            {index + 1}
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_inputBox">
            <input
              className="calenderConfig__scrollContent_contentBox_content_service_inputBox_input"
              value={title}
              placeholder="Titel eingeben"
              onChange={(e) =>
                mutateObjectInState(index, "title", e.target.value)
              }
            />
          </div>
          <div
            className="calenderConfig__scrollContent_contentBox_content_service_titleBox_cancelButton"
            onClick={() => removeServiceFromState(index)}
          >
            <CancelIcon />
          </div>
        </div>
        <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox">
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_text">
            Dauer
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox">
            <select
              value={durationHours}
              className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox_input"
              placeholder="00"
              onChange={(e) =>
                mutateObjectInState(index, "durationHours", e.target.value)
              }
            >
              {hours.map((time) => {
                return (
                  <option key={time} value={time}>
                    {time}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_text">
            h
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox">
            <select
              value={durationMinutes}
              className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox_input"
              placeholder="00"
              onChange={(e) =>
                mutateObjectInState(index, "durationMinutes", e.target.value)
              }
            >
              {minutes.map((time) => {
                return (
                  <option key={time} value={time}>
                    {time}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_text">
            min
          </div>
        </div>

        <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox">
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_text">
            Preis
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox">
            <input
              className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox_input"
              value={priceEuro}
              onChange={(e) =>
                mutateObjectInState(index, "priceEuro", e.target.value)
              }
            />
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_text">
            €
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox">
            <input
              className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBox_input"
              value={priceCent}
              onChange={(e) =>
                mutateObjectInState(index, "priceCent", e.target.value)
              }
            />
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_text">
            Cent
          </div>
        </div>

        <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox">
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_text">
            Terminart
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBoxLong">
            <select
              value={type}
              className="calenderConfig__scrollContent_contentBox_content_service_priceDurationBox_inputBoxLong_inputLong"
              placeholder="Bitte auswählen"
              onChange={(e) =>
                mutateObjectInState(index, "type", e.target.value)
              }
            >
              <option key={"Telefontermin"} value={"Telefontermin"}>
                {"Telefontermin"}
              </option>
              {/*        <option key={"beimKunden"} value={"beimKunden"}>
                {"Termin beim Kunden"}
              </option> */}

              <option key={"festeAdresse"} value={"festeAdresse"}>
                {"Termin an fester Adresse"}
              </option>
            </select>
          </div>
        </div>

        {type == "festeAdresse" ? (
          <div className="calenderConfig__scrollContent_contentBox_content_service_titleBox">
            <div className="calenderConfig__scrollContent_contentBox_content_service_titleBox_number">
              Adresse
            </div>
            <div className="calenderConfig__scrollContent_contentBox_content_service_inputBox">
              <input
                className="calenderConfig__scrollContent_contentBox_content_service_inputBox_input"
                value={typeDetails}
                onChange={(e) =>
                  mutateObjectInState(index, "typeDetails", e.target.value)
                }
              />
            </div>
          </div>
        ) : (
          <>
            {type == "Telefontermin" ? (
              <>
                Bei jeder Terminbuchung gibt dein Kunde seine Telefonnummer an,
                sodass du ihn zur Terminzeit anrufen kannst.
              </>
            ) : (
              <>Bei jeder Terminbuchung gibt dein Kunde seine Adresse an.</>
            )}
          </>
        )}
      </div>
    </div>
  );
}

const AddIcon = styled(MdAdd)`
  font-size: 20px;
  color: #575757;
  margin-top: 4px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 30px;
  color: #575757;
  margin-top: 4px;
`;
