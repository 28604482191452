import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  MdSearch,
  MdKeyboardArrowRight,
  MdArrowBack,
  MdFavoriteBorder,
  MdFavorite,
} from "react-icons/md";

import "../../styles/PostSingle.css";
import Question from "../content/Question";
import Expert from "../content/Expert";
import RelevantTag from "../content/RelevantTag";
import Answer from "../content/Answer";

export default function PostSingle({
  setDetailPage,
  type,
  feed,
  authToken,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  answerData,
  companyAnswers,
  setCompanyAnswers,
  refresh,
  setRefresh,
  likes,
  setLikes,
  uploadLike,
  refreshLikes,
  setRefreshLikes,
  refreshTags,
                  setRefreshTags
}) {
  const returnContent = () => {
    if (type == "question") {
      return (
        <Question
          feed={feed}
          authToken={authToken}
          key={"question" + feed.id}
          userData={userData}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
          refresh={refresh}
          setRefresh={setRefresh}
          likes={likes}
          setLikes={setLikes}
          refreshLikes={refreshLikes}
          setRefreshLikes={setRefreshLikes}
          refreshTags={refreshTags}
                  setRefreshTags={setRefreshTags}
        />
      );
    } else if (type == "expert") {
      return <Expert />;
    } else if (type == "tag") {
      return <RelevantTag />;
    } else if (type == "answer") {
      return (
        <Answer
          answerData={answerData}
          key={answerData.id}
          uploadLike={uploadLike}
          likes={likes}
        />
      );
    }
  };
  /* 
  Question, ExpertAvatar, RelevantTag wird unterschieden
  */

  return <div className="postsingle">{returnContent()}</div>;
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;

const LikeButtonOutlined = styled(MdFavoriteBorder)`
  font-size: 22px;
  color: #575757;
  margin-bottom: -10px;
`;

const LikeButtonFilled = styled(MdFavorite)`
  font-size: 22px;
  color: #1877f2;
  margin-bottom: -10px;
`;
