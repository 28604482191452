import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdArrowBack, MdFilterList } from "react-icons/md";

import "../styles/DetailAppointment.css";
import ContactPreview from "../components/contacts/ContactPreview";
import { useNavigate, useParams } from "react-router";
import getReadableTimeFromQuarterTimeFormat from "../functions/getReadableTimeFromQuarterTimeFormat";

export default function DetailAppointment({
  authToken,
  setCreateNewQuestion,
  feedData,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
  activeAppointment,
  setActiveAppointment,
  refresh,
  setRefresh,
  mobile,
  setMobile,
}) {
  const [isOwnerOfAppointment, setIsOwnerOfAppointment] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const [responseActiveArticle, setResponseActiveArticle] = useState("");
  const { id } = useParams();

let navigate = useNavigate();


/*
  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/search");
    }
  }, [authToken]);
  */

  const encryptID = (givenID) => {
    let givenIDString = givenID.toString();
    let length = givenIDString.length;
    let encryptedID = "";

    if(length == 5){
      encryptedID += givenIDString.substring(2,3);
    } else if(length == 7){
      encryptedID += givenIDString.substring(2,3);
      encryptedID += givenIDString.substring(4,5);
    }else if(length == 9){
      encryptedID += givenIDString.substring(2,3);
      encryptedID += givenIDString.substring(4,5);
      encryptedID += givenIDString.substring(6,7);
    }else if(length == 11){
      encryptedID += givenIDString.substring(2,3);
      encryptedID += givenIDString.substring(4,5);
      encryptedID += givenIDString.substring(6,7);
      encryptedID += givenIDString.substring(8,9);
    }else if(length == 13){
      encryptedID += givenIDString.substring(2,3);
      encryptedID += givenIDString.substring(4,5);
      encryptedID += givenIDString.substring(6,7);
      encryptedID += givenIDString.substring(8,9);
      encryptedID += givenIDString.substring(10,11);
    }else if(length == 15){
      encryptedID += givenIDString.substring(2,3);
      encryptedID += givenIDString.substring(4,5);
      encryptedID += givenIDString.substring(6,7);
      encryptedID += givenIDString.substring(8,9);
      encryptedID += givenIDString.substring(10,11);
      encryptedID += givenIDString.substring(12,13);
    }else if(length == 17){
      encryptedID += givenIDString.substring(2,3);
      encryptedID += givenIDString.substring(4,5);
      encryptedID += givenIDString.substring(6,7);
      encryptedID += givenIDString.substring(8,9);
      encryptedID += givenIDString.substring(10,11);
      encryptedID += givenIDString.substring(12,13);
      encryptedID += givenIDString.substring(14,15);
    }

    let finalEncryID = (parseInt(encryptedID) - 7 ) / 7;

    return finalEncryID;
  }

  // wenn man ueber id gekommen ist, dann soll gefetcht werden
 // get userData
 useEffect(async () => {
  if (!isEmpty(id) && isEmpty(activeAppointment)) {
    const res = await fetch("https://www.cloud.leefes.com/api/appointments/noAuthOnlyGET.php?id="+encryptID(id), {
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "GET",
      // body: JSON.stringify({
      //  companyname: "dancemonkey"
      // })
    });
    const resp = await res
      .text()
      .then((value) => setResponseActiveArticle(JSON.parse(value)));
  }
}, [activeAppointment, id]);

// userdata in state packen
useEffect(() => {
  if (responseActiveArticle !== "") {
    setActiveAppointment(responseActiveArticle[0]);
  }
}, [responseActiveArticle]);



  // checken ob man owner von activeappointment ist
  useEffect(() => {
    if (!isEmpty(companyData) && !isEmpty(activeAppointment)) {
      if (activeAppointment.ownerID == companyData[0].id) {
        setIsOwnerOfAppointment(true);
      } else {
        setIsOwnerOfAppointment(false);
      }
    }
    setShowDeleteButton(false);
  }, [activeAppointment, companyData]);

  // zeitdaten formatieren
  useEffect(() => {
    if (!isEmpty(activeAppointment)) {
      setStartTime(
        getReadableTimeFromQuarterTimeFormat(
          JSON.parse(activeAppointment.appointmentTimes)[0]
        )
      );
      let timeWithoutDuration = parseInt(
        JSON.parse(activeAppointment.appointmentTimes)[0]
      );
      let duration = JSON.parse(activeAppointment.appointmentTimes).length * 15;
      let timeWithDuration = timeWithoutDuration + duration;

      setEndTime(getReadableTimeFromQuarterTimeFormat(timeWithDuration));
    }
  }, [activeAppointment]);

  // absagen fetch
  const handleupload = async () => {
    if (
      !isEmpty(authToken) &&
      !isEmpty(activeAppointment) &&
      !isEmpty(companyData) &&
      isEmpty(id)
    ) {
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/appointments/?id=" +
          activeAppointment.id,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            canceledBy: companyData[0].id,
            status: 0,
          }),
        }
      );

      await resa.text().then(() => uploadEnds());
    } else if(!isEmpty(id) && !isEmpty(activeAppointment)){
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/appointments/noAuthPUTAppointment?id=" +
          activeAppointment.id,
        {
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            canceledBy: 0,
            status: 0,
          }),
        }
      );

      await resa.text().then(() => uploadEnds());
    }
  };

  const uploadEnds = () => {
    setShowDeleteButton(false);
    setActiveAppointment([]);
    setRefresh(!refresh);
  };

  if (isEmpty(activeAppointment)) {
    return (
      <div className="detailAppointment">
        <div className="detailAppointment__fixedTopBar">
          <div className="detailAppointment__fixedTopBar_leftColumn">
            {mobile && !isEmpty(activeAppointment) ? (
              <div onClick={() => setActiveAppointment([])}>
                <BackIcon />
              </div>
            ) : (
              <></>
            )}
            <div className="detailAppointment__fixedTopBar_leftColumn_topText">
              Termindetails
            </div>
          </div>
          <div className="detailAppointment__fixedTopBar_middleColumn"></div>
        </div>
        <div className="detailAppointment__scrollContent">
          <div className="detailAppointment__scrollContent_contentBox">
            <div className="detailAppointment__scrollContent_contentBox_title">
              Kein aktiver Termin ausgewählt.
              {!isEmpty(id)? (<div className="detailAppointment__scrollContent_contentBox_title_backToSearch"
              onClick={() => navigate("/search")}
              >zurück zur Suche</div>) : (<></>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="detailAppointment">
      {isOwnerOfAppointment ? (
        <div className="detailAppointment__fixedTopBar_blueHighlighted">
          <div className="detailAppointment__fixedTopBar_leftColumn">
            {mobile && isEmpty(id) && !isEmpty(activeAppointment)? (
              <div onClick={() => setActiveAppointment([])}>
                <BackIcon />
              </div>
            ) : (
              <></>
            )}
            <div className="detailAppointment__fixedTopBar_leftColumn_topText">
              {activeAppointment.serviceTitle}
            </div>
          </div>
          <div className="detailAppointment__fixedTopBar_middleColumn"></div>
        </div>
      ) : (
        <div className="detailAppointment__fixedTopBar_greenHighlighted">
          <div className="detailAppointment__fixedTopBar_leftColumn">
            {mobile && isEmpty(id) && !isEmpty(activeAppointment)? (
              <div onClick={() => setActiveAppointment([])}>
                <BackIcon />
              </div>
            ) : (
              <></>
            )}
            <div className="detailAppointment__fixedTopBar_leftColumn_topText">
              {activeAppointment.serviceTitle}
            </div>
          </div>
          <div className="detailAppointment__fixedTopBar_middleColumn"></div>
        </div>
      )}

      <div className="detailAppointment__scrollContent">
        {!isEmpty(id) ? (
          <>
          <div className="detailAppointment__scrollContent_contentBox">
              <div className="detailAppointment__scrollContent_contentBox_title">
                Anbieter/in
              </div>
              <div className="detailAppointment__scrollContent_contentBox_userBox">
                <div className="detailAppointment__scrollContent_contentBox_userBox_imageBox">
                  <img
                    className="detailAppointment__scrollContent_contentBox_userBox_imageBox_image"
                    src={
                      "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg"
                    }
                  />
                </div>
                <div className="detailAppointment__scrollContent_contentBox_userBox_name">
                  {activeAppointment.ownerName}
                </div>
              </div>
            </div>
            <div className="detailAppointment__scrollContent_contentBox">
              <div className="detailAppointment__scrollContent_contentBox_title">
                Teilnehmer/in
              </div>
              <div className="detailAppointment__scrollContent_contentBox_userBox">
                <div className="detailAppointment__scrollContent_contentBox_userBox_imageBox">
                  <img
                    className="detailAppointment__scrollContent_contentBox_userBox_imageBox_image"
                    src={
                      "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg"
                    }
                  />
                </div>
                <div className="detailAppointment__scrollContent_contentBox_userBox_name">
                  {activeAppointment.bookerName}
                </div>
              </div>
            </div>
          </>
        ) : (<>{isOwnerOfAppointment ? (
          <>
            <div className="detailAppointment__scrollContent_contentBox">
              <div className="detailAppointment__scrollContent_contentBox_title">
                Anbieter/in
              </div>
              <div className="detailAppointment__scrollConntent_contentBox_infoBox">
                {isOwnerOfAppointment ? (
                  <div className="detailAppointment__scrollConntent_contentBox_infoBox_blueInfoCircle"></div>
                ) : (
                  <div className="detailAppointment__scrollConntent_contentBox_infoBox_greenInfoCircle"></div>
                )}
                <div className="detailAppointment__scrollConntent_contentBox_infoBox_text">
                  Du bist <pre> </pre>
                  {isOwnerOfAppointment ? (
                    <div className="detailAppointment__scrollConntent_contentBox_infoBox_text_blueHighlighted">
                      Anbieter/in
                    </div>
                  ) : (
                    <div className="detailAppointment__scrollConntent_contentBox_infoBox_text_greenHighlighted">
                      Teilnehmer/in
                    </div>
                  )}
                  <pre> </pre>
                  dieses Termins
                </div>
              </div>
            </div>

            <div className="detailAppointment__scrollContent_contentBox">
              <div className="detailAppointment__scrollContent_contentBox_title">
                Teilnehmer/in
              </div>
              <div className="detailAppointment__scrollContent_contentBox_userBox">
                <div className="detailAppointment__scrollContent_contentBox_userBox_imageBox">
                  <img
                    className="detailAppointment__scrollContent_contentBox_userBox_imageBox_image"
                    src={
                      "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg"
                    }
                  />
                </div>
                <div className="detailAppointment__scrollContent_contentBox_userBox_name">
                  {activeAppointment.bookerName}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="detailAppointment__scrollContent_contentBox">
              <div className="detailAppointment__scrollContent_contentBox_title">
                Anbieter/in
              </div>
              <div className="detailAppointment__scrollContent_contentBox_userBox">
                <div className="detailAppointment__scrollContent_contentBox_userBox_imageBox">
                  <img
                    className="detailAppointment__scrollContent_contentBox_userBox_imageBox_image"
                    src={
                      "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg"
                    }
                  />
                </div>
                <div className="detailAppointment__scrollContent_contentBox_userBox_name">
                  {activeAppointment.ownerName}
                </div>
              </div>
            </div>

            <div className="detailAppointment__scrollContent_contentBox">
              <div className="detailAppointment__scrollContent_contentBox_title">
                Teilnehmer/in
              </div>
              <div className="detailAppointment__scrollConntent_contentBox_infoBox">
                {isOwnerOfAppointment ? (
                  <div className="detailAppointment__scrollConntent_contentBox_infoBox_blueInfoCircle"></div>
                ) : (
                  <div className="detailAppointment__scrollConntent_contentBox_infoBox_greenInfoCircle"></div>
                )}
                <div className="detailAppointment__scrollConntent_contentBox_infoBox_text">
                  Du bist <pre> </pre>
                  {isOwnerOfAppointment ? (
                    <div className="detailAppointment__scrollConntent_contentBox_infoBox_text_blueHighlighted">
                      Anbieter/in
                    </div>
                  ) : (
                    <div className="detailAppointment__scrollConntent_contentBox_infoBox_text_greenHighlighted">
                      Teilnehmer/in
                    </div>
                  )}
                  <pre> </pre>
                  dieses Termins
                </div>
              </div>
            </div>
          </>
        )}</>)}

        <div className="detailAppointment__scrollContent_contentBox">
          <div className="detailAppointment__scrollContent_contentBox_title">
            Uhrzeit & Datum
          </div>
          <div className="detailAppointment__scrollContent_contentBox_dateBox">
            {startTime + " - " + endTime}
            <br />{" "}
            {!isEmpty(activeAppointment) ? (
              <>
                {JSON.parse(activeAppointment.appointmentDay).thisDay +
                  "." +
                  JSON.parse(activeAppointment.appointmentDay).thisMonth +
                  "." +
                  JSON.parse(activeAppointment.appointmentDay).thisYear}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="detailAppointment__scrollContent_contentBox">
          <div className="detailAppointment__scrollContent_contentBox_title">
            Leistung
          </div>
          <div className="detailAppointment__scrollContent_contentBox_serviceBox">
            {activeAppointment.serviceTitle}
          </div>
        </div>
        {activeAppointment.appointmentType == "Telefontermin" ? (
          <>
            <div className="detailAppointment__scrollContent_contentBox">
              <div className="detailAppointment__scrollContent_contentBox_title">
                Ort
              </div>
              <div className="detailAppointment__scrollContent_contentBox_serviceBox">
                per Telefon
              </div>
            </div>
            <div className="detailAppointment__scrollContent_contentBox">
              <div className="detailAppointment__scrollContent_contentBox_title">
                übermittelte Notiz
              </div>
              <div className="detailAppointment__scrollContent_contentBox_noteBox">
                {!isEmpty(activeAppointment.bookerPhone) ? (
                  <>
                    Meine Telefonrufnummer lautet:
                    {activeAppointment.bookerPhone}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {activeAppointment.appointmentType == "festeAdresse" ? (
          <div className="detailAppointment__scrollContent_contentBox">
            <div className="detailAppointment__scrollContent_contentBox_title">
              Ort
            </div>
            <div className="detailAppointment__scrollContent_contentBox_serviceBox">
              {activeAppointment.appointmentDetails}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="detailAppointment__scrollContent_ctaBox">
          {showDeleteButton ? (
            <>
              <div
                className="detailAppointment__scrollContent_ctaBox_abort"
                onClick={() => setShowDeleteButton(false)}
              >
                widerrufen
              </div>
              <div
                className="detailAppointment__scrollContent_ctaBox_delete"
                onClick={() => handleupload()}
              >
                Absagen bestätigen
              </div>
            </>
          ) : (
            <div
              className="detailAppointment__scrollContent_ctaBox_cancel"
              onClick={() => setShowDeleteButton(true)}
            >
              Termin absagen
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 20px;
  color: #575757;
  margin-top: 4px;
`;

const FilterIcon = styled(MdFilterList)`
  font-size: 20px;
  color: #575757;
  margin-top: 1px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #ffffff;
  margin-top: 5px;
  margin-right: 15px;
`;
