import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  MdSearch,
  MdKeyboardArrowRight,
  MdArrowBack,
  MdOutbond,
  MdOutbound,
  MdPhone,
  MdOutlineOutbound,
} from "react-icons/md";

import "../../styles/AppointmentPreview.css";
import getReadableTimeFromQuarterTimeFormat from "../../functions/getReadableTimeFromQuarterTimeFormat";

export default function AppointmentPreview({
  contactData,
  appointmentData,
  activeAppointment,
  setActiveAppointment,
  companyData,
  openCalenderConfig,
  setOpenCalenderConfig,
}) {
  const handleClickOnAppointment = () => {
    setOpenCalenderConfig(false);
    setActiveAppointment(appointmentData);
  };

  if (appointmentData == activeAppointment) {
    return (
      <div
        className="appointmentPreview_active"
        onClick={() => handleClickOnAppointment()}
      >
        <div className="appointmentPreview_iconBox">
          {appointmentData.ownerID == companyData[0].id ? (
            <>
              {appointmentData.appointmentType == "Telefontermin" ? (
                <PhoneBlueIcon />
              ) : (
                <OutBoundBlueIcon />
              )}
            </>
          ) : (
            <>
              {appointmentData.appointmentType == "Telefontermin" ? (
                <PhoneGreenIcon />
              ) : (
                <OutBoundGreenIcon />
              )}
            </>
          )}
        </div>
        <div className="appointmentPreview_wrapper">
          <div className="appointmentPreview__left">
            <div className="appointmentPreview__left_top">
              {appointmentData.serviceTitle}
            </div>
            <div className="appointmentPreview__left_bottom">
              {appointmentData.ownerID == companyData[0].id
                ? appointmentData.bookerName
                : appointmentData.ownerName}
            </div>
          </div>
          <div className="appointmentPreview__right">
            <div className="appointmentPreview__right_top">
              {getReadableTimeFromQuarterTimeFormat(
                JSON.parse(appointmentData.appointmentTimes)[0]
              )}
            </div>
            <div className="appointmentPreview__right_bottom">
              {JSON.parse(appointmentData.appointmentDay).thisDay}.
              {JSON.parse(appointmentData.appointmentDay).thisMonth}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className="appointmentPreview"
      onClick={() => handleClickOnAppointment()}
    >
      <div className="appointmentPreview_iconBox">
        {appointmentData.ownerID == companyData[0].id ? (
          <>
            {appointmentData.appointmentType == "Telefontermin" ? (
              <PhoneBlueIcon />
            ) : (
              <OutBoundBlueIcon />
            )}
          </>
        ) : (
          <>
            {appointmentData.appointmentType == "Telefontermin" ? (
              <PhoneGreenIcon />
            ) : (
              <OutBoundGreenIcon />
            )}
          </>
        )}
      </div>
      <div className="appointmentPreview_wrapper">
        <div className="appointmentPreview__left">
          <div className="appointmentPreview__left_top">
            {appointmentData.serviceTitle}
          </div>
          <div className="appointmentPreview__left_bottom">
            {appointmentData.ownerID == companyData[0].id
              ? appointmentData.bookerName
              : appointmentData.ownerName}
          </div>
        </div>
        <div className="appointmentPreview__right">
          <div className="appointmentPreview__right_top">
            {getReadableTimeFromQuarterTimeFormat(
              JSON.parse(appointmentData.appointmentTimes)[0]
            )}
          </div>
          <div className="appointmentPreview__right_bottom">
            {JSON.parse(appointmentData.appointmentDay).thisDay}.
            {JSON.parse(appointmentData.appointmentDay).thisMonth}
          </div>
        </div>
      </div>
    </div>
  );
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;

const OutBoundBlueIcon = styled(MdOutlineOutbound)`
  font-size: 30px;
  color: #007fff;
`;

const OutBoundGreenIcon = styled(MdOutlineOutbound)`
  font-size: 30px;
  color: #009e15;
`;

const PhoneBlueIcon = styled(MdPhone)`
  font-size: 27px;
  color: #007fff;
`;

const PhoneGreenIcon = styled(MdPhone)`
  font-size: 27px;
  color: #009e15;
`;
