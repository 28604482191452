import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdAdd, MdCancel, MdFilterList } from "react-icons/md";

import "../../styles/CalenderConfig.css";
import { useNavigate } from "react-router";

export default function CalenderWeekdayConfig({
  authToken,
  setCreateNewQuestion,
  feedData,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
  day,
  active,
  startHours,
  startMinutes,
  endHours,
  endMinutes,
  hours,
  minutes,
  mutateObjectInWeekdays,
  index,
}) {
  const doNothing = () => {
    return;
  };

  return (
    <div className="calenderConfig__scrollContent_contentBox_content" key={day}>
      <div
        className="calenderConfig__scrollContent_contentBox_content_calenderActiveBox"
        onClick={() => mutateObjectInWeekdays(index, "active", "")}
      >
        <div className="calenderConfig__scrollContent_contentBox_content_calenderActiveBox_RadioButton">
          {active == "true" ? (
            <div className="calenderConfig__scrollContent_contentBox_content_calenderActiveBox_RadioButton_clickedBox"></div>
          ) : (
            <></>
          )}
        </div>
        <div className="calenderConfig__scrollContent_contentBox_content_calenderActiveBox_Text">
          {day}
        </div>
      </div>
      <div className="calenderConfig__scrollContent_contentBox_content_timeBox">
        
        <div className="calenderConfig__scrollContent_contentBox_content_timeBox_inputBox">
          <select
            value={startHours}
            className="calenderConfig__scrollContent_contentBox_content_timeBox_inputBox_input"
            placeholder="0"
            onChange={(e) =>
              mutateObjectInWeekdays(index, "startHours", e.target.value)
            }
          >
            {hours.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              );
            })}
          </select>
        </div>
        <div>:</div>
        <div className="calenderConfig__scrollContent_contentBox_content_timeBox_inputBox">
          <select
            value={startMinutes}
            className="calenderConfig__scrollContent_contentBox_content_timeBox_inputBox_input"
            onChange={(e) =>
              mutateObjectInWeekdays(index, "startMinutes", e.target.value)
            }
          >
            {minutes.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              );
            })}
          </select>
        </div>
        <div className="calenderConfig__scrollContent_contentBox_content_timeBox_text">
          
        </div>
        <div className="calenderConfig__scrollContent_contentBox_content_timeBox_text">
          -
        </div>
        <div className="calenderConfig__scrollContent_contentBox_content_timeBox_inputBox">
          <select
            value={endHours}
            className="calenderConfig__scrollContent_contentBox_content_timeBox_inputBox_input"
            placeholder="0"
            onChange={(e) =>
              mutateObjectInWeekdays(index, "endHours", e.target.value)
            }
          >
            {hours.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              );
            })}
          </select>
        </div>
        <div>:</div>
        <div className="calenderConfig__scrollContent_contentBox_content_timeBox_inputBox">
          <select
            value={endMinutes}
            className="calenderConfig__scrollContent_contentBox_content_timeBox_inputBox_input"
            placeholder="0"
            onChange={(e) =>
              mutateObjectInWeekdays(index, "endMinutes", e.target.value)
            }
          >
            {minutes.map((time) => {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              );
            })}
          </select>
        </div>
        <div className="calenderConfig__scrollContent_contentBox_content_timeBox_text">
          Uhr
        </div>
      </div>
    </div>
  );
}

const AddIcon = styled(MdAdd)`
  font-size: 20px;
  color: #575757;
  margin-top: 4px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 30px;
  color: #575757;
  margin-top: 4px;
`;
