import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../../styles/RelevantTag.css";

export default function RelevantTag({ store }) {
  return (
    <div className="relevantTag">
      <div className="relevantTag_hashtagBox">
        <div className="relevantTag_hashtagBox_hashtag">#</div>
      </div>
      <div className="relevantTag_description">
        <div className="relevantTag_description_name">Therapeuten</div>
        <div className="relevantTag_description_expertFor">
          <div className="relevantTag_description_expertFor_text">
            Experten für
          </div>
          <div className="relevantTag_description_expertFor_tag">Schmerzen</div>
          <div className="relevantTag_description_expertFor_tag">Schmerzen</div>
          <div className="relevantTag_description_expertFor_tag">Schmerzen</div>
        </div>
      </div>
    </div>
  );
}
const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;

const QuestionAnswerIconActive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #303030;
`;

const SearchIconInactive = styled(MdSearch)`
  font-size: 25px;
  color: #575757;
`;

const SearchIconActive = styled(MdSearch)`
  font-size: 25px;
  color: #303030;
`;

const ContactsIconInactive = styled(MdPeopleOutline)`
  font-size: 25px;
  color: #575757;
`;

const ContactsIconActive = styled(MdPeople)`
  font-size: 25px;
  color: #303030;
`;

const MoreIconInactive = styled(MdMore)`
  font-size: 25px;
  color: #575757;
`;

const MoreIconActive = styled(MdMore)`
  font-size: 25px;
  color: #303030;
`;

const ReputationIconInactive = styled(MdStarBorder)`
  font-size: 25px;
  color: #575757;
`;

const ReputationIconActive = styled(MdStar)`
  font-size: 25px;
  color: #303030;
`;
