import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  MdSearch,
  MdKeyboardArrowRight,
  MdArrowBack,
  MdFavoriteBorder,
  MdFavorite,
  MdImage,
  MdOutlineImage,
  MdCancel,
} from "react-icons/md";

import "../../styles/Question.css";
import { isEmpty } from "../../functions/isEmpty";
import { getTodayAsUnixDate } from "../../functions/getTodayAsUnixDate";
import getTextTrimmed from "../../functions/getTextTrimmed";

export default function Question({
  setDetailPage,
  exitData,
  feed,
  authToken,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
  refresh,
  setRefresh,
  likes,
  setLikes,
  refreshLikes,
  setRefreshLikes,
  refreshTags,
  setRefreshTags,
}) {
  const [localAnswer, setLocalAnswer] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [localAddCountToLike, setLocalAddCountToLike] = useState(0);
  const [imageUploads, setImageUploads] = useState([]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (!isEmpty(likes)) {
      likes.map((like) => {
        if (like.postID == feed.id && like.answerID == feed.topAnswerID) {
          setIsLiked(true);
        }
      });
    }
  }, [likes]);

  const activateEnterButton = () => {
    let submitButton = document.getElementById("submit" + feed.id);
    let inputField = document.getElementById("input" + feed.id);
    console.log(inputField.innerHTML);
    submitButton.style.display = "flex";
  };
  const deactivateEnterButton = () => {
    let submitButton = document.getElementById("submit" + feed.id);
    let inputField = document.getElementById("input" + feed.id);
    console.log(inputField.innerHTML);

    if (inputField.innerHTML == "") {
      submitButton.style.display = "none";
    }
  };

  /*
  const handleUpload = async (e) => {
    e.preventDefault();

    if (submitButton !== null) {
      submitButton.disabled = true;
    }
    const files = document.querySelector("[type=file]").files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${store?.JWT}`,
      },
      method: "POST",
      body: formData,
    });

    await res.text().then((value) => uploadToDatabase(value));
  };

  let history = useHistory();

  const uploadToDatabase = async (imgurl) => {
    await fetch("https://www.menschmeierapi.exconsilio.com/posts/", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        title: title,
        subtitle: subtitle,
        text: text,
        sourceURL: sourceURL,
        sourceText: sourceText,
        sourceDescription: sourceDescription,
        imgURL: imgurl,
        createdAt: getTodayAsUnixDate(),
        creatorID: userData.id ? userData.id : "0",
        bubbleSlogan: bubbleSlogan,
        category: category,
        status: active,
      }),
    }).then(() => history.push("/dashboard/posts"));
  };

  */

  const uploadLike = async () => {
    if (isLiked) {
      // update status zu 0, unlike
      let status = 0;

      let now = getTodayAsUnixDate();
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/likes/PUTandPOSTLikes.php",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            likerID: companyData[0].id,
            answerID: feed.topAnswerID,
            postID: feed.id,
          }),
        }
      );

      await resa.text().then(() => setRefreshLikes(!refreshLikes));
      setIsLiked(false);
      setLocalAddCountToLike(localAddCountToLike - 1);
    } else {
      // erstellen neuer like
      let status = 1;

      let now = getTodayAsUnixDate();
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/likes/PUTandPOSTLikes.php",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            likerID: companyData[0].id,
            answerID: feed.topAnswerID,
            postID: feed.id,
            status: status,
            createdAt: now,
          }),
        }
      );

      await resa.text().then(() => setRefreshLikes(!refreshLikes));
      setIsLiked(true);
      setLocalAddCountToLike(localAddCountToLike + 1);
    }
  };

  const handleUploadForImage = async () => {
    setIsUploading(true);

    const files = document.getElementById("imgInp" + feed.id).files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(
        "https://www.cloud.leefes.com/api/images/process.php",
        {
          headers: {
            Authorization: `Bearer ${authToken?.JWT}`,
          },
          method: "POST",
          body: formData,
        }
      );

      await res.text().then((value) => handleuploadJSON(value));
    } else {
      handleuploadJSON("");
    }
  };

  // upload der answer
  const handleuploadJSON = async (imgURL) => {
    let inputFieldText = document.getElementById("input" + feed.id).innerHTML;

    let submitButton = document.getElementById("submit" + feed.id);
    submitButton.className =
      "question__scrollContent_element_submitButtonBox_submitButton_disabled";

    let companyIMG = JSON.stringify([
      JSON.parse(companyData[0].profileImageURL)[0],
    ]);

    const resa = await fetch(
      "https://www.cloud.leefes.com/api/answers/newAnswer.php",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          postID: feed.id,
          companyname: companyData[0].companyname,
          likesCount: 0,
          companyID: companyData[0].id,
          text: inputFieldText,
          imageURL: imgURL,
          companyProfileImageURL: companyIMG,
          postTitle: feed.title,
          postTag1Title: feed.tag1Title,
          postTag2Title: feed.tag2Title,
          postTag3Title: feed.tag3Title,
          postTag1ID: feed.tag1ID,
          postTag2ID: feed.tag2ID,
          postTag3ID: feed.tag3ID,
        }),
      }
    );

    await resa.text().then(() => secondUpload(imgURL));
  };

  //upload der tagdaten in companydata
  const secondUpload = async (imgURL) => {
    // hier wird die tagid hinzugefügt zum expertForTags in companydata
    // wenn die id noch nicht vorhanden ist, dann wird die gepusht. wenn vorhanden, einfach erhöht.

    const addOneToIDs = (id1, id2, id3, id1Title, id2Title, id3Title) => {
      let helpArray = JSON.parse(companyData[0].expertForTags);
      let helpArray2 = [];
      let id1IsNew = true;
      let id2IsNew = true;
      let id3IsNew = true;
      helpArray.map((element) => {
        // es wird gemapt und gesucht, wenn gefunden, um eins erhöht.
        if (element.id == id1 || element.id == id2 || element.id == id3) {
          helpArray2.push({
            id: element.id,
            count: element.count + 1,
            title: element.title,
          });
          // schauen ob ein tag dabei ist, der noch garnicht in expertForTags in companydata enthalten ist
          if (element.id == id1) {
            id1IsNew = false;
          }
          if (element.id == id2) {
            id2IsNew = false;
          }
          if (element.id == id3) {
            id3IsNew = false;
          }
        } else {
          helpArray2.push({
            id: element.id,
            count: element.count,
            title: element.title,
          });
        }
      });

      if (id1IsNew) {
        helpArray2.push({
          id: id1,
          count: 1,
          title: id1Title,
        });
      }
      if (id2IsNew) {
        helpArray2.push({
          id: id2,
          count: 1,
          title: id2Title,
        });
      }
      if (id3IsNew) {
        helpArray2.push({
          id: id3,
          count: 1,
          title: id3Title,
        });
      }

      return JSON.stringify(helpArray2);
    };
    let expertsForPlusOne = addOneToIDs(
      feed.tag1ID,
      feed.tag2ID,
      feed.tag3ID,
      feed.tag1Title,
      feed.tag2Title,
      feed.tag3Title
    );

    // wenn garkein bild angehängt ist an die antwort,
    // dann soll auch kein bild in das array gepusht werden
    let helpArrayImages = [];
    if (!isEmpty(JSON.parse(companyData[0].postImagesURL))) {
      for (
        let i = 0;
        i < JSON.parse(companyData[0].postImagesURL).length;
        i++
      ) {
        helpArrayImages.push(JSON.parse(companyData[0].postImagesURL)[i]);
      }
    }
    if (imgURL != "") {
      helpArrayImages.push(imgURL);

      const resa = await fetch(
        "https://www.cloud.leefes.com/api/companies/?id=" + companyData[0].id,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            expertForTags: expertsForPlusOne,
            postImagesURL: JSON.stringify(helpArrayImages),
          }),
        }
      );

      await resa.text().then(() => thirdUpload(imgURL));
    } else {
      thirdUpload(imgURL);
    }
  };

  // postdata neue topanswer einsetzen
  const thirdUpload = async (imgURL) => {
    let companyIMG = JSON.stringify([
      JSON.parse(companyData[0].profileImageURL)[0],
    ]);
    let inputFieldText = document.getElementById("input" + feed.id).innerHTML;

    if (isEmpty(feed.topAnswerID)) {
      // wenn es die erste antwort ist
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/posts/?id=" + feed.id,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            topAnswerID: companyData[0].id,
            topAnswerName: companyData[0].companyname,
            topAnswerProfileImage: companyIMG,
            topAnswerText: inputFieldText,
            topAnswerLikes: 0,
            topAnswerImages: imgURL,
          }),
        }
      );

      await resa.text().then(() => uploadEnds(imgURL));
    } else {
      uploadEnds();
    }
  };

  const uploadEnds = (imgURL) => {
    setRefreshTags(!refreshTags);
    let submitButton = document.getElementById("submit" + feed.id);
    let inputFieldText = document.getElementById("input" + feed.id).innerHTML;

    var timeNowUnix = getTodayAsUnixDate();
    // setRefresh(!refresh);

    let companyIMG = JSON.stringify(
      JSON.stringify([JSON.parse(companyData[0].profileImageURL)[0]])
    );
    let helpAnswer = [];
    let ownAnswerObjectString = JSON.stringify({
      id: timeNowUnix,
      createdAt: timeNowUnix,
      likesCount: 0,
      postID: feed.id,
      companyname: companyData[0].companyname,
      likesCount: 0,
      companyID: companyData[0].id,
      text: inputFieldText,
      imageURL: imgURL,
      companyProfileImageURL: companyData[0].profileImageURL,
      postTitle: feed.title,
      postTag1Title: feed.tag1Title,
      postTag2Title: feed.tag2Title,
      postTag3Title: feed.tag3Title,
    });

    let helpArray = companyAnswers;
    helpArray.unshift(JSON.parse(ownAnswerObjectString));
    console.log(helpArray);

    setCompanyAnswers(helpArray);

    setLocalAnswer(inputFieldText);

    setNumberOfPosts(numberOfPosts + 1);
    setIsUploading(false);
    /*
    document.getElementById("input" + feed.id).innerHTML = "";
    submitButton.className =
      "question__scrollContent_element_submitButtonBox_submitButton";

    let helpArray = [];
    let numberOfPostsCounter;
    companyData.forEach((element) => {
      Object.entries(element).forEach(([key, value]) => {
        if (key == "numberOfPosts") {
          console.log(`${key} ${value}`);
        }
      });
    });
    */
  };

  function htmlToElements(html) {
    html = html.split("<div><br></div>").join("\n");
    html = html.split("<br>").join("\n");
    html = html.split("<div>").join("\n");
    html = html.split("</div>").join("");

    return html;
  }

  const openArticleAndSetActiveArticle = () => {
    setOpenArticle(true);
    setActiveArticle(feed);
  };

  const changeColorOfBackgroundToGrey = () => {
    /*
    let questionElement = document.getElementById("question" + feed.id);
    let topAnswerElement = document.getElementById("answertext" + feed.id);
    questionElement.style.backgroundColor = "#f5f5f5";
    topAnswerElement.style.backgroundColor = "#f5f5f5";
    */
  };

  const changeColorOfBackgroundToWhite = () => {
    /*
    let questionElement = document.getElementById("question" + feed.id);
    let topAnswerElement = document.getElementById("answertext" + feed.id);
    questionElement.style.backgroundColor = "#ffffff";
    topAnswerElement.style.backgroundColor = "#ffffff";
    */
  };

  const insertLocalImage = (e) => {
    let file = e.target.files[0];
    console.log(file);
    setImageUploads({
      localURL: URL.createObjectURL(file),
      files: file,
    });
  };

  return (
    <div className="question">
      <div className="question__scrollContent_element">
        <div className="question__scrollContent_element_topBar">
          <div className="question__scrollContent_element_topBar_tags">
            {feed.tag1Title != "" ? (
              <div className="question__scrollContent_element_topBar_tags_tag">
                {feed.tag1Title}
              </div>
            ) : (
              <></>
            )}
            {feed.tag2Title != "" ? (
              <div className="question__scrollContent_element_topBar_tags_tag">
                {feed.tag2Title}
              </div>
            ) : (
              <></>
            )}
            {feed.tag3Title != "" ? (
              <div className="question__scrollContent_element_topBar_tags_tag">
                {feed.tag3Title}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="question__scrollContent_element_topBar_moreButton">
            ...
          </div>
        </div>
        <div
          className="question__scrollContent_element_question"
          onClick={() => openArticleAndSetActiveArticle()}
          onMouseOver={() => changeColorOfBackgroundToGrey()}
          onMouseOut={() => changeColorOfBackgroundToWhite()}
          id={"question" + feed.id}
        >
          {!isEmpty(feed) ? feed.title : <></>}
        </div>
        {isEmpty(feed.topAnswerID) ? (
          <>
            {localAnswer == "" ? (
              <div
                className="question__scrollContent_element_answer"
                id={"answer" + feed.id}
              >
                <div
                  className="question__scrollContent_element_answer_answerText"
                  onClick={() => openArticleAndSetActiveArticle()}
                  onMouseOver={() => changeColorOfBackgroundToGrey()}
                  onMouseOut={() => changeColorOfBackgroundToWhite()}
                  id={"answertext" + feed.id}
                >
                  Noch keine Antwort. Hilf anderen mit deiner Antwort.
                </div>
                <div className="question__scrollContent_element_answer_buttonBox"></div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div
            className="question__scrollContent_element_answer"
            id={"answer" + feed.id}
          >
            <div className="question__scrollContent_element_answer_answererProfilepicbox">
              {!isEmpty(feed.topAnswerProfileImage) ? (
                <img
                  className="question__scrollContent_element_answer_answererProfilepicbox_image"
                  src={JSON.parse(feed.topAnswerProfileImage)[0].url}
                />
              ) : (
                <img
                  className="question__scrollContent_element_answer_answererProfilepicbox_image"
                  src=""
                />
              )}
            </div>
            <div
              className="question__scrollContent_element_answer_answerText"
              onClick={() => openArticleAndSetActiveArticle()}
              onMouseOver={() => changeColorOfBackgroundToGrey()}
              onMouseOut={() => changeColorOfBackgroundToWhite()}
              id={"answertext" + feed.id}
            >
              {!isEmpty(feed) ? feed.topAnswerName : <></>}{" "}
              {!isEmpty(feed.topAnswerImages) ? (
                <img
                  src={feed.topAnswerImages}
                  className="question__scrollContent_element_answer_answerText_image"
                />
              ) : (
                <></>
              )}
              <br />{" "}
              {!isEmpty(feed) ? (
                <pre>{getTextTrimmed(htmlToElements(feed.topAnswerText))}</pre>
              ) : (
                <></>
              )}
            </div>
            <div className="question__scrollContent_element_answer_buttonBox">
              {isLiked ? (
                <div
                  className="question__scrollContent_element_answer_buttonBox_button"
                  onClick={() => uploadLike()}
                >
                  <div>
                    <LikeButtonFilled />
                  </div>
                  <div>
                    {parseInt(feed.topAnswerLikes) + localAddCountToLike}
                  </div>
                </div>
              ) : (
                <div
                  className="question__scrollContent_element_answer_buttonBox_button"
                  onClick={() => uploadLike()}
                >
                  <div>
                    <LikeButtonOutlined />
                  </div>
                  <div>
                    {parseInt(feed.topAnswerLikes) + localAddCountToLike}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {localAnswer != "" ? (
          <div
            className="question__scrollContent_element_answer"
            onClick={() => openArticleAndSetActiveArticle()}
          >
            <div className="question__scrollContent_element_answer_answererProfilepicbox">
              <img
                className="question__scrollContent_element_answer_answererProfilepicbox_image"
                src={JSON.parse(companyData[0].profileImageURL)[0].url}
              />
            </div>
            <div className="question__scrollContent_element_answer_answerText">
              {companyData[0].companyname}
              <br /> <pre>{htmlToElements(localAnswer)}</pre>
            </div>
          </div>
        ) : (
          <></>
        )}

        {localAnswer == "" ? (
          <div className="question__scrollContent_element_reply">
            <div className="question__scrollContent_element_reply_ownImageThumbnailbox">
              <img
                className="question__scrollContent_element_reply_ownImageThumbnailbox_image"
                src={JSON.parse(companyData[0].profileImageURL)[0].url}
              />
            </div>

            <div className="question__scrollContent_element_replyBox">
              {!isEmpty(imageUploads) ? (
                <div className="question__scrollContent_element_reply_imagePreviewbox">
                  <img
                    src={imageUploads.localURL}
                    className="question__scrollContent_element_reply_imagePreviewbox_imagePreview"
                  />
                  <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_delete">
                    <CancelIcon onClick={() => setImageUploads([])} />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="question__scrollContent_element_replyBox_input_flexWrapper">
                <span
                  className="question__scrollContent_element_replyBox_input"
                  placeholder="Frage beantworten"
                  onFocus={() => activateEnterButton()}
                  onBlur={() => deactivateEnterButton()}
                  contentEditable={"true"}
                  role={"textbox"}
                  id={"input" + feed.id}
                ></span>
                <label forhtml={`imgInp` + feed.id} className="">
                  <div className="question__scrollContent_element_replyBox_addImage">
                    <input
                      accept=".jfif, .pjpeg, .pjp, .jpeg, .jpg, .png, .webp"
                      type="file"
                      id={"imgInp" + feed.id}
                      name="files[]"
                      className="question__scrollContent_element_replyBox_addImage_input"
                      onChange={(e) => insertLocalImage(e)}
                    />
                    <label
                      htmlFor={"imgInp" + feed.id}
                      className="question__scrollContent_element_replyBox_addImage_label"
                    >
                      <ImageIcon />
                    </label>
                  </div>
                </label>
              </div>
            </div>
            {isUploading ? (
              <div className="question__scrollContent_element_submitButtonBox">
                <div
                  className="question__scrollContent_element_submitButtonBox_submitButton_disabled"
                  id={"submit" + feed.id}
                >
                  senden
                </div>
              </div>
            ) : (
              <div className="question__scrollContent_element_submitButtonBox">
                <div
                  className="question__scrollContent_element_submitButtonBox_submitButton"
                  id={"submit" + feed.id}
                  onClick={() => handleUploadForImage()}
                >
                  senden
                </div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;

const ImageIcon = styled(MdOutlineImage)`
  font-size: 30px;
  color: #659de6;
  margin-bottom: 5px;
`;

const LikeButtonOutlined = styled(MdFavoriteBorder)`
  font-size: 22px;
  color: #575757;
  margin-bottom: -10px;
`;

const LikeButtonFilled = styled(MdFavorite)`
  font-size: 22px;
  color: #1877f2;
  margin-bottom: -10px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 28px;
  color: #575757;
  margin-bottom: 1px;
`;
