import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdArrowBack, MdFilterList, MdSearch } from "react-icons/md";

import "../styles/Register.css";
import PostThemebox from "../components/posts/PostThemebox";
import CTA from "../components/buttons/CTA";
import { useNavigate } from "react-router";

export default function Login({
  authToken,
  email,
  setEmail,
  password,
  setPassword,
  signIn,
  setOpenLogin,
  setOpenRegister,
  isUploading,
  setIsUploading,

  mobile,
  setOpenSettings,
}) {
  const [formIsFilled, setFormIsFilled] = useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    if (!isEmpty(authToken)) {
      setOpenLogin(false);
      setOpenRegister(false);
      navigate("/");
    }
  }, [authToken]);

  useEffect(() => {
    if (isEmpty(email) || isEmpty(password)) {
      setFormIsFilled(false);
    } else {
      setFormIsFilled(true);
    }
  }, [email, password]);

  const signInAndDisableButton = () => {
    setIsUploading(true);
    signIn();
  };
  const openSettings = () => {
    setOpenLogin(false);
    setOpenRegister(false);
    setOpenSettings(true);
  };

  return (
    <div className="register">
      <div className="register__fixedTopBar">
        <div onClick={() => setOpenLogin(false)}>
          <BackIcon />
        </div>
        <div className="register__fixedTopBar_text">Login</div>
      </div>
      <div className="register__scrollContent">
        <div className="register__scrollContent_nameBox">
          <div className="register__scrollContent_nameBox_nameText">
            Einloggen
          </div>
          <div className="register__scrollContent_nameBox_nameDescription">
            Melde dich mit deinen Logindaten an.
          </div>
          <div className="register__scrollContent_nameBox_nameInputbox">
            <input
              className="register__scrollContent_nameBox_nameInputbox_input"
              placeholder="Emailadresse eingeben"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="register__scrollContent_nameBox_nameInputbox">
            <input
              className="register__scrollContent_nameBox_nameInputbox_input"
              placeholder="Passwort eingeben"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          {formIsFilled ? (
            <>
              {!isUploading ? (
                <div
                  className="register__scollContent_CTAbox"
                  onClick={() => signInAndDisableButton()}
                >
                  <CTA text="Einloggen" />
                </div>
              ) : (
                <div className="register__scollContent_CTAbox">
                  <CTA text="Einloggen" type="disabled" />
                </div>
              )}
            </>
          ) : (
            <div className="register__scollContent_CTAbox">
              <CTA text="Einloggen" type="disabled" />
            </div>
          )}
        </div>

        {mobile && isEmpty(authToken) ? (
          <div className="search__infoLinks">
            <div
              className="sidebar__footer_links"
              onClick={() => openSettings()}
            >
              Impressum. Datenschutz.
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 20px;
  color: #000000;
  margin-top: -1px;
  margin-left: 3px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 30px;
  color: #000;
  padding-right: 10px;
  padding-top: 7px;
`;
