import React, { useState } from "react";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";
import styled from "styled-components/macro";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";

import "../../styles/Columns.css";
import { useEffect } from "react";
import { isEmpty } from "../../functions/isEmpty";

export default function LeftColumn({
  setCreateNewQuestion,
  setOpenLogin,
  setOpenRegister,
  setOpenSettings,
  authToken,
  setOpenArticle,
  refresh,
  setRefresh,
  setOpenCalenderConfig,
  setInfoData,
  activePage,
  setActivePage,
  mobile,
  setMobile,
  openLogin,
  openRegister,
  lightRoomData,
}) {
  let navigate = useNavigate();
  const pathname = window.location.pathname;
  const { id } = useParams();
  const [noBottomBar, setNoBottomBar] = useState(false);

  const handleNavClick = (pfad) => {
    setCreateNewQuestion(false);
    setOpenLogin(false);
    setOpenRegister(false);
    setOpenSettings(false);
    setOpenArticle(false);
    setOpenCalenderConfig(false);
    setRefresh(!refresh);
    setInfoData([]);
    navigate(pfad);
  };

  useEffect(() => {
    if (pathname.substring(pathname.length - 8) == "/contact") {
      setNoBottomBar(true);
    } else {
      setNoBottomBar(false);
    }
  }, [authToken, pathname]);

  useEffect(() => {
    let loggedIn = document.getElementById("topBarLoggedIn");
    let loggedOut = document.getElementById("topBarLoggedOut");
    if (getComputedStyle(document.documentElement).getPropertyValue("--sab")) {
    }
  }, [authToken, openLogin, openRegister]);

  const handleOpenLogin = () => {
    setOpenRegister(false);
    setOpenLogin(true);
  };

  const handleOpenRegister = () => {
    setOpenLogin(false);
    setOpenRegister(true);
  };

  return (
    <>
      {!openLogin && !openRegister && isEmpty(lightRoomData) && !noBottomBar ? (
        <div className="topNavBarOnlyOnMobile">
          {isEmpty(authToken) ? (
            <div
              className="topNavBarOnlyOnMobile__loggedOut"
              id={"topBarLoggedOut"}
            >
              <div className="topNavBarOnlyOnMobile__loggedOut_left">
                <div
                  className="topNavBarOnlyOnMobile__loggedOut_left_navLink"
                  onClick={() => navigate("/search")}
                >
                  exit925
                </div>
              </div>
              <div className="topNavBarOnlyOnMobile__loggedOut_right">
                <div
                  className="topNavBarOnlyOnMobile__loggedOut_right_login"
                  onClick={() => handleOpenLogin()}
                >
                  Einloggen
                </div>
                <div
                  className="topNavBarOnlyOnMobile__loggedOut_right_register"
                  onClick={() => handleOpenRegister()}
                >
                  Registrieren
                </div>
              </div>
            </div>
          ) : (
            <div
              className="topNavBarOnlyOnMobile__loggedIn"
              id={"topBarLoggedIn"}
            >
              <div
                className="topNavBarOnlyOnMobile__loggedIn_col"
                onClick={() => handleNavClick(`/`)}
              >
                {activePage == "" ? (
                  <>
                    <QuestionAnswerIconActive />
                    <div className="medium">Fragen</div>
                  </>
                ) : (
                  <>
                    <QuestionAnswerIconInactive />
                    Fragen
                  </>
                )}
              </div>
              <div
                className="topNavBarOnlyOnMobile__loggedIn_col"
                onClick={() => handleNavClick(`/search`)}
              >
                {activePage == "search" ? (
                  <>
                    <SearchIconActive />
                    <div className="medium">Suche</div>
                  </>
                ) : (
                  <>
                    <SearchIconInactive />
                    Suche
                  </>
                )}
              </div>
              <div
                className="topNavBarOnlyOnMobile__loggedIn_col"
                onClick={() => handleNavClick(`/contacts`)}
              >
                {activePage == "contacts" ? (
                  <>
                    <ContactsIconActive />
                    <div className="medium">Termine</div>
                  </>
                ) : (
                  <>
                    <ContactsIconInactive />
                    Termine
                  </>
                )}
              </div>
              <div
                className="topNavBarOnlyOnMobile__loggedIn_col"
                onClick={() => handleNavClick(`/profile`)}
              >
                {activePage == "profile" ? (
                  <>
                    <ReputationIconActive />
                    <div className="medium">Profil</div>
                  </>
                ) : (
                  <>
                    <ReputationIconInactive />
                    Profil
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className="leftColumn">
        <Sidebar
          authToken={authToken}
          setCreateNewQuestion={setCreateNewQuestion}
          setOpenLogin={setOpenLogin}
          setOpenRegister={setOpenRegister}
          setOpenSettings={setOpenSettings}
          setOpenArticle={setOpenArticle}
          refresh={refresh}
          setRefresh={setRefresh}
          setOpenCalenderConfig={setOpenCalenderConfig}
          setInfoData={setInfoData}
          activePage={activePage}
          setActivePage={setActivePage}
          mobile={mobile}
          setMobile={setMobile}
        />
      </div>
    </>
  );
}

const SearchIcon = styled(MdSearch)`
  font-size: 25px;
  color: #333333;
`;

const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;

const QuestionAnswerIconActive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #303030;
`;

const SearchIconInactive = styled(MdSearch)`
  font-size: 25px;
  color: #575757;
`;

const SearchIconActive = styled(MdSearch)`
  font-size: 25px;
  color: #303030;
`;

const ContactsIconInactive = styled(MdPeopleOutline)`
  font-size: 25px;
  color: #575757;
`;

const ContactsIconActive = styled(MdPeople)`
  font-size: 25px;
  color: #303030;
`;

const MoreIconInactive = styled(MdMore)`
  font-size: 25px;
  color: #575757;
`;

const MoreIconActive = styled(MdMore)`
  font-size: 25px;
  color: #303030;
`;

const ReputationIconInactive = styled(MdStarBorder)`
  font-size: 25px;
  color: #575757;
`;

const ReputationIconActive = styled(MdStar)`
  font-size: 25px;
  color: #303030;
`;
