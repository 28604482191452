import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../../styles/ExpertsSidebar.css";

export default function ExpertsSidebar({ store }) {

  return (
    <div className="expertsSidebar__bottom_similarExperts_expert">
    <div className="expertsSidebar__bottom_similarExperts_expert_imagebox">
      <img
        className="expertsSidebar__bottom_similarExperts_expert_imagebox_image"
        src="https://cdn.discordapp.com/attachments/832697220406181924/1075516311045681173/IMG_8206.png"
      />
    </div>
    <div className="expertsSidebar__bottom_similarExperts_expert_description">
      <div className="expertsSidebar__bottom_similarExperts_expert_description_name">
        Physiotherapie E. Sazegar
      </div>
      <div className="expertsSidebar__bottom_similarExperts_expert_description_expertFor">
        <div className="expertsSidebar__bottom_similarExperts_expert_description_expertFor_text">
          Experte für
        </div>
        <div className="expertsSidebar__bottom_similarExperts_expert_description_expertFor_tag">
          Schmerzen
        </div>
      </div>
    </div>
  </div>
  );
}
const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;
