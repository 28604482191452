export default function getReadableTimeFromQuarterTimeFormat(time) {
  const hour = Math.floor(time / 60);
  let hour2 = "";
  if (hour < 10) {
    hour2 = "0";
  }
  const minutes = time % 60;
  let minutes2 = "";
  if (minutes < 1) {
    minutes2 = "0";
  }
  return hour2 + hour + ":" + minutes2 + minutes;
}
