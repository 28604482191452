import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import {
  MdAdd,
  MdArrowBack,
  MdCancel,
  MdFilterList,
  MdSearch,
} from "react-icons/md";

import "../styles/NewQuestion.css";
import CTA from "../components/buttons/CTA";
import Tag from "../components/buttons/Tag";
import TagForSearch from "../components/buttons/TagForSearch";
import { getTodayAsUnixDate } from "../functions/getTodayAsUnixDate";

export default function NewQuestion({
  authToken,
  setCreateNewQuestion,
  companyData,
  refresh,
  setRefresh,
}) {
  const [questionTitle, setQuestionTitle] = useState("");
  const [tag1, setTag1] = useState([]);
  const [tag2, setTag2] = useState([]);
  const [tag3, setTag3] = useState([]);
  const [searchTagInput, setSearchTagInput] = useState([]);
  const [searchTagResult, setSearchTagResult] = useState([]);
  const [responseSearchTagResult, setResponseSearchTagResult] = useState("");

  const closeCreateNewQuestionView = () => {
    setCreateNewQuestion(false);
  };

  const doCreateNewQuestion = () => {
    alert("Reggie");
  };

  // funktion zum einfügen des tags in den zu erstellenden post
  const clickOnSearchResultTag = (id, title) => {
    if (isEmpty(tag1)) {
      setTag1(JSON.parse(JSON.stringify({ id: id, title: title })));
    } else if (isEmpty(tag2)) {
      setTag2(JSON.parse(JSON.stringify({ id: id, title: title })));
    } else if (isEmpty(tag3)) {
      setTag3(JSON.parse(JSON.stringify({ id: id, title: title })));
    }

    // @todo : doppelte dürfen nicht rein. n alert dass der schon drin ist
  };

  // funktion zum erstellen des posts

  // wenn es tags gibt, die id = 0 sind, dann müssen diese erstmal created werden.
  // serverseitig? ja warum nicht
  //

  const handleupload = async () => {
    let now = getTodayAsUnixDate();
    const resa = await fetch(
      "https://www.cloud.leefes.com/api/posts/createPost.php",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          title: questionTitle,
          tag1ID: tag1.id,
          tag1Title: tag1.title,
          tag2ID: tag2.id,
          tag2Title: tag2.title,
          tag3ID: tag3.id,
          tag3Title: tag3.title,
          type: "q",
          difficulty: 1,
          createdAt: now,
          creatorID: companyData[0].id,
        }),
      }
    );

    await resa.text().then(() => uploadEnds());
  };

  const uploadEnds = () => {
    setRefresh(!refresh);
    setCreateNewQuestion(false);
  };

  // tags suchen
  useEffect(async () => {
    if (searchTagInput.length > 1) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/?title=" + searchTagInput,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseSearchTagResult(value));
    }
  }, [searchTagInput, authToken]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (responseSearchTagResult !== "") {
      setSearchTagResult(JSON.parse(responseSearchTagResult));
      console.log(searchTagResult);
    }
  }, [responseSearchTagResult]);

  var handleChange = function (event) {
    setQuestionTitle({ html: event.target.value });
  };

  return (
    <div className="newQuestion">
      <div className="newQuestion__fixedTopBar">
        <div onClick={() => closeCreateNewQuestionView(false)}>
          <BackIcon />
        </div>
        <div className="newQuestion__fixedTopBar_text">Neue Frage</div>
      </div>
      <div className="newQuestion__scrollContent">
        <div className="newQuestion__scrollContent_questionBox">
          <div className="newQuestion__scrollContent_questionBox_questionText">
            Frage eingeben
          </div>
          <div className="newQuestion__scrollContent_questionBox_questionDescription">
            Stelle deine Frage so genau wie möglich.
          </div>
          <div className="newQuestion__scrollContent_questionBox_questionInputbox">
            <span
              className="newQuestion__scrollContent_questionBox_questionInputbox_input"
              placeholder="Frage eingeben"
              contentEditable={"true"}
              role={"textbox"}
              id={"questionInput"}
              onBlur={(e) => setQuestionTitle(e.target.innerHTML)}
            ></span>
          </div>
        </div>
        <div className="newQuestion__scrollContent_keywordBox">
          <div className="newQuestion__scrollContent_keywordBox_questionText">
            Keywords
          </div>
          <div className="newQuestion__scrollContent_keywordBox_questionDescription">
            Füge 3 Keywords hinzu um besser gefunden zu werden
          </div>
          <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags">
            {!isEmpty(tag1) ? (
              <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_wrapper">
                <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_number">
                  1.
                </div>
                <div className="tag__active">{tag1.title}</div>
                <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_delete">
                  <CancelIcon onClick={() => setTag1([])} />
                </div>
              </div>
            ) : (
              <></>
            )}
            {!isEmpty(tag2) ? (
              <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_wrapper">
                <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_number">
                  2.
                </div>
                <div className="tag__active">{tag2.title}</div>
                <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_delete">
                  <CancelIcon onClick={() => setTag2([])} />
                </div>
              </div>
            ) : (
              <></>
            )}
            {!isEmpty(tag3) ? (
              <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_wrapper">
                <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_number">
                  3.
                </div>
                <div className="tag__active">{tag3.title}</div>
                <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_delete">
                  <CancelIcon onClick={() => setTag3([])} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="newQuestion__scrollContent_keywordBox_section">
            <div className="newQuestion__scrollContent_keywordBox_section_searchInputbox">
              <input
                className="newQuestion__scrollContent_keywordBox_section_searchInputbox_input"
                placeholder="Keyword eingeben"
                value={searchTagInput}
                onChange={(e) => setSearchTagInput(e.target.value)}
              />
            </div>
            <div className="newQuestion__scrollContent_keywordBox_section_tags">
              {searchTagResult.map((result) => (
                <TagForSearch
                  text={result.title}
                  clickOnSearchResultTag={clickOnSearchResultTag}
                  id={result.id}
                  key={result.id}
                />
              ))}
              {searchTagInput.length > 1 ? (
                <TagForSearch
                  text={searchTagInput}
                  id={0}
                  clickOnSearchResultTag={clickOnSearchResultTag}
                  key={0}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="newQuestion__scollContent_CTAbox">
          {isEmpty(tag1) ||
          isEmpty(tag2) ||
          isEmpty(tag3) ||
          questionTitle.length < 5 ? (
            <CTA text="Fülle alle Angaben aus" type={"disabled"} />
          ) : (
            <div onClick={() => handleupload()}>
              <CTA text="Frage erstellen" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const CancelIcon = styled(MdCancel)`
  font-size: 25px;
  color: #575757;
  margin-bottom: 1px;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 20px;
  color: #000000;
  margin-top: -1px;
  margin-left: 3px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 30px;
  color: #000;
  padding-right: 10px;
  padding-top: 7px;
`;
