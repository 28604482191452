import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import LeftColumn from "./screens/columns/LeftColumn";
import MiddleAndRightColumnForQuestions from "./screens/columns/MiddleAndRightColumnForQuestions";
import MiddleAndRightColumnForSearch from "./screens/columns/MiddleAndRightColumnForSearch";
import MiddleAndRightColumnForProfile from "./screens/columns/MiddleAndRightColumnForProfile";
import { getTodayAsUnixDate } from "./functions/getTodayAsUnixDate";
import MiddleAndRightColumnForContacts from "./screens/columns/MiddleAndRightColumnForContacts";
import { arraysEqual } from "./functions/arraysEqual";

export default function App() {
  /* Unterseiten ohne ID und ohne URI */
  const [createNewQuestion, setCreateNewQuestion] = useState(false);
  const [openInfoPage, setOpenInfoPage] = useState("");
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openArticle, setOpenArticle] = useState(false);
  const [openCalenderConfig, setOpenCalenderConfig] = useState(false);
  const [pathFromSearch, setPathFromSearch] = useState(false);

  /* Search Input */
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [responseSearchResults, setResponseSearchResults] = useState("");
  const [similarExperts, setSimilarExperts] = useState([]);
  const [responseSimilarExperts, setResponseSimilarExperts] = useState("");
  const [searchFeedData, setSearchFeedData] = useState([]);
  const [responseSearchFeedData, setResponseSearchFeedData] = useState("");

  /* Auth Token */
  const [authToken, setAuthToken] = useState();

  /* userdata */
  const [userData, setUserData] = useState([]);
  const [responseUser, setResponseUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [interestedTags, setInterestedTags] = useState([]);

  /* companyData - name, tags, images, posts */
  const [companyData, setCompanyData] = useState([]);
  const [responseCompanyData, setResponseCompanyData] = useState("");
  const [sortedExpertForTagData, setSortedExpertForTagData] = useState([]);
  const [tagData1, setTagData1] = useState([]);
  const [responseTagData1, setResponseTagData1] = useState("");
  const [tagData2, setTagData2] = useState([]);
  const [responseTagData2, setResponseTagData2] = useState("");
  const [tagData3, setTagData3] = useState([]);
  const [responseTagData3, setResponseTagData3] = useState("");
  const [numberOfPosts, setNumberOfPosts] = useState(0);
  const [companyAnswers, setCompanyAnswers] = useState([]);
  const [responseCompanyAnswers, setResponseCompanyAnswers] = useState("");
  const [likes, setLikes] = useState([]);
  const [responseLikes, setResponseLikes] = useState("");

  /* feedData */
  const [sortedFeedData, setSortedFeedData] = useState([]);
  const [feedData, setFeedData] = useState([]);
  const [responseFeedData, setResponseFeedData] = useState("");
  const [activeArticle, setActiveArticle] = useState([]);
  const [responseActiveArticle, setResponseActiveArticle] = useState("");
  const [articleID, setArticleID] = useState("");

  /* appointmentsData */
  const [ownerAppointmentsData, setOwnerAppointmentsData] = useState([]);
  const [responseOwnerAppointmentsData, setResponseOwnerAppointmentsdata] =
    useState("");
  const [bookerAppointmentsData, setBookerAppointmentsData] = useState([]);
  const [responseBookerAppointmentsData, setResponseBookerAppointmentsdata] =
    useState("");
  const [servicesData, setServicesData] = useState([]);
  const [responseseServicesData, setResponseServicesData] = useState("");

  /* infoData */
  const [infoData, setInfoData] = useState([]);
  const [lightRoomData, setLightRoomData] = useState([]);

  /* refresh */
  const [refresh, setRefresh] = useState(false);
  const [refreshLikes, setRefreshLikes] = useState(false);
  const [refreshTags, setRefreshTags] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  /* settings */
  const [darkModeOn, setDarkModeOn] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [mobile, setMobile] = useState(false);
  const [bottom, setBottom] = useState(false);

  useEffect(() => {
    let windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    let windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (windowWidth < 692) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [authToken]);


  // likes neu laden über likerefresh

  useEffect(() => {
    if (darkModeOn) {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", "#000000");
    } else {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", "#f0f2f5");
    }
  }, [darkModeOn]);

  // tagData in companyData speichern
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(companyData) && !isEmpty(tagData1)) {
      if (
        arraysEqual(tagData1, JSON.parse(companyData[0].tag1JSON)) &&
        arraysEqual(tagData2, JSON.parse(companyData[0].tag2JSON)) &&
        arraysEqual(tagData3, JSON.parse(companyData[0].tag3JSON))
      ) {
        //
      } else {
        // customerdaten bekommen anhand der companyid
        const res = await fetch(
          "https://www.cloud.leefes.com/api/companies/?id=" + companyData[0].id,
          {
            headers: {
              Authorization: `Bearer ${authToken.JWT}`,
              "Content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            method: "POST",
            body: JSON.stringify({
              tag1JSON: JSON.stringify(tagData1),
              tag2JSON: JSON.stringify(tagData2),
              tag3JSON: JSON.stringify(tagData3),
            }),
          }
        );
        const resp = await res.text().then((value) => console.log(value));
      }
    }
  }, [authToken, tagData1, tagData2, tagData3, companyData]);

  //get activeArticle
  useEffect(async () => {
    if (!isEmpty(authToken) && openArticle && articleID != "") {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/posts/?id=" + articleID,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseActiveArticle(value));
    }
  }, [authToken, activeArticle, openArticle, articleID]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (responseActiveArticle !== "") {
      setActiveArticle(JSON.parse(responseActiveArticle)[0]);
      console.log(responseActiveArticle);
    }
  }, [responseActiveArticle]);

  //get likes as String
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(companyData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/likes/?likerID=" + companyData[0].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res.text().then((value) => setResponseLikes(value));
    }
    console.log("refreshlikes");
  }, [authToken, companyData, refreshLikes]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (responseLikes !== "") {
      setLikes(JSON.parse(responseLikes));
    }
  }, [responseLikes]);

  //get companies with same tags ... hui das wird schwer
  // dazu muss auf server seite expertfor array geloopt werden bei jedem hallelulha ne.
  // das compiled zu lang. besser es gibt tag 1 tag 2 tag 3 in den companydata
  useEffect(async () => {
    if (
      isEmpty(similarExperts) &&
      !isEmpty(authToken) &&
      !isEmpty(companyData)
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/companies/getSimilarExperts.php?tag1JSON=" +
          companyData[0].tag1JSON +
          "&tag2JSON=" +
          companyData[0].tag2JSON +
          "&tag3JSON=" +
          companyData[0].tag3JSON,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseSimilarExperts(value));
    }
  }, [authToken, similarExperts, companyData, tagData1, tagData2, tagData3]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (responseSimilarExperts !== "") {
      console.log(responseSimilarExperts);
      setSimilarExperts(JSON.parse(responseSimilarExperts));
    }
  }, [responseSimilarExperts]);

  //get searchFEED as String
  useEffect(async () => {
    if (searchInput.length > 1 && !isEmpty(authToken)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/posts/getSearchFeedPosts.php?tag1Title=" +
          searchInput +
          "&tag2Title=" +
          searchInput +
          "&tag3Title=" +
          searchInput,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseSearchFeedData(value));
    }
  }, [searchInput, authToken]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (responseSearchFeedData !== "") {
      setSearchFeedData(JSON.parse(responseSearchFeedData));
    }
  }, [responseSearchFeedData]);

  //get searchResults as String
  useEffect(async () => {
    if (searchInput.length > 1) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/companies/getCompaniesFromTags.php?tag1Title=" +
          searchInput +
          "&tag2Title=" +
          searchInput +
          "&tag3Title=" +
          searchInput,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseSearchResults(value));
    }
  }, [searchInput]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (responseSearchResults !== "") {
      setSearchResults(JSON.parse(responseSearchResults));
    }
  }, [responseSearchResults]);

  //get booker appointmentData as String
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(companyData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/appointments/?bookerID=" +
          companyData[0].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseBookerAppointmentsdata(value));
    }
  }, [authToken, companyData, refresh]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (responseBookerAppointmentsData !== "") {
      setBookerAppointmentsData(JSON.parse(responseBookerAppointmentsData));
    }
  }, [responseBookerAppointmentsData, authToken]);

  //get Owner appointmentData as String
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(companyData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/appointments/?ownerID=" +
          companyData[0].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseOwnerAppointmentsdata(value));
    }
  }, [authToken, companyData, refresh]);

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (responseOwnerAppointmentsData !== "") {
      setOwnerAppointmentsData(JSON.parse(responseOwnerAppointmentsData));
    }
  }, [responseOwnerAppointmentsData, authToken]);

  //get companyData as String
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(companyData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/services/?companyID=" +
          companyData[0].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseServicesData(value));
    }
  }, [authToken, companyData, refresh]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseseServicesData !== "") {
      setServicesData(JSON.parse(responseseServicesData));
    }
  }, [responseseServicesData, authToken]);

  //get companyAnswers as String
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(userData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/answers/?companyID=" +
          userData.companyID,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseCompanyAnswers(value));
    }
  }, [authToken, userData, refresh]);

  // companyAnswers als JSON in state packen
  useEffect(() => {
    if (responseCompanyAnswers !== "") {
      let unsorted = JSON.parse(responseCompanyAnswers);
      var sorted = unsorted.sort(function (a, b) {
        return b.createdAt - a.createdAt;
      });
      setCompanyAnswers(sorted);
    }
  }, [responseCompanyAnswers, authToken]);

  /*
   * Rausfiltern aller posts, wo bereits eine antwort abgegeben wurde
   */
  useEffect(() => {
    if (!isEmpty(feedData) && !isEmpty(companyAnswers)) {
      let helpArrayFeed = feedData;
      let helpArrayAnswers = companyAnswers;
      let newFeedArrayWithoutAnsweredPosts = [];
      for (let i = 0; i < helpArrayFeed.length; i++) {
        let isAnswered = false;
        for (let k = 0; k < helpArrayAnswers.length; k++) {
          if (helpArrayFeed[i].id == helpArrayAnswers[k].postID) {
            isAnswered = true;
          }
        }
        if (!isAnswered) {
          newFeedArrayWithoutAnsweredPosts.push(helpArrayFeed[i]);
        }
      }
      setSortedFeedData(newFeedArrayWithoutAnsweredPosts);
    }
  }, [companyAnswers, feedData, refresh]);

  //get companyData as String
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(userData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/companies/?id=" + userData.companyID,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseCompanyData(value));
    }
  }, [authToken, userData, refreshTags, refresh]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseCompanyData !== "") {
      setCompanyData(JSON.parse(responseCompanyData));
    }
  }, [responseCompanyData, authToken]);

  //get feedData
  useEffect(async () => {
    let tag1Var;
    let tag2Var;
    let tag3Var;

    if (!isEmpty(authToken) && !isEmpty(sortedExpertForTagData)) {
      if (!isEmpty(sortedExpertForTagData[0])) {
        tag1Var = sortedExpertForTagData[0].id.toString();
      } else {
        tag1Var = "0";
      }
      if (!isEmpty(sortedExpertForTagData[1])) {
        tag2Var = sortedExpertForTagData[1].id.toString();
      } else {
        tag2Var = "0";
      }
      if (!isEmpty(sortedExpertForTagData[2])) {
        tag3Var = sortedExpertForTagData[2].id.toString();
      } else {
        tag3Var = "0";
      }

      // posts bekommen
      const res = await fetch(
        "https://www.cloud.leefes.com/api/posts/feed.php?tag1ID=" +
          tag1Var +
          "&tag2ID=" +
          tag2Var +
          "&tag3ID=" +
          tag3Var,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res.text().then((value) => setResponseFeedData(value));
    }
  }, [authToken, sortedExpertForTagData, refresh]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseFeedData !== "") {
      setFeedData(JSON.parse(responseFeedData));
      setSortedFeedData(JSON.parse(responseFeedData));
    }
  }, [responseFeedData, authToken]);

  // companyData sortieren und in tagData reinpacken
  useEffect(() => {
    if (!isEmpty(companyData) && isEmpty(sortedExpertForTagData)) {
      let helpArray = JSON.parse(companyData[0].expertForTags);
      let helpArray2 = [];
      let firstCount = 0;
      let firstElement;
      let secondCount = 0;
      let secondelement;
      let thirdCount = 0;
      let thirdElement;
      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > firstCount) {
            firstElement = helpArray[i];
            firstCount = helpArray[i].count;
          }
        }

        if (firstCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(firstElement);
          const index = helpArray.indexOf(firstElement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }

      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > secondCount) {
            secondelement = helpArray[i];
            secondCount = helpArray[i].count;
          }
        }

        if (secondCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(secondelement);
          const index = helpArray.indexOf(secondelement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }

      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > thirdCount) {
            thirdElement = helpArray[i];
            thirdCount = helpArray[i].count;
          }
        }

        if (thirdCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(thirdElement);
          const index = helpArray.indexOf(thirdElement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }

      setSortedExpertForTagData(helpArray2);
    }
  }, [companyData, authToken, sortedExpertForTagData]);

  useEffect(() => {
    if (!isEmpty(companyData)) {
      let helpArray = JSON.parse(companyData[0].expertForTags);
      let helpArray2 = [];
      let firstCount = 0;
      let firstElement;
      let secondCount = 0;
      let secondelement;
      let thirdCount = 0;
      let thirdElement;
      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > firstCount) {
            firstElement = helpArray[i];
            firstCount = helpArray[i].count;
          }
        }

        if (firstCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(firstElement);
          const index = helpArray.indexOf(firstElement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }

      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > secondCount) {
            secondelement = helpArray[i];
            secondCount = helpArray[i].count;
          }
        }

        if (secondCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(secondelement);
          const index = helpArray.indexOf(secondelement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }

      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > thirdCount) {
            thirdElement = helpArray[i];
            thirdCount = helpArray[i].count;
          }
        }

        if (thirdCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(thirdElement);
          const index = helpArray.indexOf(thirdElement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }
      setSortedExpertForTagData(helpArray2);
    }
  }, [companyData, authToken]);

  //get tagData1
  useEffect(async () => {
    if (
      !isEmpty(authToken) &&
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 1
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/?id=" +
          sortedExpertForTagData[0].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res.text().then((value) => setResponseTagData1(value));
    }
  }, [authToken, sortedExpertForTagData]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseTagData1 !== "") {
      setTagData1(JSON.parse(responseTagData1));
    }
  }, [responseTagData1, authToken]);

  //get tagData2
  useEffect(async () => {
    if (
      !isEmpty(authToken) &&
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 2
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/?id=" +
          sortedExpertForTagData[1].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res.text().then((value) => setResponseTagData2(value));
    }
  }, [authToken, sortedExpertForTagData]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseTagData2 !== "") {
      setTagData2(JSON.parse(responseTagData2));
    }
  }, [responseTagData2, authToken]);

  //get tagData3
  useEffect(async () => {
    if (
      !isEmpty(authToken) &&
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 3
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/?id=" +
          sortedExpertForTagData[2].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res.text().then((value) => setResponseTagData3(value));
    }
  }, [authToken, sortedExpertForTagData]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseTagData3 !== "") {
      setTagData3(JSON.parse(responseTagData3));
    }
  }, [responseTagData3, authToken]);

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtleefes");
      setResponseUser("");
      setUserData([]);
      setResponseCompanyData("");
      setCompanyData([]);
      setAuthToken();
      setSortedExpertForTagData([]);
      setTagData1([]);
      setResponseTagData1("");
      setTagData2([]);
      setResponseTagData2("");
      setTagData3([]);
      setResponseTagData3("");
      setFeedData([]);
      setResponseFeedData("");
      setNumberOfPosts(0);
      setCompanyAnswers([]);
      setResponseCompanyAnswers("");
      setSortedFeedData([]);
      setInfoData([]);
      setBookerAppointmentsData([]);
      setOwnerAppointmentsData([]);
      setOpenSettings(false);
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtleefes");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtleefes")) {
    getData();
  }

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtleefes", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch("https://www.cloud.leefes.com/api/authenticate", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtleefes", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    let helpArray2 = [];
    interestedTags.map((tagID) =>
      helpArray2.push({ id: tagID.tagID, count: 0, title: tagID.title })
    );
    let tagString = JSON.stringify(helpArray2);

    const res = await fetch("https://www.cloud.leefes.com/api/signup.php", {
      method: "POST",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
        tags: tagString,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  // get userData
  useEffect(async () => {
    if (!isEmpty(authToken)) {
      const res = await fetch("https://www.cloud.leefes.com/api/users/", {
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      });
      const resp = await res
        .text()
        .then((value) => setResponseUser(JSON.parse(value)));
    }
  }, [authToken]);

  // userdata in state packen
  useEffect(() => {
    if (responseUser !== "") {
      setUserData(responseUser);
    }
  }, [responseUser, authToken]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setBottom(true);
    }
  };

  return (
    <div
      className="app"
      //   onScroll={handleScroll}
      //   style={{ overflowY: "scroll", maxHeight: "100%" }}
    >
      <Router>
        <div className="app__fixedSidebar">
          <LeftColumn
            setCreateNewQuestion={setCreateNewQuestion}
            setOpenLogin={setOpenLogin}
            setOpenRegister={setOpenRegister}
            setOpenSettings={setOpenSettings}
            setOpenArticle={setOpenArticle}
            setOpenCalenderConfig={setOpenCalenderConfig}
            authToken={authToken}
            refresh={refresh}
            setRefresh={setRefresh}
            setInfoData={setInfoData}
            activePage={activePage}
            setActivePage={setActivePage}
            mobile={mobile}
            setMobile={setMobile}
            pathFromSearch={pathFromSearch}
            setPathFromSearch={setPathFromSearch}
            setOpenInfoPage={setOpenInfoPage}
            openLogin={openLogin}
            openRegister={openRegister}
            lightRoomData={lightRoomData}
            setLightRoomData={setLightRoomData}
          />
        </div>
        <div className="app__routingPages">
          <Routes>
            <Route
              path="/"
              element={
                <MiddleAndRightColumnForQuestions
                  createNewQuestion={createNewQuestion}
                  setCreateNewQuestion={setCreateNewQuestion}
                  openLogin={openLogin}
                  openRegister={openRegister}
                  authToken={authToken}
                  openSettings={openSettings}
                  setOpenSettings={setOpenSettings}
                  logout={destroySession}
                  companyData={companyData}
                  setCompanyData={setCompanyData}
                  responseCompanyData={responseCompanyData}
                  setResponseCompanyData={setResponseCompanyData}
                  userData={userData}
                  tagData1={tagData1}
                  tagData2={tagData2}
                  tagData3={tagData3}
                  feedData={sortedFeedData}
                  numberOfPosts={numberOfPosts}
                  setNumberOfPosts={setNumberOfPosts}
                  openArticle={openArticle}
                  setOpenArticle={setOpenArticle}
                  activeArticle={activeArticle}
                  setActiveArticle={setActiveArticle}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  companyAnswers={companyAnswers}
                  setCompanyAnswers={setCompanyAnswers}
                  likes={likes}
                  setLikes={setLikes}
                  refreshLikes={refreshLikes}
                  setRefreshLikes={setRefreshLikes}
                  refreshTags={refreshTags}
                  setRefreshTags={setRefreshTags}
                  openInfoPage={openInfoPage}
                  setOpenInfoPage={setOpenInfoPage}
                  articleID={articleID}
                  setArticleID={setArticleID}
                  bottom={bottom}
                  setBottom={setBottom}
                  mobile={mobile}
                  lightRoomData={lightRoomData}
                  setLightRoomData={setLightRoomData}
                />
              }
            />
            <Route
              path="/search"
              element={
                <MiddleAndRightColumnForSearch
                  feedData={sortedFeedData}
                  likes={likes}
                  setLikes={setLikes}
                  refreshLikes={refreshLikes}
                  setRefreshLikes={setRefreshLikes}
                  refreshTags={refreshTags}
                  setRefreshTags={setRefreshTags}
                  companyAnswers={companyAnswers}
                  setCompanyAnswers={setCompanyAnswers}
                  openLogin={openLogin}
                  openRegister={openRegister}
                  setOpenLogin={setOpenLogin}
                  setOpenRegister={setOpenRegister}
                  authToken={authToken}
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  username={username}
                  setUsername={setUsername}
                  signIn={signIn}
                  signUp={signUp}
                  openSettings={openSettings}
                  setOpenSettings={setOpenSettings}
                  logout={destroySession}
                  companyData={companyData}
                  setCompanyData={setCompanyData}
                  responseCompanyData={responseCompanyData}
                  setResponseCompanyData={setResponseCompanyData}
                  userData={userData}
                  interestedTags={interestedTags}
                  setInterestedTags={setInterestedTags}
                  tagData1={tagData1}
                  tagData2={tagData2}
                  tagData3={tagData3}
                  numberOfPosts={numberOfPosts}
                  setNumberOfPosts={setNumberOfPosts}
                  openArticle={openArticle}
                  setOpenArticle={setOpenArticle}
                  activeArticle={activeArticle}
                  setActiveArticle={setActiveArticle}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  searchResults={searchResults}
                  setSearchResults={setSearchResults}
                  pathFromSearch={pathFromSearch}
                  setPathFromSearch={setPathFromSearch}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  similarExperts={similarExperts}
                  openInfoPage={openInfoPage}
                  setOpenInfoPage={setOpenInfoPage}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  articleID={articleID}
                  setArticleID={setArticleID}
                  searchFeedData={searchFeedData}
                  bottom={bottom}
                  setBottom={setBottom}
                  mobile={mobile}
                  setMobile={setMobile}
                  lightRoomData={lightRoomData}
                  setLightRoomData={setLightRoomData}
                />
              }
            />
            {["/profile", "/profile/:id", "/profile/:id/contact", "/appointment/:id"].map(
              (path, index) => (
                <Route
                  key={index}
                  path={path}
                  element={
                    <MiddleAndRightColumnForProfile
                      authToken={authToken}
                      openSettings={openSettings}
                      setOpenSettings={setOpenSettings}
                      logout={destroySession}
                      companyData={companyData}
                      setCompanyData={setCompanyData}
                      responseCompanyData={responseCompanyData}
                      setResponseCompanyData={setResponseCompanyData}
                      userData={userData}
                      tagData1={tagData1}
                      tagData2={tagData2}
                      tagData3={tagData3}
                      numberOfPosts={numberOfPosts}
                      setNumberOfPosts={setNumberOfPosts}
                      openArticle={openArticle}
                      setOpenArticle={setOpenArticle}
                      activeArticle={activeArticle}
                      setActiveArticle={setActiveArticle}
                      companyAnswers={companyAnswers}
                      openLogin={openLogin}
                      openRegister={openRegister}
                      setOpenLogin={setOpenLogin}
                      setOpenRegister={setOpenRegister}
                      email={email}
                      setEmail={setEmail}
                      password={password}
                      setPassword={setPassword}
                      username={username}
                      setUsername={setUsername}
                      signIn={signIn}
                      signUp={signUp}
                      interestedTags={interestedTags}
                      setInterestedTags={setInterestedTags}
                      infoData={infoData}
                      setInfoData={setInfoData}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      pathFromSearch={pathFromSearch}
                      setPathFromSearch={setPathFromSearch}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                      likes={likes}
                      setLikes={setLikes}
                      openInfoPage={openInfoPage}
                      setOpenInfoPage={setOpenInfoPage}
                      feedData={sortedFeedData}
                      refreshLikes={refreshLikes}
                      setRefreshLikes={setRefreshLikes}
                      refreshTags={refreshTags}
                      setRefreshTags={setRefreshTags}
                      setCompanyAnswers={setCompanyAnswers}
                      articleID={articleID}
                      setArticleID={setArticleID}
                      bottom={bottom}
                      setBottom={setBottom}
                      mobile={mobile}
                      lightRoomData={lightRoomData}
                      setLightRoomData={setLightRoomData}

                    />
                  }
                />
              )
            )}
            {["/contacts", "/contacts/:id"].map((path, index) => (
              <Route
                key={index}
                path={path}
                element={
                  <MiddleAndRightColumnForContacts
                    createNewQuestion={createNewQuestion}
                    setCreateNewQuestion={setCreateNewQuestion}
                    openLogin={openLogin}
                    openRegister={openRegister}
                    authToken={authToken}
                    openSettings={openSettings}
                    setOpenSettings={setOpenSettings}
                    logout={destroySession}
                    companyData={companyData}
                    setCompanyData={setCompanyData}
                    responseCompanyData={responseCompanyData}
                    setResponseCompanyData={setResponseCompanyData}
                    userData={userData}
                    tagData1={tagData1}
                    tagData2={tagData2}
                    tagData3={tagData3}
                    feedData={sortedFeedData}
                    numberOfPosts={numberOfPosts}
                    setNumberOfPosts={setNumberOfPosts}
                    openArticle={openArticle}
                    setOpenArticle={setOpenArticle}
                    activeArticle={activeArticle}
                    setActiveArticle={setActiveArticle}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    companyAnswers={companyAnswers}
                    setCompanyAnswers={setCompanyAnswers}
                    openCalenderConfig={openCalenderConfig}
                    setOpenCalenderConfig={setOpenCalenderConfig}
                    servicesData={servicesData}
                    setServicesData={setServicesData}
                    ownerAppointmentsData={ownerAppointmentsData}
                    setOwnerAppointmentsData={setOwnerAppointmentsData}
                    bookerAppointmentsData={bookerAppointmentsData}
                    setBookerAppointmentsData={setBookerAppointmentsData}
                    mobile={mobile}
                    setMobile={setMobile}
                    articleID={articleID}
                    setArticleID={setArticleID}
                  />
                }
              />
            ))}
            <Route
              path="/appointment/"
              element={
                <MiddleAndRightColumnForProfile
                      authToken={authToken}
                      openSettings={openSettings}
                      setOpenSettings={setOpenSettings}
                      logout={destroySession}
                      companyData={companyData}
                      setCompanyData={setCompanyData}
                      responseCompanyData={responseCompanyData}
                      setResponseCompanyData={setResponseCompanyData}
                      userData={userData}
                      tagData1={tagData1}
                      tagData2={tagData2}
                      tagData3={tagData3}
                      numberOfPosts={numberOfPosts}
                      setNumberOfPosts={setNumberOfPosts}
                      openArticle={openArticle}
                      setOpenArticle={setOpenArticle}
                      activeArticle={activeArticle}
                      setActiveArticle={setActiveArticle}
                      companyAnswers={companyAnswers}
                      openLogin={openLogin}
                      openRegister={openRegister}
                      setOpenLogin={setOpenLogin}
                      setOpenRegister={setOpenRegister}
                      email={email}
                      setEmail={setEmail}
                      password={password}
                      setPassword={setPassword}
                      username={username}
                      setUsername={setUsername}
                      signIn={signIn}
                      signUp={signUp}
                      interestedTags={interestedTags}
                      setInterestedTags={setInterestedTags}
                      infoData={infoData}
                      setInfoData={setInfoData}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      pathFromSearch={pathFromSearch}
                      setPathFromSearch={setPathFromSearch}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                      likes={likes}
                      setLikes={setLikes}
                      openInfoPage={openInfoPage}
                      setOpenInfoPage={setOpenInfoPage}
                      feedData={sortedFeedData}
                      refreshLikes={refreshLikes}
                      setRefreshLikes={setRefreshLikes}
                      refreshTags={refreshTags}
                      setRefreshTags={setRefreshTags}
                      setCompanyAnswers={setCompanyAnswers}
                      articleID={articleID}
                      setArticleID={setArticleID}
                      bottom={bottom}
                      setBottom={setBottom}
                      mobile={mobile}
                      lightRoomData={lightRoomData}
                      setLightRoomData={setLightRoomData}
                    />
              }
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
}
