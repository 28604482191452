import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../../styles/Tag.css";
import { isEmpty } from "../../functions/isEmpty";

export default function TagForSearch({ text, id, clickOnSearchResultTag }) {
  const [active, setActive] = useState(false);

  return (
    <div className="tag" onClick={() => clickOnSearchResultTag(id, text)}>
      {text}
    </div>
  );
}
const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;
