import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MdSearch, MdKeyboardArrowRight, MdArrowBack } from "react-icons/md";

import "../../styles/ThemeboxComponent.css";
import RelevantTag from "../content/RelevantTag";

export default function PostThemebox({ setDetailPage }) {
  const dummyThema = () => {
    return (
      <div className="name">
        <RelevantTag />
      </div>
    );
  };

  return (
    <div className="themeboxComponent">
      <div className="themeboxComponent__topBar">
        <div className="themeboxComponent__topBar_headline">Deine Themen</div>
      </div>
      <div className="themeboxComponent__elements">
        {dummyThema()}
        {dummyThema()}
        {dummyThema()}
      </div>
    </div>
  );
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;
