import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../../functions/isEmpty";
import {
  MdAdd,
  MdArrowForwardIos,
  MdBusiness,
  MdConstruction,
  MdDeveloperBoard,
  MdDocumentScanner,
  MdFitnessCenter,
  MdHealthAndSafety,
  MdHouse,
  MdMedicalServices,
  MdMonetizationOn,
  MdPartyMode,
  MdSearch,
  MdShop,
  MdSpa,
} from "react-icons/md";
import Profile from "../Profile";
import Fragen from "../Fragen";

import "../../styles/Columns.css";
import Search from "../Search";
import Register from "../Register";
import Article from "../Article";
import NewQuestion from "../NewQuestion";
import NotLoggedInRightSidebar from "../NotLoggedInRightSidebar";
import Settings from "../Settings";
import { useParams } from "react-router";
import Booking from "../Booking";
import Login from "../Login";
import Info from "../Info";
import Lightroom from "../Lightroom";
import DetailAppointment from "../DetailAppointment";

export default function MiddleAndRightColumnForProfile({
  authToken,
  openSettings,
  setOpenSettings,
  logout,
  companyData,
  setCompanyData,
  responseCompanyData,
  setResponseCompanyData,
  userData,
  tagData1,
  tagData2,
  tagData3,
  numberOfPosts,
  setNumberOfPosts,
  companyAnswers,
  openLogin,
  openRegister,
  setOpenLogin,
  setOpenRegister,
  email,
  setEmail,
  password,
  setPassword,
  username,
  setUsername,
  signIn,
  signUp,
  interestedTags,
  setInterestedTags,
  infoData,
  setInfoData,
  refresh,
  setRefresh,
  pathFromSearch,
  setPathFromSearch,
  isUploading,
  setIsUploading,
  openInfoPage,
  setOpenInfoPage,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  articleID,
  setArticleID,
  feedData,
  likes,
  setLikes,
  refreshLikes,
  setRefreshLikes,
  refreshTags,
  setRefreshTags,
  setCompanyAnswers,
  mobile,
  setMobile,
  lightRoomData,
  setLightRoomData,
}) {
  const pathname = window.location.pathname;
  const { id } = useParams();
  const [isAppointmentPage, setIsAppointmentPage] = useState(false);
  const [activeAppointment, setActiveAppointment] = useState([]);

  useEffect(() => {
    if (pathname.substring(0, 8) == "/appoint") {
      setIsAppointmentPage(true);
    } else {
      setIsAppointmentPage(false);
    }
  }, [authToken, id, companyData]);

  const showActivePage = () => {
    if (!isEmpty(lightRoomData)) {
      return (
        <Lightroom
          lightRoomData={lightRoomData}
          setLightRoomData={setLightRoomData}
        />
      );
    } else if (openLogin) {
      return (
        <Login
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          signIn={signIn}
          authToken={authToken}
          setOpenLogin={setOpenLogin}
          setOpenRegister={setOpenRegister}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          mobile={mobile}
          setOpenSettings={setOpenSettings}
        />
      );
    } else if (openRegister) {
      return (
        <Register
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          username={username}
          setUsername={setUsername}
          signUp={signUp}
          authToken={authToken}
          interestedTags={interestedTags}
          setInterestedTags={setInterestedTags}
          setOpenLogin={setOpenLogin}
          setOpenRegister={setOpenRegister}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          mobile={mobile}
          setOpenSettings={setOpenSettings}
        />
      );
    } else if (openInfoPage != "") {
      return (
        <Info
          logout={logout}
          authToken={authToken}
          setOpenSettings={setOpenSettings}
          openInfoPage={openInfoPage}
          setOpenInfoPage={setOpenInfoPage}
          companyData={companyData}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      );
    } else if (openSettings) {
      return (
        <Settings
          logout={logout}
          authToken={authToken}
          setOpenSettings={setOpenSettings}
          openInfoPage={openInfoPage}
          setOpenInfoPage={setOpenInfoPage}
        />
      );
    } else if (openArticle) {
      return (
        <Article
          authToken={authToken}
          feedData={feedData}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          refresh={refresh}
          setRefresh={setRefresh}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
          likes={likes}
          setLikes={setLikes}
          refreshLikes={refreshLikes}
          setRefreshLikes={setRefreshLikes}
          refreshTags={refreshTags}
          setRefreshTags={setRefreshTags}
        />
      );
    } else if (!isEmpty(infoData)) {
      return (
        <Info infoData={infoData} setInfoData={setInfoData} type={"termin"} />
      );
    } else if (isAppointmentPage) {
      return (
        // hier die buchungsseite ohne login - designvorlage von appointments nehmen contacts
        <DetailAppointment
          authToken={authToken}
          setOpenSettings={setOpenSettings}
          companyData={companyData}
          setCompanyData={setCompanyData}
          responseCompanyData={responseCompanyData}
          setResponseCompanyData={setResponseCompanyData}
          userData={userData}
          tagData1={tagData1}
          tagData2={tagData2}
          tagData3={tagData3}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          companyAnswers={companyAnswers}
          type={"profile"}
          infoData={infoData}
          setInfoData={setInfoData}
          refresh={refresh}
          setRefresh={setRefresh}
          activeAppointment={activeAppointment}
          setActiveAppointment={setActiveAppointment}
          mobile={mobile}
          setMobile={setMobile}
        />
      );
    } else if (pathname == "/profile/" + id + "/contact") {
      return (
        <Booking
          authToken={authToken}
          setOpenSettings={setOpenSettings}
          companyData={companyData}
          setCompanyData={setCompanyData}
          responseCompanyData={responseCompanyData}
          setResponseCompanyData={setResponseCompanyData}
          userData={userData}
          tagData1={tagData1}
          tagData2={tagData2}
          tagData3={tagData3}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          companyAnswers={companyAnswers}
          type={"profile"}
          infoData={infoData}
          setInfoData={setInfoData}
          refresh={refresh}
          setRefresh={setRefresh}
          setOpenInfoPage={setOpenInfoPage}
          setOpenLogin={setOpenLogin}
          setOpenRegister={setOpenRegister}
          mobile={mobile}
        />
      );
    } else {
      return (
        <Profile
          authToken={authToken}
          setOpenSettings={setOpenSettings}
          companyData={companyData}
          setCompanyData={setCompanyData}
          responseCompanyData={responseCompanyData}
          setResponseCompanyData={setResponseCompanyData}
          userData={userData}
          tagData1={tagData1}
          tagData2={tagData2}
          tagData3={tagData3}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          companyAnswers={companyAnswers}
          type={"profile"}
          infoData={infoData}
          setInfoData={setInfoData}
          pathFromSearch={pathFromSearch}
          setPathFromSearch={setPathFromSearch}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          articleID={articleID}
          setArticleID={setArticleID}
          mobile={mobile}
          lightRoomData={lightRoomData}
          setLightRoomData={setLightRoomData}
        />
      );
    }
  };

  return (
    <div className="home">
      <div className="home__scrollPageLeft">{showActivePage()} </div>
      <div className="home__fixedPageRight">
        {isEmpty(authToken) ? (
          <NotLoggedInRightSidebar
            setOpenLogin={setOpenLogin}
            setOpenRegister={setOpenRegister}
          />
        ) : (
          <Profile
            authToken={authToken}
            setOpenSettings={setOpenSettings}
            companyData={companyData}
            setCompanyData={setCompanyData}
            responseCompanyData={responseCompanyData}
            setResponseCompanyData={setResponseCompanyData}
            userData={userData}
            tagData1={tagData1}
            tagData2={tagData2}
            tagData3={tagData3}
            numberOfPosts={numberOfPosts}
            setNumberOfPosts={setNumberOfPosts}
            companyAnswers={companyAnswers}
            type={"sidebar"}
            pathFromSearch={pathFromSearch}
            setPathFromSearch={setPathFromSearch}
            openArticle={openArticle}
            setOpenArticle={setOpenArticle}
            activeArticle={activeArticle}
            setActiveArticle={setActiveArticle}
            articleID={articleID}
            setArticleID={setArticleID}
            mobile={mobile}
            lightRoomData={lightRoomData}
            setLightRoomData={setLightRoomData}
          />
        )}
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #667781;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 18px;
  color: #667781;
  margin-bottom: -4px;
  margin-right: 11px;
`;

const ArrowRight = styled(MdArrowForwardIos)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const BusinessIcon = styled(MdBusiness)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const HouseIcon = styled(MdHouse)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const ConstructionIcon = styled(MdConstruction)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const SpaIcon = styled(MdSpa)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const HealthIcon = styled(MdHealthAndSafety)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const MedicIcon = styled(MdMedicalServices)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const InfluencerIcon = styled(MdPartyMode)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const ShopIcon = styled(MdShop)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const DeveloperIcon = styled(MdDeveloperBoard)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const TaxIcon = styled(MdMonetizationOn)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const FitnessIcon = styled(MdFitnessCenter)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const InsuranceIcon = styled(MdDocumentScanner)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;
