import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../../functions/isEmpty";
import {
  MdAdd,
  MdArrowForwardIos,
  MdBusiness,
  MdConstruction,
  MdDeveloperBoard,
  MdDocumentScanner,
  MdFitnessCenter,
  MdHealthAndSafety,
  MdHouse,
  MdMedicalServices,
  MdMonetizationOn,
  MdPartyMode,
  MdSearch,
  MdShop,
  MdSpa,
} from "react-icons/md";
import Profile from "../Profile";
import Fragen from "../Fragen";

import "../../styles/Columns.css";
import Search from "../Search";
import Register from "../Register";
import Login from "../Login";
import Article from "../Article";
import NewQuestion from "../NewQuestion";
import NotLoggedInRightSidebar from "../NotLoggedInRightSidebar";
import Settings from "../Settings";
import Info from "../Info";
import Lightroom from "../Lightroom";

export default function MiddleAndRightColumnForSearch({
  authToken,
  openLogin,
  openRegister,
  setOpenLogin,
  setOpenRegister,
  email,
  setEmail,
  password,
  setPassword,
  username,
  setUsername,
  signIn,
  signUp,
  openSettings,
  setOpenSettings,
  logout,
  companyData,
  setCompanyData,
  responseCompanyData,
  setResponseCompanyData,
  userData,
  interestedTags,
  setInterestedTags,
  tagData1,
  tagData2,
  tagData3,
  numberOfPosts,
  setNumberOfPosts,
  companyAnswers,
  searchInput,
  setSearchInput,
  searchResults,
  setSearchResults,
  pathFromSearch,
  setPathFromSearch,
  isUploading,
  setIsUploading,
  similarExperts,
  openInfoPage,
  setOpenInfoPage,
  refresh,
  setRefresh,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  feedData,
  likes,
  setLikes,
  refreshLikes,
  setRefreshLikes,
  refreshTags,
  setRefreshTags,
  setCompanyAnswers,
  articleID,
  setArticleID,
  searchFeedData,
  mobile,
  setMobile,
  lightRoomData,
  setLightRoomData,
}) {
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  const windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const showActivePage = () => {
    if (!isEmpty(lightRoomData)) {
      return (
        <Lightroom
          lightRoomData={lightRoomData}
          setLightRoomData={setLightRoomData}
        />
      );
    } else if (openLogin) {
      return (
        <Login
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          signIn={signIn}
          authToken={authToken}
          setOpenLogin={setOpenLogin}
          setOpenRegister={setOpenRegister}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          mobile={mobile}
          setOpenSettings={setOpenSettings}
        />
      );
    } else if (openRegister) {
      return (
        <Register
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          username={username}
          setUsername={setUsername}
          signUp={signUp}
          authToken={authToken}
          interestedTags={interestedTags}
          setInterestedTags={setInterestedTags}
          setOpenLogin={setOpenLogin}
          setOpenRegister={setOpenRegister}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          mobile={mobile}
          setOpenSettings={setOpenSettings}
        />
      );
    } else if (openInfoPage != "") {
      return (
        <Info
          logout={logout}
          authToken={authToken}
          setOpenSettings={setOpenSettings}
          openInfoPage={openInfoPage}
          setOpenInfoPage={setOpenInfoPage}
          companyData={companyData}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      );
    } else if (openSettings) {
      return (
        <Settings
          logout={logout}
          authToken={authToken}
          setOpenSettings={setOpenSettings}
          openInfoPage={openInfoPage}
          setOpenInfoPage={setOpenInfoPage}
        />
      );
    } else if (openArticle) {
      return (
        <Article
          authToken={authToken}
          feedData={feedData}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          refresh={refresh}
          setRefresh={setRefresh}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
          likes={likes}
          setLikes={setLikes}
          refreshLikes={refreshLikes}
          setRefreshLikes={setRefreshLikes}
          refreshTags={refreshTags}
          setRefreshTags={setRefreshTags}
          articleID={articleID}
          setArticleID={setArticleID}
        />
      );
    } else {
      return (
        <Search
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          pathFromSearch={pathFromSearch}
          setPathFromSearch={setPathFromSearch}
          similarExperts={similarExperts}
          authToken={authToken}
          tagData1={tagData1}
          tagData2={tagData2}
          tagData3={tagData3}
          searchFeedData={searchFeedData}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
          refresh={refresh}
          setRefresh={setRefresh}
          likes={likes}
          setLikes={setLikes}
          refreshLikes={refreshLikes}
          setRefreshLikes={setRefreshLikes}
          refreshTags={refreshTags}
          setRefreshTags={setRefreshTags}
          mobile={mobile}
          setMobile={setMobile}
          setOpenSettings={setOpenSettings}
        />
      );
    }
  };

  return (
    <div className="home">
      <div className="home__scrollPageLeft">{showActivePage()}</div>
      <div className="home__fixedPageRight">
        {isEmpty(authToken) ? (
          <NotLoggedInRightSidebar
            setOpenLogin={setOpenLogin}
            setOpenRegister={setOpenRegister}
          />
        ) : (
          <Profile
            authToken={authToken}
            setOpenSettings={setOpenSettings}
            companyData={companyData}
            setCompanyData={setCompanyData}
            responseCompanyData={responseCompanyData}
            setResponseCompanyData={setResponseCompanyData}
            userData={userData}
            tagData1={tagData1}
            tagData2={tagData2}
            tagData3={tagData3}
            numberOfPosts={numberOfPosts}
            setNumberOfPosts={setNumberOfPosts}
            companyAnswers={companyAnswers}
            type={"sidebar"}
            openArticle={openArticle}
            setOpenArticle={setOpenArticle}
            activeArticle={activeArticle}
            setActiveArticle={setActiveArticle}
            articleID={articleID}
            setArticleID={setArticleID}
            mobile={mobile}
            lightRoomData={lightRoomData}
            setLightRoomData={setLightRoomData}
          />
        )}
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #667781;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 18px;
  color: #667781;
  margin-bottom: -4px;
  margin-right: 11px;
`;

const ArrowRight = styled(MdArrowForwardIos)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const BusinessIcon = styled(MdBusiness)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const HouseIcon = styled(MdHouse)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const ConstructionIcon = styled(MdConstruction)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const SpaIcon = styled(MdSpa)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const HealthIcon = styled(MdHealthAndSafety)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const MedicIcon = styled(MdMedicalServices)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const InfluencerIcon = styled(MdPartyMode)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const ShopIcon = styled(MdShop)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const DeveloperIcon = styled(MdDeveloperBoard)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const TaxIcon = styled(MdMonetizationOn)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const FitnessIcon = styled(MdFitnessCenter)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const InsuranceIcon = styled(MdDocumentScanner)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;
