import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MdSearch, MdKeyboardArrowRight, MdArrowBack } from "react-icons/md";

import "../../styles/ContactPreview.css";

export default function ContactPreview({ contactData }) {
  function htmlToElements(html) {
    html = html.split("<div><br></div>").join("\n");
    html = html.split("<br>").join("\n");
    html = html.split("<div>").join("\n");
    html = html.split("</div>").join("");

    return html;
  }
  return (
    <div className="contactPreview">
      <div className="contactPreview__left">
        <div className="contactPreview__left_imageBox">
          <img
            className="contactPreview__left_imageBox_image"
            src={
              "https://www.cloud.leefes.com/api/images/placeholder/profilbild.svg"
            }
          />
        </div>
      </div>
      <div className="contactPreview__right">
        <div className="contactPreview__right_text">
          <div className="contactPreview__right_text_username">Muradin</div>
          <div className="contactPreview__right_text_lastUserMessage">
            Hallo wie gehts
          </div>
        </div>
        <div className="contactPreview__right_meta">
          <div className="contactPreview__right_meta_top">.</div>
          <div className="contactPreview__right_meta_bottom">18:30</div>
        </div>
      </div>
    </div>
  );
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;
