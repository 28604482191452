import "./Booking.css";
import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { MdArrowBack, MdPhone } from "react-icons/md";
import Calendar from "./Calendar";
import { transformTimeToMinutes } from "../functions/transformTimeToMinutes";
import { isEmpty } from "../functions/isEmpty";
import { getDateToUnixDate } from "../functions/getDateToUnixDate";
import { thisTimeToDateString } from "../functions/thisTimeToDateString";
import { getTodayAsUnixDate } from "../functions/getTodayAsUnixDate";

/**
 * Die Seite über die man den Kalender und die Uhrzeit buchen kann
 *
 * @author Masih Minawal
 * @version 1.1
 */

export default function Booking({
  authToken,
  companyData,
  infoData,
  setInfoData,
  refresh,
  setRefresh,
  setOpenInfoPage,
  setOpenSettings,
  mobile,
  setOpenLogin,
  setOpenRegister,
}) {
  /* zunächst wird das heutige Datum generiert. 
     Dieses wird in States gepackt und als Hook in den Kalendar geschickt 
  */
  var today = new Date();
  today.setDate(today.getDate() + 1); // tag auf morgen setzen, weil am gleichen tag will niemand termine
  const actualMonth = today.getMonth() + 1;
  const actualYear = today.getFullYear();
  const actualDay = today.getDate();

  /* Kalendar Props - State */
  const [thisMonthId, setThisMonthId] = useState("");
  const [thisMonth, setThisMonth] = useState(actualMonth);
  const [thisYear, setThisYear] = useState(actualYear);
  const [thisDay, setThisDay] = useState(actualDay);

  /* ausgewählte Termindaten */
  const [thisService, setThisService] = useState([]);
  const [chosenMonth, setChosenMonth] = useState();
  const [chosenYear, setChosenYear] = useState();
  const [chosenDay, setChosenDay] = useState();

  /* Dienstleistungsdaten */
  const [services, setServices] = useState([]);
  const [responseServices, setResponseServices] = useState("");

  /* Anbieter Daten */
  const [profileData, setProfileData] = useState([]);
  const [responseProfileData, setResponseProfileData] = useState("");

  const [responseAppointments, setResponseAppointments] = useState("");
  const [appointments, setAppointments] = useState("");
  const [notes, setNotes] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [formIsFilled, setFormIsFilled] = useState(false);

  /* TimeBox States */
  const [thisTime, setThisTime] = useState(9999);

  const { id } = useParams();

  let navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/profile/" + id);
  };

  const openPrivacy = () => {
    // setOpenSettings(true);
    setOpenInfoPage("privacy");
  };

  useEffect(() => {
    if (
      isEmpty(thisService) ||
      thisTime == 9999 ||
      (thisService.type == "Telefontermin" && isEmpty(tel)) ||
      (isEmpty(authToken) &&
        thisService.type != "Telefontermin" &&
        isEmpty(email)) ||
      (isEmpty(authToken) && isEmpty(name))
    ) {
      setFormIsFilled(false);
    } else {
      setFormIsFilled(true);
    }
  }, [thisService, thisTime, thisDay, thisYear, name, email, tel]);

  const handleupload = async () => {
    let now = getTodayAsUnixDate();
    let bookedTimes = [];
    let timeHours = parseInt(thisService.durationHours) * 60;
    let timeMinutes = parseInt(thisService.durationMinutes);
    let duration = timeHours + timeMinutes;
    let multipleFactor = duration / 15;
    for (let i = 0; i < multipleFactor; i++) {
      bookedTimes.push(thisTime + i * 15);
    }
    // für nicht eingeloggte
    if (isEmpty(companyData) && isEmpty(authToken) && !isEmpty(profileData)) {
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/appointments/noAuthPUTAppointment.php",
        {
          method: "PUT",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            ownerID: id,
            ownerName: profileData[0].companyname,
            ownerProfileImage: JSON.parse(profileData[0].profileImageURL)[0]
              .url,
            bookerID: 0,
            bookerName: name,
            bookerPhone: tel,
            bookerEmail: email,
            bookerProfileImage: "",
            serviceTitle: thisService.title,
            serviceID: thisService.id,
            notes: notes,
            appointmentDetails: thisService.typeDetails,
            appointmentTimes: JSON.stringify(bookedTimes),
            appointmentDay: JSON.stringify({
              thisDay: chosenDay,
              thisMonth: chosenMonth,
              thisYear: chosenYear,
            }),
            appointmentType: thisService.type,

            createdAt: now,
          }),
        }
      );
      await resa.text().then(() => uploadEnds());
    } // für eingeloggte
    else if (!isEmpty(companyData) && !isEmpty(profileData)) {
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/appointments/index.php",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            ownerID: id,
            ownerName: profileData[0].companyname,
            ownerProfileImage: JSON.parse(profileData[0].profileImageURL)[0]
              .url,
            bookerID: companyData[0].id,
            bookerName: companyData[0].companyname,
            bookerPhone: tel,
            bookerProfileImage: "",
            serviceTitle: thisService.title,
            serviceID: thisService.id,
            notes: notes,
            appointmentDetails: thisService.typeDetails,
            appointmentTimes: JSON.stringify(bookedTimes),
            appointmentDay: JSON.stringify({
              thisDay: chosenDay,
              thisMonth: chosenMonth,
              thisYear: chosenYear,
            }),
            appointmentType: thisService.type,

            createdAt: now,
          }),
        }
      );

      await resa.text().then(() => uploadEnds());
    }
  };

  const uploadEnds = () => {
    let now = getTodayAsUnixDate();
    let bookedTimes = [];
    let timeHours = parseInt(thisService.durationHours) * 60;
    let timeMinutes = parseInt(thisService.durationMinutes);
    let duration = timeHours + timeMinutes;
    let multipleFactor = duration / 15;
    for (let i = 0; i < multipleFactor; i++) {
      bookedTimes.push(thisTime + i * 15);
    }

    // für eingeloggte
    if (isEmpty(authToken) && !isEmpty(profileData)) {
      setInfoData(
        JSON.parse(
          JSON.stringify({
            ownerID: id,
            ownerName: profileData[0].companyname,
            ownerProfileImage: JSON.parse(profileData[0].profileImageURL)[0]
              .url,
            bookerID: 0,
            bookerName: name,
            bookerPhone: tel,
            bookerEmail: email,
            bookerProfileImage: "",
            serviceTitle: thisService.title,
            serviceID: thisService.id,
            notes: notes,
            appointmentDetails: thisService.typeDetails,
            appointmentTimes: JSON.stringify(bookedTimes),
            appointmentDay: JSON.stringify({
              thisDay: chosenDay,
              thisMonth: chosenMonth,
              thisYear: chosenYear,
            }),
            appointmentType: thisService.type,

            createdAt: now,
          })
        )
      );
    } // für nicht eingeloggte
    else if (!isEmpty(companyData) && !isEmpty(profileData)) {
      setInfoData(
        JSON.parse(
          JSON.stringify({
            ownerID: id,
            ownerName: profileData[0].companyname,
            ownerProfileImage: JSON.parse(profileData[0].profileImageURL)[0]
              .url,
            bookerID: companyData[0].id,
            bookerName: companyData[0].companyname,
            bookerPhone: tel,
            bookerProfileImage: "",
            serviceTitle: thisService.title,
            serviceID: thisService.id,
            notes: notes,
            appointmentDetails: thisService.typeDetails,
            appointmentTimes: JSON.stringify(bookedTimes),
            appointmentDay: JSON.stringify({
              thisDay: chosenDay,
              thisMonth: chosenMonth,
              thisYear: chosenYear,
            }),
            appointmentType: thisService.type,
            createdAt: now,
          })
        )
      );
    }

    setRefresh(!refresh);
  };

  //get companyData
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(id)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/companies/?id=" + id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileData(value));
    } else if (!isEmpty(id)) {
      const res = await fetch(
        "https://www.cloud.leefes.com/api/companies/noAuthOnlyGET.php?id=" + id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileData(value));
    }
  }, [authToken, id]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseProfileData !== "") {
      setProfileData(JSON.parse(responseProfileData));
    }
  }, [responseProfileData, authToken]);

  //get services
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(id) && !isEmpty(profileData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/services/?companyID=" + id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res.text().then((value) => setResponseServices(value));
    } else if (!isEmpty(id)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/services/noAuthOnlyGET.php?companyID=" +
          id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res.text().then((value) => setResponseServices(value));
    }
  }, [authToken, id, profileData]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseServices !== "") {
      setServices(JSON.parse(responseServices));
    }
  }, [responseServices, authToken]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (!isEmpty(services)) {
      setThisService(services[0]);
    }
  }, [services]);

  //get appointmentData
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(id)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/appointments/?ownerID=" + id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseAppointments(value));
    } else if (!isEmpty(id)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/appointments/noAuthOnlyGET.php?ownerID=" +
          id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseAppointments(value));
    }
  }, [authToken, id]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseAppointments !== "") {
      setAppointments(JSON.parse(responseAppointments));
    }
  }, [responseAppointments, authToken]);

  function getReadableTimeFromQuarterTimeFormat(time) {
    const hour = Math.floor(time / 60);
    let hour2 = "";
    if (hour < 10) {
      hour2 = "0";
    }
    const minutes = time % 60;
    let minutes2 = "";
    if (minutes < 1) {
      minutes2 = "0";
    }
    if (time == 9999) {
      return "";
    }
    return hour2 + hour + ":" + minutes2 + minutes;
  }

  const callProfile = async () => {
    if (!isEmpty(profileData) && !isEmpty(profileData[0].phone)) {
      let phoneNumber = profileData[0].phone;

      window.open("tel:" + phoneNumber);
      let now = getTodayAsUnixDate();

      const resa = await fetch(
        "https://www.cloud.leefes.com/api/calls/noAuthPUTCall.php",
        {
          method: "PUT",
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            ownerID: id,
            ownerName: profileData[0].companyname,
            ownerProfileImage: JSON.parse(profileData[0].profileImageURL)[0]
              .url,
            createdAt: now,
          }),
        }
      );
      //  await resa.text().then(() => uploadEnds());
    }
  };

  const openSettings = () => {
    setOpenLogin(false);
    setOpenRegister(false);
    setOpenSettings(true);
  };

// Facebook aufruf track
const [searchParams] = useSearchParams();
useEffect(async() => {
  if(searchParams.toString().substring(0,6) == "fbclid"){
     let fbclid = searchParams.toString().substring(7);
     let now = getTodayAsUnixDate();

    const resa = await fetch(
      "https://www.cloud.leefes.com/api/fbclid/noAuthPUTClick.php",
      {
        method: "PUT",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          fbclid: fbclid,
          createdAt: now,
        }),
      }
    );
  }

}, []);

  return (
    <div className="booking">
      <div className="booking__fixedTopBar">
        <div
          className="booking__fixedTopBar_left"
          onClick={() => handleBackButton()}
        >
          <BackIcon />
        </div>
        <div className="booking__fixedTopBar_middle">Termin buchen</div>
      </div>
      <div className="booking__scrollContent">
        <div className="booking__fixedTopBar_right">
          <div className="booking__fixedTopBar_right_profileBox">
            <div className="booking__fixedTopBar_right_profileBox_left">
              <div className="booking__fixedTopBar_right_profileBox_left_imageBox">
                {!isEmpty(profileData) ? (
                  <>
                    <img
                      className="booking__fixedTopBar_right_profileBox_left_imagebox_image"
                      src={JSON.parse(profileData[0].profileImageURL)[0].url}
                    />
                  </>
                ) : (
                  <>
                    <img
                      className="booking__fixedTopBar_right_profileBox_left_imagebox_image"
                      src={""}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="booking__fixedTopBar_right_profileBox_right">
              {!isEmpty(profileData) ? (
                <>
                  <div className="booking__fixedTopBar_right_profileBox_right_topText">
                    {profileData[0].companyname}
                  </div>
                  <div className="booking__fixedTopBar_right_profileBox_right_bottomText">
                    <> Experte für </>
                    {profileData[0].tag1JSON != "[]" ? (
                      <div className="searchResultExpert__right_bottom_tag">
                        {JSON.parse(profileData[0].tag1JSON)[0].title}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>...</>
              )}
            </div>
          </div>
          {!isEmpty(profileData) && profileData[0].phoneActive == "true" ? (
            <div className="booking__fixedTopBar_right_callBtnBox">
              <div
                className="booking__fixedTopBar_right_callBtnBox_callBtn"
                onClick={() => callProfile()}
              >
                <PhoneIcon /> Anrufen
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="booking__scrollContent_elementBox">
          <div className="booking__scrollContent_elementBox_title">
            Leistung auswählen
          </div>
          <div className="booking__scrollContent_elementBox_content">
            {!isEmpty(services) ? (
              <>
                {services.map((service) => {
                  if (thisService == service) {
                    return (
                      <div
                        className="booking__scrollContent_elementBox_content_leistungComponent_active"
                        onClick={() => setThisService(service)}
                        key={service.title}
                      >
                        <div className="booking__scrollContent_elementBox_content_leistungComponent_top">
                          {service.title}
                        </div>
                        <div className="booking__scrollContent_elementBox_content_leistungComponent_bottom">
                          {service.priceEuro + ","}
                          {service.priceCent == 0 ? (
                            <>00€, </>
                          ) : (
                            <>{service.priceCent + "€, "}</>
                          )}
                          {service.durationHours == 0 ? (
                            <></>
                          ) : (
                            <>{service.durationHours + "h "}</>
                          )}
                          {service.durationMinutes == 0 ? (
                            <>, </>
                          ) : (
                            <>{service.durationMinutes + "Min, "}</>
                          )}
                          {service.type == "festeAdresse" ? (
                            <>{service.typeDetails}</>
                          ) : (
                            <>{service.type}</>
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className="booking__scrollContent_elementBox_content_leistungComponent"
                        onClick={() => setThisService(service)}
                        key={service.title}
                      >
                        <div className="booking__scrollContent_elementBox_content_leistungComponent_top">
                          {service.title}
                        </div>
                        <div className="booking__scrollContent_elementBox_content_leistungComponent_bottom">
                          {service.priceEuro + ","}
                          {service.priceCent == 0 ? (
                            <>00€, </>
                          ) : (
                            <>{service.priceCent + "€, "}</>
                          )}
                          {service.durationHours == 0 ? (
                            <></>
                          ) : (
                            <>{service.durationHours + "h "}</>
                          )}
                          {service.durationMinutes == 0 ? (
                            <>, </>
                          ) : (
                            <>{service.durationMinutes + "Min, "}</>
                          )}
                          {service.type == "festeAdresse" ? (
                            <>{service.typeDetails}</>
                          ) : (
                            <>{service.type}</>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {isEmpty(authToken) ? (
          <div className="booking__scrollContent_elementBox">
            <div className="booking__scrollContent_elementBox_title">
              Ihre Kontaktdaten
            </div>
            <div className="booking__scrollContent_elementBox_content">
              <div className="calenderConfig__scrollContent_contentBox_content_service_inputBox">
                <input
                  className="calenderConfig__scrollContent_contentBox_content_service_inputBox_input"
                  value={name}
                  placeholder={"Namen eingeben"}
                  onChange={(e) => setName(e.target.value)}
                  type={"name"}
                />
              </div>
              {thisService.type == "Telefontermin" ? (
                <div className="booking__scrollContent_elementBox_content">
                  <div className="calenderConfig__scrollContent_contentBox_content_service_inputBox">
                    <input
                      className="calenderConfig__scrollContent_contentBox_content_service_inputBox_input"
                      value={tel}
                      placeholder={"Telefonnummer eingeben"}
                      onChange={(e) => setTel(e.target.value)}
                      type={"tel"}
                    />
                  </div>
                </div>
              ) : (
                <div className="calenderConfig__scrollContent_contentBox_content_service_inputBox">
                  <input
                    className="calenderConfig__scrollContent_contentBox_content_service_inputBox_input"
                    value={email}
                    placeholder={"Emailadresse eingeben"}
                    onChange={(e) => setEmail(e.target.value)}
                    type={"email"}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="booking__scrollContent_elementBox">
          <div className="booking__scrollContent_elementBox_title">
            Termin auswählen
          </div>
          <div className="booking__scrollContent_elementBox_content">
            <Calendar
              thisMonthId={thisMonthId}
              setThisMonthId={setThisMonthId}
              thisMonth={thisMonth}
              setThisMonth={setThisMonth}
              thisYear={thisYear}
              setThisYear={setThisYear}
              thisDay={thisDay}
              setThisDay={setThisDay}
              thisTime={thisTime}
              setThisTime={setThisTime}
              actualMonth={actualMonth}
              actualYear={actualYear}
              actualDay={actualDay}
              profileData={profileData}
              thisService={thisService}
              appointments={appointments}
              chosenDay={chosenDay}
              chosenMonth={chosenMonth}
              chosenYear={chosenYear}
              setChosenDay={setChosenDay}
              setChosenMonth={setChosenMonth}
              setChosenYear={setChosenYear}
            />
          </div>
        </div>
        {formIsFilled ? (
          <>
            <div className="booking__scrollContent_CTAbox">
              <div
                className="booking__scrollContent_CTAbox_CTA"
                onClick={() => handleupload()}
                id={"CTA_newAppointment"}
              >
                Termin am{" "}
                {chosenDay +
                  "." +
                  chosenMonth +
                  "." +
                  chosenYear +
                  " " +
                  getReadableTimeFromQuarterTimeFormat(thisTime) +
                  " "}
                buchen
              </div>
              {!authToken ? (
                <div className="booking__scrollContent_privacybox">
                  <div className="booking__scrollContent_privacybox_privacy">
                    Beim Klicken auf den Button akzeptieren Sie die{" "}
                    <span className="activeLink" onClick={() => openPrivacy()}>
                      Datenschutzbestimmungen
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="booking__scrollContent_CTAbox_disabled">
              <div
                className="booking__scrollContent_CTAbox_disabled_CTA"
                id={"CTA_newAppointment"}
              >
                Bitte alle Felder ausfüllen
              </div>
            </div>
          </>
        )}
      </div>
      {mobile && isEmpty(authToken) ? (
        <div className="search__infoLinks">
          <div className="sidebar__footer_links" onClick={() => openSettings()}>
            Impressum. Datenschutz.
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

const BackIcon = styled(MdArrowBack)`
  font-size: 30px;
  color: #000;
  padding-right: 6px;
  padding-top: 3px;
`;

const PhoneIcon = styled(MdPhone)`
  font-size: 30px;
  color: #575757;
  margin-right: 15px;
  padding-top: 3px;
`;
