import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdArrowBack, MdFilterList } from "react-icons/md";

import "../styles/CalenderConfig.css";
import ContactPreview from "../components/contacts/ContactPreview";
import { useNavigate } from "react-router";
import CalenderServiceCreateConfig from "../components/contacts/CalenderServiceCreateConfig";
import CalenderWeekdayConfig from "../components/contacts/CalenderWeekdayConfig";
import { getTodayAsUnixDate } from "../functions/getTodayAsUnixDate";
import { arraysEqual } from "../functions/arraysEqual";

export default function CalenderConfig({
  authToken,
  setCreateNewQuestion,
  feedData,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
  servicesData,
  refresh,
  setRefresh,
  openCalenderConfig,
  setOpenCalenderConfig,
  mobile,
  setMobile,
}) {
  const [openWeekdays, setOpenWeekdays] = useState(
    JSON.parse(
      JSON.stringify([
        {
          id: "0",
          day: "Montag",
          startInMinutes: "480",
          endInMinutes: "1080",
          active: "true",
        },
        {
          id: "1",
          day: "Dienstag",
          startInMinutes: "480",
          endInMinutes: "1080",
          active: "true",
        },
        {
          id: "2",
          day: "Mittwoch",
          startInMinutes: "480",
          endInMinutes: "1080",
          active: "true",
        },
        {
          id: "3",
          day: "Donnerstag",
          startInMinutes: "480",
          endInMinutes: "1080",
          active: "true",
        },
        {
          id: "4",
          day: "Freitag",
          startInMinutes: "480",
          endInMinutes: "1080",
          active: "true",
        },
        {
          id: "5",
          day: "Samstag",
          startInMinutes: "480",
          endInMinutes: "1080",
          active: "false",
        },
        {
          id: "6",
          day: "Sonntag",
          startInMinutes: "480",
          endInMinutes: "1080",
          active: "false",
        },
      ])
    )
  );
  const [services, setServices] = useState([]);
  const [calenderActive, setCalenderActive] = useState(false);
  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState(false);
  const [sentCounter, setSentCounter] = useState(0);

  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/search");
    }
  }, [authToken]);

  useEffect(() => {
    if (!isEmpty(companyData)) {
      setOpenWeekdays(
        JSON.parse(JSON.stringify(JSON.parse(companyData[0].openWeekdays)))
      );
      if (companyData[0].calenderActive == "true") {
        setCalenderActive(true);
      }
    }
  }, [companyData]);

  useEffect(() => {
    if (!isEmpty(servicesData)) {
      let helpArray = servicesData;
      setServices(JSON.parse(JSON.stringify(helpArray)));
    }
  }, [servicesData]);

  useEffect(() => {
    // calenderActive,services, wochentage, leere services, keine Leistungsdauer angegeben
    if (!isEmpty(companyData)) {
      if (
        (((calenderActive == true && companyData[0].calenderActive == "true") ||
          (calenderActive == false &&
            companyData[0].calenderActive == "false")) &&
          arraysEqual(servicesData, services) &&
          arraysEqual(JSON.parse(companyData[0].openWeekdays), openWeekdays)) ||
        isEmpty(services)
      ) {
        // sind gleich, startzustand
        setChangesHaveBeenMade(false);
      } else {
        let changes = true;
        for (let i = 0; i < services.length; i++) {
          if (
            parseInt(services[i].durationHours) +
              parseInt(services[i].durationMinutes) ==
              0 ||
            services[i].title == ""
          ) {
            changes = false;
          }
        }
        if (!changes) setChangesHaveBeenMade(false);
        else {
          setChangesHaveBeenMade(true);
        }
      }
      if (companyData[0].calenderActive == "true" && calenderActive == false) {
        //wenn kalender aktiv war und ohne leistung deaktivierd wird
        setChangesHaveBeenMade(true);
      }
    }
  }, [calenderActive, openWeekdays, services]);

  useEffect(() => {
    if (calenderActive == false) {
      document.getElementById("calenderConfigLeistungen").style.display =
        "none";
      document.getElementById("calenderConfigWochentage").style.display =
        "none";
    } else {
      document.getElementById("calenderConfigLeistungen").style.display =
        "block";
      document.getElementById("calenderConfigWochentage").style.display =
        "block";
    }
  }, [calenderActive]);

  const hours = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
  ];

  const minutes = ["0", "15", "30", "45"];

  const getHoursFromMinuteDateFormat = (timeInMinutesString) => {
    let timeInMinutes = parseInt(timeInMinutesString);
    let hours = Math.floor(timeInMinutes / 60);
    return hours.toString();
  };

  const getMinutesFromMinuteDateFormat = (timeInMinutesString) => {
    let timeInMinutes = parseInt(timeInMinutesString);
    let hours = Math.floor(timeInMinutes / 60);
    let minutes = timeInMinutes - hours * 60;
    return minutes.toString();
  };

  const addEmptyServiceToState = () => {
    let helpArray = [];
    services.map((service) => helpArray.push(service));
    let newService = JSON.stringify({
      title: "",
      durationHours: "0",
      durationMinutes: "15",
      priceEuro: "0",
      priceCent: "0",
      createdAt: getTodayAsUnixDate(),
      companyID: companyData[0].id,
      status: "1",
      type: "Telefontermin"
    });
    helpArray.push(JSON.parse(newService));
    setServices(helpArray);
  };

  const removeServiceFromState = (indexToRemove) => {
    let helpArray = [];
    services.map((service, index) => {
      helpArray.push(service);
    });

    const index = helpArray.indexOf(helpArray[indexToRemove]);
    if (index > -1) {
      // only splice array when item is found
      helpArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setServices(helpArray);
  };

  const mutateObjectInState = (objectIndex, key, value) => {
    let helpArray = [];
    services.map((service, index) => {
      helpArray.push(service);
    });
    if (key == "title") {
      helpArray[objectIndex].title = value;
    }
    if (key == "durationHours") {
      helpArray[objectIndex].durationHours = value;
    }
    if (key == "durationMinutes") {
      helpArray[objectIndex].durationMinutes = value;
    }
    if (key == "priceEuro") {
      helpArray[objectIndex].priceEuro = value;
    }
    if (key == "priceCent") {
      helpArray[objectIndex].priceCent = value;
    }
    if (key == "type") {
      helpArray[objectIndex].type = value;
    }
    if (key == "typeDetails") {
      helpArray[objectIndex].typeDetails = value;
    }
    setServices(helpArray);
  };

  const mutateObjectInWeekdays = (objectIndex, key, value) => {
    let helpArray = [];
    openWeekdays.map((service, index) => {
      helpArray.push(service);
    });
    if (key == "active") {
      if (helpArray[objectIndex].active == "false") {
        helpArray[objectIndex].active = "true";
      } else {
        helpArray[objectIndex].active = "false";
      }
    }
    if (key == "startHours") {
      let startBefore = getHoursFromMinuteDateFormat(
        helpArray[objectIndex].startInMinutes
      );
      let difference = parseInt(startBefore) - parseInt(value);
      let newStartHours =
        parseInt(helpArray[objectIndex].startInMinutes) - difference * 60;
      helpArray[objectIndex].startInMinutes = newStartHours;
    }
    if (key == "startMinutes") {
      let startBefore = getMinutesFromMinuteDateFormat(
        helpArray[objectIndex].startInMinutes
      );
      let difference = parseInt(startBefore) - parseInt(value);
      let newStartMinutes =
        parseInt(helpArray[objectIndex].startInMinutes) - difference;
      helpArray[objectIndex].startInMinutes = newStartMinutes;
    }
    if (key == "endHours") {
      let startBefore = getHoursFromMinuteDateFormat(
        helpArray[objectIndex].endInMinutes
      );
      let difference = parseInt(startBefore) - parseInt(value);
      let newStartHours =
        parseInt(helpArray[objectIndex].endInMinutes) - difference * 60;
      helpArray[objectIndex].endInMinutes = newStartHours;
    }
    if (key == "endMinutes") {
      let startBefore = getMinutesFromMinuteDateFormat(
        helpArray[objectIndex].endInMinutes
      );
      let difference = parseInt(startBefore) - parseInt(value);
      let newStartMinutes =
        parseInt(helpArray[objectIndex].endInMinutes) - difference;
      helpArray[objectIndex].endInMinutes = newStartMinutes;
    }
    setOpenWeekdays(helpArray);
  };

  const handleupload = async () => {
    console.log("first");
    // hier nur gucken ob was gelöscht wurde
    let uploadCounter = 0;

    let CountNumEqualObjects = 0;
    for (let k = 0; k < servicesData.length; k++) {
      let isDeletedOnZero = 0;
      for (let j = 0; j < services.length; j++) {
        if (servicesData[k].createdAt == services[j].createdAt) {
          isDeletedOnZero += 1;
          CountNumEqualObjects += 1;
        }
      }

      if (isDeletedOnZero == 0) {
        const resa = await fetch(
          "https://www.cloud.leefes.com/api/services/insertAndUpdate.php?id=" +
            servicesData[k].id,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authToken.JWT}`,
              "Content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: JSON.stringify({
              status: "0",
            }),
          }
        );
        await resa.text().then(() => uploadCounter++);
      }
    }
    if (
      uploadCounter + CountNumEqualObjects == servicesData.length ||
      CountNumEqualObjects == servicesData.length
    ) {
      secondUpload();
    }
  };

  const secondUpload = async () => {
    console.log("second");
    // button disablen
    // companydata mutaten an der stelle calenderactive und openweekdays
    // uploaden

    let calenderActiveString;
    if (calenderActive) {
      calenderActiveString = "true";
    } else {
      calenderActiveString = "false";
    }

    const resa = await fetch(
      "https://www.cloud.leefes.com/api/companies/?id=" + companyData[0].id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          openWeekdays: JSON.stringify(openWeekdays),
          calenderActive: calenderActiveString,
        }),
      }
    );

    await resa.text().then(() => nextUpload());
  };

  const nextUpload = async () => {
    console.log("third");

    // alle services die neu sind putten. alle die nicht neu sind, updaten, alle die
    // nicht übermittelt wurden, aber existieren, auf status 0 setzen.
    let uploadCounter = 0;
    // für jedes element muss ein eigener fetch gestartet werden
    for (let i = 0; i < services.length; i++) {
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/services/insertAndUpdate.php",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            companyID: services[i].companyID,
            title: services[i].title,
            durationHours: services[i].durationHours,
            durationMinutes: services[i].durationMinutes,
            priceEuro: services[i].priceEuro,
            priceCent: services[i].priceCent,
            type: services[i].type,
            typeDetails: services[i].typeDetails,
            createdAt: services[i].createdAt,
            status: services[i].status,
          }),
        }
      );

      await resa.text().then(() => uploadCounter++);
    }
    if (uploadCounter == services.length) {
      uploadEnds();
    }

    // jede serviceData durchloopen und die createdAts vergleichen
    // wenn von serviceData welche da sind, die keinen match gefunden haben,
    // dann heisst das der ist gelöscht und der statusupdate muss auf 0
  };

  const uploadEnds = () => {
    console.log("end");

    // Button wieder normal machen
    // companydata laden mittels setRefresh
    // openCalenderconfig auf false setzen
    setRefresh(!refresh);
    setOpenCalenderConfig(false);
  };

  return (
    <div className="calenderConfig">
      <div className="calenderConfig__fixedTopBar">
        <div className="calenderConfig__fixedTopBar_leftColumn">
          {mobile ? (
            <div className="calenderConfig__fixedTopBar_leftColumn_wrapper">
              <div onClick={() => setOpenCalenderConfig(false)}>
                <BackIcon />
              </div>
              <div className="calenderConfig__fixedTopBar_leftColumn_topText">
                Terminkalender
              </div>
            </div>
          ) : (
            <div className="calenderConfig__fixedTopBar_leftColumn_topText">
              Terminkalender
            </div>
          )}
        </div>
        <div className="calenderConfig__fixedTopBar_middleColumn"></div>
        <div className="calenderConfig__fixedTopBar_rightColumn">
          <div className="calenderConfig__fixedTopBar_rightColumn_saveChangesButtonBox">
            {changesHaveBeenMade ? (
              <div
                className="calenderConfig__fixedTopBar_rightColumn_savesChangesButtonBox_savesChangesButton"
                onClick={() => handleupload()}
              >
                speichern
              </div>
            ) : (
              <div className="calenderConfig__fixedTopBar_rightColumn_savesChangesButtonBox_savesChangesButton_disabled">
                speichern
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="calenderConfig__scrollContent">
        <div className="calenderConfig__scrollContent_contentBox">
          <div className="calenderConfig__scrollContent_contentBox_title">
            Terminkalender aktivieren
          </div>
          <div className="calenderConfig__scrollContent_contentBox_descriptionText">
            Kunden ermöglichen einen Termin bei dir zu buchen.
          </div>
          <div className="calenderConfig__scrollContent_contentBox_content">
            <div
              className="calenderConfig__scrollContent_contentBox_content_calenderActiveBox"
              onClick={() => setCalenderActive(!calenderActive)}
            >
              <div className="calenderConfig__scrollContent_contentBox_content_calenderActiveBox_RadioButton">
                {calenderActive ? (
                  <div className="calenderConfig__scrollContent_contentBox_content_calenderActiveBox_RadioButton_clickedBox"></div>
                ) : (
                  <></>
                )}
              </div>
              <div className="calenderConfig__scrollContent_contentBox_content_calenderActiveBox_Text">
                aktivieren
              </div>
            </div>
          </div>
        </div>
        <div
          className="calenderConfig__scrollContent_contentBox"
          id={"calenderConfigLeistungen"}
        >
          <div className="calenderConfig__scrollContent_contentBox_title">
            Leistungen
          </div>
          {services.map((service, index) => {
            return (
              <CalenderServiceCreateConfig
                key={index}
                index={index}
                title={service.title}
                durationHours={service.durationHours}
                durationMinutes={service.durationMinutes}
                priceEuro={service.priceEuro}
                priceCent={service.priceCent}
                type={service.type}
                typeDetails={service.typeDetails}
                hours={hours}
                minutes={minutes}
                removeServiceFromState={removeServiceFromState}
                mutateObjectInState={mutateObjectInState}
              />
            );
          })}
          <div className="calenderConfig__scrollContent_contentBox_descriptionText"></div>

          <div
            className="calenderConfig__scrollContent_contentBox_addMoreServices"
            onClick={() => addEmptyServiceToState()}
          >
            + Weitere Leistungen hinzufügen
          </div>
        </div>
        <div
          className="calenderConfig__scrollContent_contentBox"
          id={"calenderConfigWochentage"}
        >
          <div className="calenderConfig__scrollContent_contentBox_title">
            Wochentage und Uhrzeit festlegen
          </div>
          <div className="calenderConfig__scrollContent_contentBox_descriptionText">
            Zu welcher Zeit dürfen Termine gebucht werden?
          </div>
          {openWeekdays.map((openWeekday, index) => (
            <CalenderWeekdayConfig
              day={openWeekday.day}
              active={openWeekday.active}
              startHours={getHoursFromMinuteDateFormat(
                openWeekday.startInMinutes
              )}
              startMinutes={getMinutesFromMinuteDateFormat(
                openWeekday.startInMinutes
              )}
              endHours={getHoursFromMinuteDateFormat(openWeekday.endInMinutes)}
              endMinutes={getMinutesFromMinuteDateFormat(
                openWeekday.endInMinutes
              )}
              hours={hours}
              minutes={minutes}
              mutateObjectInWeekdays={mutateObjectInWeekdays}
              key={index}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 20px;
  color: #575757;
  margin-top: 4px;
`;

const FilterIcon = styled(MdFilterList)`
  font-size: 20px;
  color: #575757;
  margin-top: 1px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #575757;
  margin-top: 5px;
  margin-right: 15px;
`;
