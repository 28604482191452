import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../../styles/Tag.css";
import { isEmpty } from "../../functions/isEmpty";

export default function Tag({
  text,
  tagID,
  interestedTags,
  setInterestedTags,
  tagCounter,
  setTagCounter,
  type,
}) {
  const [active, setActive] = useState(false);

  const AddOrRemoveFromState = () => {
    let helpArray = interestedTags;
    let helpArray2 = [];
    if (active) {
      setActive(false);
      helpArray.map((element, index) => {
        if (element.tagID == tagID) {
          console.log(element);
        } else {
          helpArray2.push({ tagID: element.tagID, title: element.title });
        }
        console.log(helpArray2);
      });
      setInterestedTags(helpArray2);
      console.log(JSON.parse(JSON.stringify(interestedTags)));
      setTagCounter(tagCounter - 1);
    } else {
      setActive(true);
      helpArray.push({ tagID: tagID, title: text });
      setInterestedTags(helpArray);
      console.log(JSON.parse(JSON.stringify(interestedTags)));
      setTagCounter(tagCounter + 1);
    }
  };

  if (active == true) {
    return (
      <div className="tag__active" onClick={() => AddOrRemoveFromState()}>
        {text}
      </div>
    );
  }
  if (text == "") {
    return <></>;
  }
  if (type == "search") {
    return <div className="tag">{text}</div>;
  }

  return (
    <div className="tag" onClick={() => AddOrRemoveFromState()}>
      {text}
    </div>
  );
}
const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;
