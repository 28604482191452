import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import {
  MdAdd,
  MdArrowBack,
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCancel,
  MdFilterList,
  MdOutlineImage,
  MdSearch,
} from "react-icons/md";

import "../styles/Lightroom.css";
import { useNavigate, useNavigationType } from "react-router";

export default function Lightroom({ lightRoomData, setLightRoomData }) {
  let navigate = useNavigate();
  const [imageCounter, setImageCounter] = useState(0);

  // lighroom kriegt bildURL objekte ["www.url.de", "www.url.de"];
  // jedes bild wird mittig in maximaler breite dargestellt und auto höhe
  // onclick nach rechts (unten) wird der local state um 1 erhöht, der im img src steht

  const handleClickArrow = (direction) => {
    let newCountingNumber = imageCounter;

    if (direction == "back") {
      newCountingNumber = imageCounter - 1;
      if (newCountingNumber < 0) {
        newCountingNumber = lightRoomData.length - 1;
      }
      setImageCounter(newCountingNumber);
    } else if (direction == "forward") {
      newCountingNumber = imageCounter + 1;

      if (newCountingNumber >= lightRoomData.length) {
        newCountingNumber = 0;
      }

      setImageCounter(newCountingNumber);
    }
  };

  return (
    <div className="lightroom">
      <div className="lightroom__fixedTopBar">
        <div className="lightroom__fixedTopBar_wrapper">
          <div className="lightroom__fixedTopBar_text">Bilderzoom</div>
          <div
            className="lightroom__fixedTopBar_cancelIcon"
            onClick={() => setLightRoomData([])}
          >
            <CancelIcon />
          </div>
        </div>
      </div>
      <div className="lightroom__fixedImageBox">
        <img
          src={lightRoomData[imageCounter]}
          className="lightroom__fixedImageBox_image"
        />
        <div className="lightroom__fixedImageBox_arrows">
          <div
            className="lightroom__fixedImageBox_arrows_arrowLeft"
            onClick={() => handleClickArrow("back")}
          >
            <ArrowLeftIcon />
          </div>
          <div
            className="lightroom__fixedImageBox_arrows_arrowRight"
            onClick={() => handleClickArrow("forward")}
          >
            <ArrowForwardIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 20px;
  color: #000000;
  margin-top: -1px;
  margin-left: 3px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 30px;
  color: #000;
  padding-right: 10px;
  padding-top: 7px;
`;

const ImageIcon = styled(MdOutlineImage)`
  font-size: 30px;
  color: #388eff;
  margin-bottom: 5px;
`;
const CancelIcon = styled(MdCancel)`
  font-size: 35px;
  color: #575757;
  margin-tops: 2px;
`;

const ArrowLeftIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #388eff;
  margin-bottom: 5px;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 20px;
  color: #388eff;
  margin-bottom: 5px;
`;
