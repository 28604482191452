import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  MdSearch,
  MdKeyboardArrowRight,
  MdArrowBack,
  MdFavorite,
  MdFavoriteBorder,
} from "react-icons/md";

import "../../styles/Answer.css";
import { isEmpty } from "../../functions/isEmpty";

export default function Answer({ answerData, uploadLike, likes }) {
  const [isLiked, setIsLiked] = useState(false);
  const [localAddCountToLike, setLocalAddCountToLike] = useState(0);

  const clickOnLikeButton = () => {
    if (isLiked == false) {
      // like putten
      uploadLike(false, answerData.companyID);
      setIsLiked(true);
      setLocalAddCountToLike(localAddCountToLike + 1);
    } else {
      // like status auf 0 setzen
      uploadLike(true, answerData.companyID);
      setIsLiked(false);
      setLocalAddCountToLike(localAddCountToLike - 1);
    }
  };

  // appointmentData als JSON in state packen
  useEffect(() => {
    if (!isEmpty(likes)) {
      likes.map((like) => {
        if (
          like.postID == answerData.postID &&
          like.answerID == answerData.companyID
        ) {
          setIsLiked(true);
        }
      });
      console.log("nicht leer");
    }
  }, [likes]);

  function htmlToElements(html) {
    html = html.split("<div><br></div>").join("\n");
    html = html.split("<br>").join("\n");
    html = html.split("<div>").join("\n");
    html = html.split("</div>").join("");

    return html;
  }
  return (
    <div className="answer">
      <div className="answer__scrollContent_element">
        <div className="answer__scrollContent_element_answer">
          <div className="answer__scrollContent_element_answer_answererProfilepicbox">
            <img
              className="answer__scrollContent_element_answer_answererProfilepicbox_image"
              src={JSON.parse(answerData.companyProfileImageURL)[0].url}
            />
          </div>
          <div className="answer__scrollContent_element_answer_answerText">
            {answerData.companyname}
            {!isEmpty(answerData.imageURL) ? (
              <img
                src={answerData.imageURL}
                className="question__scrollContent_element_answer_answerText_image"
              />
            ) : (
              <></>
            )}
            <pre>{htmlToElements(answerData.text)}</pre>
          </div>
          <div className="question__scrollContent_element_answer_buttonBox">
            {isLiked ? (
              <div
                className="question__scrollContent_element_answer_buttonBox_button"
                onClick={() => clickOnLikeButton()}
              >
                <div>
                  <LikeButtonFilled />
                </div>
                <div>
                  {parseInt(answerData.likesCount) + localAddCountToLike}
                </div>
              </div>
            ) : (
              <div
                className="question__scrollContent_element_answer_buttonBox_button"
                onClick={() => clickOnLikeButton()}
              >
                <div>
                  <LikeButtonOutlined />
                </div>
                <div>
                  {parseInt(answerData.likesCount) + localAddCountToLike}
                </div>
              </div>
            )}
          </div>
        </div>
        {/*

        <div className="answer__scrollContent_element_reply">
          <div className="answer__scrollContent_element_reply_ownImageThumbnailbox">
            <img
              className="answer__scrollContent_element_reply_ownImageThumbnailbox_image"
              src="https://cdn.discordapp.com/attachments/832697220406181924/1075516311045681173/IMG_8206.png"
            />
          </div>
          <div className="answer__scrollContent_element_replyBox">
            <input
              className="answer__scrollContent_element_replyBox_input"
              placeholder="Kommentieren"
            />
          </div>
        </div>

        */}
      </div>
    </div>
  );
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;

const LikeButtonOutlined = styled(MdFavoriteBorder)`
  font-size: 22px;
  color: #575757;
  margin-bottom: -10px;
`;

const LikeButtonFilled = styled(MdFavorite)`
  font-size: 22px;
  color: #1877f2;
  margin-bottom: -10px;
`;
