import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MdSearch, MdKeyboardArrowRight, MdArrowBack } from "react-icons/md";

import "../../styles/Answer.css";
import { isEmpty } from "../../functions/isEmpty";

export default function QuestionWithOwnAnswer({
  answerData,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  articleID,
  setArticleID,
  id,
  authToken
}) {
  function htmlToElements(html) {
    html = html.split("<div><br></div>").join("\n");
    html = html.split("<br>").join("\n");
    html = html.split("<div>").join("\n");
    html = html.split("</div>").join("");

    return html;
  }

  const openArticleAndSetActiveArticle = () => {
    if(!isEmpty(authToken)){
      setOpenArticle(true);
      setArticleID(answerData.postID);
      console.log(answerData.postID);
    }
  };

  return (
    <div className="profile__userAnswers_element">
      <div className="profile__userAnswers_tags">
        {answerData.postTag1Title != "" ? (
          <div className="profile__userAnswers_tags_tag">
            {answerData.postTag1Title}
          </div>
        ) : (
          <></>
        )}
        {answerData.postTag2Title != "" ? (
          <div className="profile__userAnswers_tags_tag">
            {answerData.postTag2Title}
          </div>
        ) : (
          <></>
        )}
        {answerData.postTag3Title != "" ? (
          <div className="profile__userAnswers_tags_tag">
            {answerData.postTag3Title}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div onClick={() => openArticleAndSetActiveArticle()}>
        <div className="profile__userAnswers_question">
          {answerData.postTitle}
        </div>
        <div className="profile__userAnswers_answer">
          <div className="profile__userAnswers_answer_answererProfilepicbox">
            <img
              className="profile__userAnswers_answer_answererProfilepicbox_img"
              src={JSON.parse(answerData.companyProfileImageURL)[0].url}
            />
          </div>
          <div className="profile__userAnswers_answer_answerText">
            {answerData.companyname}
            {!isEmpty(answerData.imageURL) ? (
              <img
                src={answerData.imageURL}
                className="question__scrollContent_element_answer_answerText_image"
              />
            ) : (
              <></>
            )}{" "}
            <br /> <pre>{htmlToElements(answerData.text)}</pre>
          </div>
        </div>
        {/* V1.1
            <div className="profile__userAnswers_moreButton">Mehr</div>
      <div className="profile__userAnswers_helpfulFortext">
        11 Personen fanden dies hilfreich
              </div>
      */}
      </div>
    </div>
  );
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;
