import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import "../styles/Profile.css";
import { MdAdd, MdArrowBack, MdFilterList, MdReply } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import TagFetched from "../components/buttons/TagFetched";
import PostSingleWithOwnAnswer from "../components/posts/PostSingleWithOwnAnswer";
import { useSearchParams } from "react-router-dom";
import { getTodayAsUnixDate } from "../functions/getTodayAsUnixDate";

export default function Profile({
  authToken,
  type,
  setOpenSettings,
  companyData,
  setCompanyData,
  responseCompanyData,
  setResponseCompanyData,
  userData,
  tagData1,
  tagData2,
  tagData3,
  numberOfPosts,
  setNumberOfPosts,
  companyAnswers,
  pathFromSearch,
  setPathFromSearch,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  articleID,
  setArticleID,
  mobile,
  lightRoomData,
  setLightRoomData,
}) {
  const { id } = useParams();
  /* profileData */
  const [profileData, setProfileData] = useState([]);
  const [responseProfileData, setResponseProfileData] = useState("");

  const [profileImageURL, setProfileImageURL] = useState("");
  const [profileName, setProfileName] = useState("");

  const [profileAnswerCount, setProfileAnswerCount] = useState("");
  const [profileAnswers, setProfileAnswers] = useState("");

  // fetched answersDaten
  const [profileFetchedAnswers, setProfileFetchedAnswers] = useState([]);
  const [responseProfileFetchedAnswers, setResponseProfileFetchedAnswers] =
    useState("");

  // fetched tagDaten
  const [sortedExpertForTagData, setSortedExpertForTagData] = useState([]);
  const [profileTag1, setProfileTag1] = useState([]);
  const [responseProfileTag1, setResponseProfileTag1] = useState("");
  const [profileTag2, setProfileTag2] = useState([]);
  const [responseProfileTag2, setResponseProfileTag2] = useState("");
  const [profileTag3, setProfileTag3] = useState([]);
  const [responseProfileTag3, setResponseProfileTag3] = useState("");

  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(authToken) && isEmpty(id)) {
      navigate("/search");
    }

    if (!isEmpty(companyData) && !isEmpty(id)) {
      if (companyData[0].id == id) {
        navigate("/profile");
      }
    }
  }, [authToken, id, companyData]);

  const openContactsWithProfileID = () => {
    navigate("/profile/" + id + "/contact");
  };

  const handleClickToOpenSettings = () => {
    setOpenSettings(true);
  };

  /*
alle daten müssen auf companyData gemünzt sein, außer es gibt profileData
  */

  // useeffect der checkt ob id da ist, wenn nicht, alle companydaten in die states
  // wenn da ist, dann wird ein fetch ausgeführt, der die companydaten des anderen in die states packt

  //get companyData
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(id)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/companies/?id=" + id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileData(value));
    } else if (!isEmpty(id)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/companies/noAuthOnlyGET.php?id=" + id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileData(value));
    }
  }, [authToken, id]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseProfileData !== "") {
      setProfileData(JSON.parse(responseProfileData));
    }
  }, [responseProfileData, authToken]);

  useEffect(() => {
    if (!isEmpty(profileData) && !isEmpty(id) && type != "sidebar") {
      console.log("uebeltaeter");

      // alle states daraufsetzen
      setProfileName(profileData[0].companyname);
      setProfileImageURL(JSON.parse(profileData[0].profileImageURL)[0].url);
      setProfileAnswerCount(profileData[0].companyname);
      if (!isEmpty(profileFetchedAnswers)) {
        setProfileAnswers(profileFetchedAnswers);
      } else {
        setProfileAnswers([]);
      }
    } else if (!isEmpty(companyData)) {
      console.log("asdasd");

      // alle states auf company setzen
      setProfileName(companyData[0].companyname);
      setProfileImageURL(JSON.parse(companyData[0].profileImageURL)[0].url);
      setProfileTag1(tagData1);
      setProfileTag2(tagData2);
      setProfileTag3(tagData3);
      setProfileAnswers(companyAnswers);
    }
  }, [
    profileData,
    companyData,
    authToken,
    type,
    id,
    tagData1,
    tagData2,
    tagData3,
    profileFetchedAnswers,
  ]);

  // TAGDATA aus profile.expertForTags generieren und dann einzeln abfrage starten
  // todo: alle variablen: companydata = profiledata
  //
  useEffect(() => {
    if (!isEmpty(profileData) && isEmpty(sortedExpertForTagData)) {
      let helpArray = JSON.parse(profileData[0].expertForTags);
      let helpArray2 = [];
      let firstCount = 0;
      let firstElement;
      let secondCount = 0;
      let secondelement;
      let thirdCount = 0;
      let thirdElement;
      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > firstCount) {
            firstElement = helpArray[i];
            firstCount = helpArray[i].count;
          }
        }

        if (firstCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(firstElement);
          const index = helpArray.indexOf(firstElement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }

      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > secondCount) {
            secondelement = helpArray[i];
            secondCount = helpArray[i].count;
          }
        }

        if (secondCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(secondelement);
          const index = helpArray.indexOf(secondelement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }

      if (helpArray.length > 0) {
        for (let i = 0; i < helpArray.length; i++) {
          if (helpArray[i].count > thirdCount) {
            thirdElement = helpArray[i];
            thirdCount = helpArray[i].count;
          }
        }

        if (thirdCount == 0) {
          helpArray2.push(helpArray[0]);
          const index = helpArray.indexOf(helpArray[0]);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        } else {
          helpArray2.push(thirdElement);
          const index = helpArray.indexOf(thirdElement);
          if (index > -1) {
            // only splice array when item is found
            helpArray.splice(index, 1); // 2nd parameter means remove one item only
          }
        }
      }

      setSortedExpertForTagData(helpArray2);
    }
  }, [profileData, authToken]);

  //get tagData1
  useEffect(async () => {
    if (
      !isEmpty(authToken) &&
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 1 &&
      !isEmpty(id)
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/?id=" +
          sortedExpertForTagData[0].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileTag1(value));
    } else if (
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 1 &&
      !isEmpty(id)
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/noAuthOnlyGET.php?id=" +
          sortedExpertForTagData[0].id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileTag1(value));
    }
  }, [authToken, sortedExpertForTagData, id]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseProfileTag1 !== "" && type != "sidebar") {
      setProfileTag1(JSON.parse(responseProfileTag1));
    }
  }, [responseProfileTag1, authToken]);

  //get tagData2
  useEffect(async () => {
    if (
      !isEmpty(authToken) &&
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 2 &&
      !isEmpty(id)
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/?id=" +
          sortedExpertForTagData[1].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileTag2(value));
    } else if (
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 2 &&
      !isEmpty(id)
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/noAuthOnlyGET.php?id=" +
          sortedExpertForTagData[1].id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileTag2(value));
    }
  }, [authToken, sortedExpertForTagData, id]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseProfileTag2 !== "" && type != "sidebar") {
      setProfileTag2(JSON.parse(responseProfileTag2));
    }
  }, [responseProfileTag2, authToken]);

  //get tagData3
  useEffect(async () => {
    if (
      !isEmpty(authToken) &&
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 3 &&
      !isEmpty(id)
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/?id=" +
          sortedExpertForTagData[2].id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileTag3(value));
    } else if (
      !isEmpty(sortedExpertForTagData) &&
      sortedExpertForTagData.length >= 3 &&
      !isEmpty(id)
    ) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/tags/noAuthOnlyGET.php?id=" +
          sortedExpertForTagData[2].id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileTag3(value));
    }
  }, [authToken, sortedExpertForTagData, id]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseProfileTag3 !== "" && type != "sidebar") {
      setProfileTag3(JSON.parse(responseProfileTag3));
    }
  }, [responseProfileTag3, authToken]);

  //get companyAnswers as String
  useEffect(async () => {
    if (!isEmpty(authToken) && !isEmpty(id) && isEmpty(profileData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/answers/?companyID=" + id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileFetchedAnswers(value));
    } else if (!isEmpty(id) && isEmpty(profileData)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/answers/noAuthOnlyGET.php?companyID=" +
          id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseProfileFetchedAnswers(value));
    }
  }, [authToken, id, profileData]);

  // companyAnswers als JSON in state packen
  useEffect(() => {
    if (responseProfileFetchedAnswers !== "") {
      let unsorted = JSON.parse(responseProfileFetchedAnswers);
      var sorted = unsorted.sort(function (a, b) {
        return b.createdAt - a.createdAt;
      });
      setProfileFetchedAnswers(sorted);
    }
  }, [responseProfileFetchedAnswers, authToken]);

  const backToSearch = () => {
    setPathFromSearch(false);
    navigate("/search");
  };

  const shareOwnLinkOnWhatsApp = () => {
    let myLink = "www.exit925.de/profile/" + companyData[0].id;
    window.open(
      "whatsapp://send?text=Siehe dir mein Expertenprofil an und nutze es um Termine zu buchen: " +
        myLink
    );
  };

  const openLightRoom = () => {
    let imageArray;
    if (!isEmpty(profileData)) {
      setLightRoomData(JSON.parse(profileData[0].postImagesURL));
    } else if (!isEmpty(companyData)) {
      setLightRoomData(JSON.parse(companyData[0].postImagesURL));
    }
  };

  return (
    <div className="profile">
      <div className="profile__thumbnailBox">
        {pathFromSearch ? (
          <div onClick={() => backToSearch()}>
            <BackIcon />
          </div>
        ) : (
          <></>
        )}

        <div
          className="profile__thumbnailBox_imagebox"
          onClick={() => handleClickToOpenSettings()}
        >
          {type == "sidebar" ? (
            <>
              {!isEmpty(companyData) ? (
                <img
                  className="profile__thumbnailBox_imagebox_image"
                  src={JSON.parse(companyData[0].profileImageURL)[0].url}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <img
              className="profile__thumbnailBox_imagebox_image"
              src={profileImageURL}
            />
          )}
        </div>
        <div
          className="profile__thumbnailBox_nameText"
          onClick={() => handleClickToOpenSettings()}
        >
          {type == "sidebar" ? (
            <>
              {!isEmpty(companyData) ? (
                <div>{companyData[0].companyname}</div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div>{profileName}</div>
          )}
        </div>
        {!isEmpty(id) && type != "sidebar" ? (
          <>
            {!isEmpty(profileData) ? (
              <>
                {profileData[0].calenderActive == "true" ? (
                  <div
                    className="profile__thumbnailBox_contactButtonBox"
                    onClick={() => openContactsWithProfileID()}
                  >
                    <div className="profile__thumbnailBox_contactButtonBox_contactButton">
                      <div className="profile__thumbnailBox_contactButtonBox_contactButton_icon"></div>
                      <div className="profile__thumbnailBox_contactButtonBox_contactButton_text">
                        Termin buchen
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className="profile__thumbnailBox_shareButtonBox">
            <div className="profile__thumbnailBox_shareButtonBox_shareButton">
              <div className="profile__thumbnailBox_shareButtonBox_shareButton_icon">
                <ReplyIcon />
              </div>
              <div
                className="profile__thumbnailBox_shareButtonBox_shareButton_text"
                onClick={() => shareOwnLinkOnWhatsApp()}
              >
                Teilen auf WhatsApp
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="profile__imageCollection">
        {type == "sidebar" && !isEmpty(companyData) ? (
          <>
            {!isEmpty(JSON.parse(companyData[0].postImagesURL)) ? (
              <>
                {JSON.parse(companyData[0].postImagesURL).map((image) => (
                  <div
                    className="profile_imageCollection_imagebox"
                    onClick={() => openLightRoom()}
                  >
                    <img
                      className="profile__imageCollection_imagebox_image"
                      src={image}
                    />
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {isEmpty(id) && !isEmpty(companyData) ? (
              <>
                {JSON.parse(companyData[0].postImagesURL).map((image) => (
                  <div
                    className="profile_imageCollection_imagebox"
                    onClick={() => openLightRoom()}
                  >
                    <img
                      className="profile__imageCollection_imagebox_image"
                      src={image}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {!isEmpty(profileData) ? (
                  <>
                    {JSON.parse(profileData[0].postImagesURL).map((image) => (
                      <div
                        className="profile_imageCollection_imagebox"
                        onClick={() => openLightRoom()}
                      >
                        <img
                          className="profile__imageCollection_imagebox_image"
                          src={image}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className="profile__expertFor">
        <div className="profile__expertFor_text">Experte für</div>
        <div className="profile__expertFor_tags">
          {type == "sidebar" ? (
            <>
              {!isEmpty(companyData) ? (
                <>
                  {!isEmpty(tagData1) ? (
                    <>
                      <TagFetched
                        tagData={tagData1}
                        key={tagData1[0].id.toString()}
                        authToken={authToken}
                        companyData={companyData}
                      />
                    </>
                  ) : (
                    <div>...</div>
                  )}
                  {!isEmpty(tagData2) ? (
                    <>
                      <TagFetched
                        tagData={tagData2}
                        key={tagData2[0].id.toString()}
                        authToken={authToken}
                        companyData={companyData}
                      />
                    </>
                  ) : (
                    <div>...</div>
                  )}
                  {!isEmpty(tagData3) ? (
                    <>
                      <TagFetched
                        tagData={tagData3}
                        key={tagData3[0].id.toString()}
                        authToken={authToken}
                        companyData={companyData}
                      />
                    </>
                  ) : (
                    <div>...</div>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {!isEmpty(profileTag1) ? (
                <>
                  <TagFetched
                    tagData={profileTag1}
                    key={profileTag1[0].id.toString()}
                    authToken={authToken}
                    companyData={profileData}
                  />
                </>
              ) : (
                <div>...</div>
              )}
              {!isEmpty(profileTag2) ? (
                <>
                  <TagFetched
                    tagData={profileTag2}
                    key={profileTag2[0].id.toString()}
                    authToken={authToken}
                    companyData={profileData}
                  />
                </>
              ) : (
                <div>...</div>
              )}
              {!isEmpty(profileTag3) ? (
                <>
                  <TagFetched
                    tagData={profileTag3}
                    key={profileTag3[0].id.toString()}
                    authToken={authToken}
                    companyData={profileData}
                  />
                </>
              ) : (
                <div>...</div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="profile__userAnswers">
        <div className="profile__userAnswers_topBar">
          <div className="profile__userAnswers_topBar_text">
            {type == "sidebar" ? (
              <>
                {isEmpty(companyAnswers) ? (
                  <> 0 Fachbeiträge </>
                ) : (
                  <>
                    {companyAnswers.length == 1 ? (
                      <>{companyAnswers.length} Fachbeitrag</>
                    ) : (
                      <>{companyAnswers.length} Fachbeiträge</>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {isEmpty(profileAnswers) ? (
                  <> 0 Fachbeiträge </>
                ) : (
                  <>
                    {profileAnswers.length == 1 ? (
                      <>{profileAnswers.length} Fachbeitrag</>
                    ) : (
                      <>{profileAnswers.length} Fachbeiträge</>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="profile__userAnswers_topBar_sortButton">
            <div className="profile__userAnswers_topBar_sortButton_icon">
              {type == "profile" ? (
                <> {/*  <FilterIcon /> */}</>
              ) : (
                <div onClick={() => navigate("/profile")}>Mehr Anzeigen</div>
              )}
            </div>
          </div>
        </div>
        {type == "profile" ? ( // es ist /profile mit allen antworten
          <>
            {!isEmpty(id) ? (
              <>
                {!isEmpty(profileAnswers) ? (
                  profileAnswers.map((answer) => (
                    <PostSingleWithOwnAnswer
                      type={"ownAnswer"}
                      answerData={answer}
                      key={answer.id}
                      openArticle={openArticle}
                      setOpenArticle={setOpenArticle}
                      activeArticle={activeArticle}
                      setActiveArticle={setActiveArticle}
                      articleID={articleID}
                      setArticleID={setArticleID}
                      authToken={authToken}

                    />
                  ))
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {!isEmpty(companyAnswers) ? (
                  companyAnswers.map((answer) => (
                    <PostSingleWithOwnAnswer
                      type={"ownAnswer"}
                      answerData={answer}
                      key={answer.id}
                      openArticle={openArticle}
                      setOpenArticle={setOpenArticle}
                      activeArticle={activeArticle}
                      setActiveArticle={setActiveArticle}
                      articleID={articleID}
                      setArticleID={setArticleID}
                      authToken={authToken}
                    />
                  ))
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          // es ist an der sidebar mit nur der letzten antwort
          <>
            {!isEmpty(companyAnswers) ? (
              <PostSingleWithOwnAnswer
                type={"ownAnswer"}
                answerData={companyAnswers[0]}
                key={companyAnswers[0].id}
                openArticle={openArticle}
                setOpenArticle={setOpenArticle}
                activeArticle={activeArticle}
                setActiveArticle={setActiveArticle}
                articleID={articleID}
                setArticleID={setArticleID}
                authToken={authToken}

              />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      {mobile && isEmpty(authToken) ? (
        <div className="search__infoLinks">
          <div
            className="sidebar__footer_links"
            onClick={() => handleClickToOpenSettings()}
          >
            Impressum. Datenschutz.
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

const ReplyIcon = styled(MdReply)`
  font-size: 25px;
  color: #5e5e5e;
  margin-bottom: -1px;
`;

const FilterIcon = styled(MdFilterList)`
  font-size: 20px;
  color: #575757;
  margin-top: 5px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 30px;
  color: #000;
  padding-right: 10px;
  padding-top: 7px;
`;
