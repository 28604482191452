import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import "../styles/NotLoggedInRightSidebar.css";
import {
  MdAdd,
  MdFilterList,
  MdPeople,
  MdPeopleOutline,
  MdReply,
  MdSchool,
  MdStar,
  MdStarBorder,
} from "react-icons/md";
import CTA from "../components/buttons/CTA";

export default function NotLoggedInRightSidebar({
  setOpenLogin,
  setOpenRegister,
}) {
  const openLoginAndCloseRegister = () => {
    setOpenLogin(true);
    setOpenRegister(false);
  };
  const openRegisterAndCloseLogin = () => {
    setOpenLogin(false);
    setOpenRegister(true);
  };

  return (
    <div className="notLoggedInRightSidebar">
      <div className="notLoggedInRightSidebar__topBar">
        <div onClick={() => openRegisterAndCloseLogin()}>
          <CTA text="Registrieren" />
        </div>
        <div onClick={() => openLoginAndCloseRegister()}>
          <CTA text="Login" type="outlined" />
        </div>
      </div>
      <div className="notLoggedInRightSidebar__middleBar">
        <div className="notLoggedInRightSidebar__middleBar_topText">
          <div className="notLoggedInRightSidebar__middleBar_topText_headline">
            Dein Start in die Selbständigkeit!
          </div>
          <div className="notLoggedInRightSidebar__middleBar_topText_description">
            exit925 hilft dir deine Selbständigkeit zu entdecken und auszubauen
          </div>
        </div>
        <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints">
          <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint">
            <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint_icon">
              <SchoolIcon />
            </div>
            <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint_text">
              Lerne aus der Praxis
            </div>
          </div>
          <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint">
            <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint_icon">
              <StarIcon />
            </div>
            <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint_text">
              Positioniere dich als Experte
            </div>
          </div>
          <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint">
            <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint_icon">
              <People />
            </div>
            <div className="notLoggedInRightSidebar__middleBar_bottomMarketingPoints_MarketingPoint_text">
              Erhalte Kunden
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SchoolIcon = styled(MdSchool)`
  font-size: 36px;
  color: #5e5e5e;
  margin-bottom: -1px;
`;

const StarIcon = styled(MdStar)`
  font-size: 36px;
  color: #5e5e5e;
  margin-bottom: -1px;
`;

const People = styled(MdPeople)`
  font-size: 36px;
  color: #5e5e5e;
  margin-bottom: -1px;
`;
