import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../styles/Sidebar.css";
import ExpertsSidebar from "../components/sidebar/ExpertsSidebar";
import { useNavigate } from "react-router";
import CTA from "../components/buttons/CTA";

export default function Sidebar({
  authToken,
  setCreateNewQuestion,
  setOpenLogin,
  setOpenRegister,
  setOpenSettings,
  setOpenArticle,
  refresh,
  setRefresh,
  setOpenCalenderConfig,
  setInfoData,
  activePage,
  setActivePage,
  mobile,
  setMobile,
}) {
  const pathname = window.location.pathname;
  useEffect(() => {
    let helpArray = pathname.split("/");
    let helpString = helpArray[1];
    setActivePage(helpString);
  }, [pathname]);

  useEffect(() => {
    if (isEmpty(authToken)) {
      document.getElementById("sidebarNavLinkProfile").style.display = "none";
      document.getElementById("sidebarNavLinkQuestions").style.display = "none";
      document.getElementById("sidebarNavLinkContacts").style.display = "none";
      document.getElementById("sidebarRegister").style.display = "flex";
      document.getElementById("sidebarLogin").style.display = "flex";
    } else {
      document.getElementById("sidebarNavLinkProfile").style.display = "flex";
      document.getElementById("sidebarNavLinkQuestions").style.display = "flex";
      document.getElementById("sidebarNavLinkContacts").style.display = "flex";
      document.getElementById("sidebarRegister").style.display = "none";
      document.getElementById("sidebarLogin").style.display = "none";
    }
  }, [authToken]);

  const closeCreateNewQuestionView = () => {
    setCreateNewQuestion(false);
    setOpenLogin(false);
    setOpenRegister(false);
    setOpenSettings(false);
    setOpenArticle(false);
    setOpenCalenderConfig(false);
    setRefresh(!refresh);
    setInfoData([]);
  };

  const openLoginOnClick = () => {
    setOpenRegister(false);
    setOpenLogin(true);
  };

  const openRegisterOnClick = () => {
    setOpenLogin(false);
    setOpenRegister(true);
  };

  let navigate = useNavigate();
  const routeChange = (locationURL) => {
    closeCreateNewQuestionView(false);
    navigate(locationURL);
  };

  return (
    <div className="sidebar">
      <div className="sidebar__top">
        <div className="sidebar__top_logo" id="logoLong">
          exit925
        </div>
        <div className="sidebar__top_logo" id="logoShort">
          exit
        </div>
      </div>
      <div className="sidebar__middle">
        <div className="sidebar__middle_navigationLinks">
          <div
            className="sidebar__middle_navigationLinks_link"
            onClick={() => routeChange(`/`)}
            id="sidebarNavLinkQuestions"
          >
            {activePage == "" ? (
              <>
                <div className="sidebar__middle_navigationLinks_link_icon">
                  <QuestionAnswerIconActive />
                </div>
                <div className="sidebar__middle_navigationLinks_link_text">
                  <div className="semibold">Fragen & Antworten</div>
                </div>
              </>
            ) : (
              <>
                <div className="sidebar__middle_navigationLinks_link_icon">
                  <QuestionAnswerIconInactive />
                </div>
                <div className="sidebar__middle_navigationLinks_link_text">
                  Fragen & Antworten
                </div>
              </>
            )}
          </div>
          <div
            className="sidebar__middle_navigationLinks_link"
            onClick={() => routeChange(`/search`)}
          >
            {activePage == "search" ? (
              <>
                <div className="sidebar__middle_navigationLinks_link_icon">
                  <SearchIconActive />
                </div>
                <div className="sidebar__middle_navigationLinks_link_text">
                  <div className="semibold">Suche</div>
                </div>
              </>
            ) : (
              <>
                <div className="sidebar__middle_navigationLinks_link_icon">
                  <SearchIconInactive />
                </div>
                <div className="sidebar__middle_navigationLinks_link_text">
                  Suche{" "}
                </div>
              </>
            )}
          </div>

          <div
            className="sidebar__middle_navigationLinks_link"
            onClick={() => routeChange(`/contacts`)}
            id="sidebarNavLinkContacts"
          >
            {activePage == "contacts" ? (
              <>
                <div className="sidebar__middle_navigationLinks_link_icon">
                  <ContactsIconActive />
                </div>
                <div className="sidebar__middle_navigationLinks_link_text">
                  <div className="semibold">Termine</div>
                </div>
              </>
            ) : (
              <>
                <div className="sidebar__middle_navigationLinks_link_icon">
                  <ContactsIconInactive />
                </div>
                <div className="sidebar__middle_navigationLinks_link_text">
                  Termine
                </div>
              </>
            )}
          </div>

          <div
            className="sidebar__middle_navigationLinks_link"
            onClick={() => routeChange(`/profile`)}
            id="sidebarNavLinkProfile"
          >
            {activePage == "profile" ? (
              <>
                <div className="sidebar__middle_navigationLinks_link_icon">
                  <ReputationIconActive />
                </div>
                <div className="sidebar__middle_navigationLinks_link_text">
                  <div className="semibold">Reputation</div>
                </div>
              </>
            ) : (
              <>
                <div className="sidebar__middle_navigationLinks_link_icon">
                  <ReputationIconInactive />
                </div>
                <div className="sidebar__middle_navigationLinks_link_text">
                  Reputation{" "}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* V1.1: Braucht noch n algorithmus der die ähnlichen aus DB lädt 
  
        <div className="sidebar__bottom">
        <div className="sidebar__bottom_similarExperts">
          <div className="sidebar__bottom_similarExperts_titleText">
            Dir ähnliche Experten
          </div>
          {<ExpertsSidebar />}
        </div>
      </div>
  
  */}
      <div className="sidebar__logins">
        <div
          className="sidebar__logins_register"
          id={"sidebarRegister"}
          onClick={() => openRegisterOnClick()}
        >
          <CTA size={"small"} text="Registrieren" />
        </div>
        <div
          className="sidebar__logins_login"
          id={"sidebarLogin"}
          onClick={() => openLoginOnClick()}
        >
          <CTA size={"small"} type="outlined" text="Login" />
        </div>
      </div>
      <div className="sidebar__footer" onClick={() => setOpenSettings(true)}>
        <div className="sidebar__footer_links">Impressum. Datenschutz.</div>
      </div>
    </div>
  );
}
const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;

const QuestionAnswerIconActive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #000000;
`;

const SearchIconInactive = styled(MdSearch)`
  font-size: 25px;
  color: #575757;
`;

const SearchIconActive = styled(MdSearch)`
  font-size: 25px;
  color: #000000;
`;

const ContactsIconInactive = styled(MdPeopleOutline)`
  font-size: 25px;
  color: #575757;
`;

const ContactsIconActive = styled(MdPeople)`
  font-size: 25px;
  color: #000000;
`;

const MoreIconInactive = styled(MdMore)`
  font-size: 25px;
  color: #575757;
`;

const MoreIconActive = styled(MdMore)`
  font-size: 25px;
  color: #000000;
`;

const ReputationIconInactive = styled(MdStarBorder)`
  font-size: 25px;
  color: #575757;
`;

const ReputationIconActive = styled(MdStar)`
  font-size: 25px;
  color: #000000;
`;
