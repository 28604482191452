import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../../styles/Tag.css";
import { isEmpty } from "../../functions/isEmpty";

export default function TagFetched({
  text,
  tagData,
  interestedTags,
  setInterestedTags,
  authToken,
}) {
  const [active, setActive] = useState(false);

  if (active == true) {
    return (
      <div className="tag__active">
        {!isEmpty(tagData) ? <div>{tagData[0].title}</div> : <div></div>}
      </div>
    );
  }

  return (
    <div className="tag">
      {!isEmpty(tagData) ? <div>{tagData[0].title}</div> : <div></div>}
    </div>
  );
}
const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;
