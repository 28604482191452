export default function getTextTrimmed(text) {
  let moreString = "";
  let newText;
  if (text.length > 139) {
    moreString = " ...";
    newText = text.slice(0, 140) + moreString;
    return newText;
  } else {
    return text;
  }
}
