import React from "react";
import { useState } from "react";
import "./Calendar.css";
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdChevronLeft,
  MdChevronRight,
  MdFilePresent,
} from "react-icons/md";
import styled from "styled-components/macro";
import { useEffect } from "react";
import { transformTimeToMinutes } from "../functions/transformTimeToMinutes";
import { getWeekDay } from "../functions/getWeekday";
import { isEmpty } from "../functions/isEmpty";
import getReadableTimeFromQuarterTimeFormat from "../functions/getReadableTimeFromQuarterTimeFormat";

export default function Calendar({
  thisMonth,
  setThisMonth,
  thisYear,
  setThisYear,
  thisDay,
  setThisDay,
  thisTime,
  setThisTime,
  actualMonth,
  actualYear,
  actualDay,
  profileData,
  thisService,
  appointments,
  chosenDay,
  chosenMonth,
  chosenYear,
  setChosenDay,
  setChosenMonth,
  setChosenYear,
}) {
  let Oeffnungszeiten;

  if (!isEmpty(profileData)) {
    Oeffnungszeiten = JSON.parse(
      JSON.stringify(JSON.parse(profileData[0].openWeekdays))
    );
  } else {
    Oeffnungszeiten = [
      {
        id: "0",
        day: "Montag",
        startInMinutes: "480",
        endInMinutes: "1080",
        active: "true",
      },
      {
        id: "1",
        day: "Dienstag",
        startInMinutes: "480",
        endInMinutes: "1080",
        active: "true",
      },
      {
        id: "2",
        day: "Mittwoch",
        startInMinutes: "600",
        endInMinutes: "1080",
        active: "true",
      },
      {
        id: "3",
        day: "Donnerstag",
        startInMinutes: "480",
        endInMinutes: "1080",
        active: "true",
      },
      {
        id: "4",
        day: "Freitag",
        startInMinutes: "480",
        endInMinutes: "1080",
        active: "true",
      },
      {
        id: "5",
        day: "Samstag",
        startInMinutes: "480",
        endInMinutes: "1080",
        active: "false",
      },
      {
        id: "6",
        day: "Sonntag",
        startInMinutes: "600",
        endInMinutes: "1080",
        active: "false",
      },
    ];
  }

  const months = [
    { id: "12021", name: "Januar", days: 31, firstWeekday: 5 },
    { id: "22021", name: "Februar", days: 28, firstWeekday: 1 },
    { id: "32021", name: "März", days: 31, firstWeekday: 1 },
    { id: "42021", name: "April", days: 30, firstWeekday: 4 },
    { id: "52021", name: "Mai", days: 31, firstWeekday: 6 },
    { id: "62021", name: "Juni", days: 30, firstWeekday: 2 },
    { id: "72021", name: "Juli", days: 31, firstWeekday: 4 },
    { id: "82021", name: "August", days: 30, firstWeekday: 7 },
    { id: "92021", name: "September", days: 30, firstWeekday: 3 },
    { id: "102021", name: "Oktober", days: 31, firstWeekday: 5 },
    { id: "112021", name: "November", days: 30, firstWeekday: 1 },
    { id: "122021", name: "Dezember", days: 31, firstWeekday: 3 },

    { id: "12022", name: "Januar", days: 31, firstWeekday: 6 },
    { id: "22022", name: "Februar", days: 28, firstWeekday: 2 },
    { id: "32022", name: "März", days: 31, firstWeekday: 2 },
    { id: "42022", name: "April", days: 30, firstWeekday: 5 },
    { id: "52022", name: "Mai", days: 31, firstWeekday: 7 },
    { id: "62022", name: "Juni", days: 30, firstWeekday: 3 },
    { id: "72022", name: "Juli", days: 31, firstWeekday: 5 },
    { id: "82022", name: "August", days: 31, firstWeekday: 1 },
    { id: "92022", name: "September", days: 30, firstWeekday: 4 },
    { id: "102022", name: "Oktober", days: 31, firstWeekday: 6 },
    { id: "112022", name: "November", days: 30, firstWeekday: 2 },
    { id: "122022", name: "Dezember", days: 31, firstWeekday: 4 },

    { id: "12023", name: "Januar", days: 31, firstWeekday: 7 },
    { id: "22023", name: "Februar", days: 28, firstWeekday: 3 },
    { id: "32023", name: "März", days: 31, firstWeekday: 3 },
    { id: "42023", name: "April", days: 30, firstWeekday: 6 },
    { id: "52023", name: "Mai", days: 31, firstWeekday: 1 },
    { id: "62023", name: "Juni", days: 30, firstWeekday: 4 },
    { id: "72023", name: "Juli", days: 31, firstWeekday: 6 },
    { id: "82023", name: "August", days: 31, firstWeekday: 2 },
    { id: "92023", name: "September", days: 30, firstWeekday: 5 },
    { id: "102023", name: "Oktober", days: 31, firstWeekday: 7 },
    { id: "112023", name: "November", days: 30, firstWeekday: 3 },
    { id: "122023", name: "Dezember", days: 31, firstWeekday: 5 },

    { id: "12024", name: "Januar", days: 31, firstWeekday: 1 },
    { id: "22024", name: "Februar", days: 29, firstWeekday: 4 },
    { id: "32024", name: "März", days: 31, firstWeekday: 5 },
    { id: "42024", name: "April", days: 30, firstWeekday: 1 },
    { id: "52024", name: "Mai", days: 31, firstWeekday: 3 },
    { id: "62024", name: "Juni", days: 30, firstWeekday: 6 },
    { id: "72024", name: "Juli", days: 31, firstWeekday: 1 },
    { id: "82024", name: "August", days: 31, firstWeekday: 4 },
    { id: "92024", name: "September", days: 30, firstWeekday: 7 },
    { id: "102024", name: "Oktober", days: 31, firstWeekday: 2 },
    { id: "112024", name: "November", days: 30, firstWeekday: 5 },
    { id: "122024", name: "Dezember", days: 31, firstWeekday: 7 },

    { id: "12025", name: "Januar", days: 31, firstWeekday: 3 },
    { id: "22025", name: "Februar", days: 28, firstWeekday: 6 },
    { id: "32025", name: "März", days: 31, firstWeekday: 6 },
    { id: "42025", name: "April", days: 30, firstWeekday: 2 },
    { id: "52025", name: "Mai", days: 31, firstWeekday: 4 },
    { id: "62025", name: "Juni", days: 30, firstWeekday: 7 },
    { id: "72025", name: "Juli", days: 31, firstWeekday: 2 },
    { id: "82025", name: "August", days: 31, firstWeekday: 5 },
    { id: "92025", name: "September", days: 30, firstWeekday: 1 },
    { id: "102025", name: "Oktober", days: 31, firstWeekday: 3 },
    { id: "112025", name: "November", days: 30, firstWeekday: 6 },
    { id: "122025", name: "Dezember", days: 31, firstWeekday: 1 },

    { id: "12026", name: "Januar", days: 31, firstWeekday: 4 },
    { id: "22026", name: "Februar", days: 28, firstWeekday: 7 },
    { id: "32026", name: "März", days: 31, firstWeekday: 7 },
    { id: "42026", name: "April", days: 30, firstWeekday: 3 },
    { id: "52026", name: "Mai", days: 31, firstWeekday: 5 },
    { id: "62026", name: "Juni", days: 30, firstWeekday: 1 },
    { id: "72026", name: "Juli", days: 31, firstWeekday: 3 },
    { id: "82026", name: "August", days: 31, firstWeekday: 6 },
    { id: "92026", name: "September", days: 30, firstWeekday: 2 },
    { id: "102026", name: "Oktober", days: 31, firstWeekday: 4 },
    { id: "112026", name: "November", days: 30, firstWeekday: 7 },
    { id: "122026", name: "Dezember", days: 31, firstWeekday: 2 },

    { id: "12027", name: "Januar", days: 31, firstWeekday: 5 },
    { id: "22027", name: "Februar", days: 28, firstWeekday: 1 },
    { id: "32027", name: "März", days: 31, firstWeekday: 1 },
    { id: "42027", name: "April", days: 30, firstWeekday: 4 },
    { id: "52027", name: "Mai", days: 31, firstWeekday: 6 },
    { id: "62027", name: "Juni", days: 30, firstWeekday: 2 },
    { id: "72027", name: "Juli", days: 31, firstWeekday: 4 },
    { id: "82027", name: "August", days: 31, firstWeekday: 7 },
    { id: "92027", name: "September", days: 30, firstWeekday: 3 },
    { id: "102027", name: "Oktober", days: 31, firstWeekday: 5 },
    { id: "112027", name: "November", days: 30, firstWeekday: 1 },
    { id: "122027", name: "Dezember", days: 31, firstWeekday: 3 },

    { id: "12028", name: "Januar", days: 31, firstWeekday: 6 },
    { id: "22028", name: "Februar", days: 29, firstWeekday: 2 },
    { id: "32028", name: "März", days: 31, firstWeekday: 3 },
    { id: "42028", name: "April", days: 30, firstWeekday: 6 },
    { id: "52028", name: "Mai", days: 31, firstWeekday: 1 },
    { id: "62028", name: "Juni", days: 30, firstWeekday: 4 },
    { id: "72028", name: "Juli", days: 31, firstWeekday: 6 },
    { id: "82028", name: "August", days: 31, firstWeekday: 2 },
    { id: "92028", name: "September", days: 30, firstWeekday: 5 },
    { id: "102028", name: "Oktober", days: 31, firstWeekday: 7 },
    { id: "112028", name: "November", days: 30, firstWeekday: 3 },
    { id: "122028", name: "Dezember", days: 31, firstWeekday: 5 },
  ];

  const changethisMonthPlusOne = () => {
    if (thisMonth == 12) {
      setThisYear(thisYear + 1);
      setThisMonth(1);
    } else {
      setThisMonth(thisMonth + 1);
    }
  };

  const changethisMonthMinusOne = () => {
    if (thisMonth == 1) {
      setThisYear(thisYear - 1);
      setThisMonth(12);
    } else {
      setThisMonth(thisMonth - 1);
    }
  };

  function showWeekdays() {
    return (
      <div className="calendar__row">
        <label className="calendar__row_weekdays">Mo</label>
        <label className="calendar__row_weekdays">Di</label>
        <label className="calendar__row_weekdays">Mi</label>
        <label className="calendar__row_weekdays">Do</label>
        <label className="calendar__row_weekdays">Fr</label>
        <label className="calendar__row_weekdays">Sa</label>
        <label className="calendar__row_weekdays">So</label>
      </div>
    );
  }

  // finden des richtigen monats aus monthsArray
  function getActualMonthFromMonthsArray() {
    let thisMonthString = thisMonth.toString();
    let thisYearString = thisYear.toString();
    let activeMonth;
    months.map((month) => {
      if (thisMonthString + thisYearString == month.id) {
        activeMonth = month;
      }
    });
    return activeMonth;
  }

  function showDaysOfActiveMonth() {
    let activeMonthArray = getActualMonthFromMonthsArray();
    let days = [];
    let closedWeeklyDays = []; // 1,2,3,4,5,6,7 aus Oeffnungszeiten
    let allClosedDays = [];

    const chooseThisDay = (i) => {
      setChosenYear(thisYear);
      setChosenMonth(thisMonth);
      setChosenDay(i);
    };

    for (let j = 0; j < Oeffnungszeiten.length; j++) {
      if (Oeffnungszeiten[j].active == "false") {
        closedWeeklyDays.push(parseInt(Oeffnungszeiten[j].id) + 1);
      }
    }
    for (
      let i = 1;
      i <= activeMonthArray.days + activeMonthArray.firstWeekday;
      i++
    ) {
      closedWeeklyDays.map((closedWeeklyDay) => {
        if (i % 7 === closedWeeklyDay) {
          if (i >= activeMonthArray.firstWeekday)
            allClosedDays.push(i - (activeMonthArray.firstWeekday - 1));
        }
        if (closedWeeklyDay === 7) {
          if (i % 7 === 0) {
            allClosedDays.push(i - (activeMonthArray.firstWeekday - 1));
          }
        }
      });
    }

    // leere tage einfügen damit die erste reihe
    // mit dem ersten wochentag des monats beginnt
    for (let k = 0; k < activeMonthArray.firstWeekday - 1; k++) {
      days.push(
        <div
          className="calendar__row_days_empty"
          key={"calendarEmptyday" + k}
        ></div>
      );
    }

    // alle weiteren tage einfügen
    for (let i = 1; i < activeMonthArray.days + 1; i++) {
      // die verganenen disablen
      if (
        (i < actualDay && thisMonth <= actualMonth && thisYear <= actualYear) ||
        (thisMonth < actualMonth && thisYear <= actualYear) ||
        (thisMonth < actualMonth && thisYear < actualYear) ||
        thisYear < actualYear
      ) {
        days.push(
          <div className="calendar__row_days_disabled" key={"calendarDay" + i}>
            {i}
          </div>
        );
      } // die geschlossenen tage disablen
      else if (allClosedDays.includes(i)) {
        days.push(
          <div className="calendar__row_days_disabled" key={"calendarDay" + i}>
            {i}
          </div>
        );
      }
      // den tag aktiven blau markieren
      else if (
        i == chosenDay &&
        thisMonth == chosenMonth &&
        chosenYear == thisYear
      ) {
        days.push(
          <div className="calendar__row_days_active" key={"calendarDay" + i}>
            {i}
          </div>
        );
      } // alle anderen tagen einfüge n
      else {
        days.push(
          <div
            className="calendar__row_days"
            key={"calendarDay" + i}
            onClick={() => chooseThisDay(i)}
          >
            {i}
          </div>
        );
      }
    }

    // todo: alle tage wo keine öffnungszeiten sind disablen

    return <div className="calendar__row">{days.map((day) => day)}</div>;
  }

  const showTimesOfActiveDay = () => {
    let duration;
    if (!isEmpty(thisService)) {
      let timeHours = parseInt(thisService.durationHours) * 60;
      let timeMinutes = parseInt(thisService.durationMinutes);
      duration = timeHours + timeMinutes;
    } else {
      duration = 9999;
    }

    let bookedAppointments = []; // aus allen appointments an dem tag
    if (!isEmpty(appointments)) {
      for (let i = 0; i < appointments.length; i++) {
        // checken ob der termin auch an diesem tag ist
        if (
          JSON.parse(appointments[i].appointmentDay).thisDay == chosenDay &&
          JSON.parse(appointments[i].appointmentDay).thisMonth == chosenMonth &&
          JSON.parse(appointments[i].appointmentDay).thisYear == chosenYear
        ) {
          JSON.parse(appointments[i].appointmentTimes).map((time) =>
            bookedAppointments.push(time)
          );
        }
      }
    }

    let allOpenTimes = []; // leer. hier kommen alle freien termine gleich rein

    // vom beginn der öffnungszeiten bis zum ende der öffnungszeiten
    // jedes mal ein opentimes objekt erstellen das die dauer der ausgewählten
    // leistung hat
    Oeffnungszeiten.map((wochenTag) => {
      // aus fetched wochendaten in oeffnungszeitenArray gucken wieviel
      // uhr die termine beginnen und enden können.
      // dann innerhalb dieses zeitraumes alle x-minuten einen termin erstellen
      // abhängig von der dauer duration der ausgewählten leistung
      if (
        (parseInt(wochenTag.id) + 7 - 6) % 7 ==
        getWeekDay(chosenDay, chosenMonth, chosenYear)
      ) {
        for (
          let i = 0;
          i <
          (parseInt(wochenTag.endInMinutes) - wochenTag.startInMinutes) /
            duration;
          i++
        ) {
          // alle bereits gebuchten terminzeiten aus bookedappointments filtern
          let isBlocked = false;
          for (let k = 0; k < bookedAppointments.length; k++) {
            if (
              bookedAppointments[k] ==
              i * duration + parseInt(wochenTag.startInMinutes)
            ) {
              isBlocked = true;
            }
          }
          if (!isBlocked && !isEmpty(thisService)) {
            if (thisTime == i * duration + parseInt(wochenTag.startInMinutes)) {
              allOpenTimes.push(
                <div
                  className="calendar__bottom_timebox_time_active"
                  key={"timebox" + i}
                >
                  {getReadableTimeFromQuarterTimeFormat(
                    i * duration + parseInt(wochenTag.startInMinutes)
                  )}
                </div>
              );
            } else {
              allOpenTimes.push(
                <div
                  className="calendar__bottom_timebox_time"
                  key={"timebox" + i}
                  onClick={() =>
                    setThisTime(
                      i * duration + parseInt(wochenTag.startInMinutes)
                    )
                  }
                >
                  {getReadableTimeFromQuarterTimeFormat(
                    i * duration + parseInt(wochenTag.startInMinutes)
                  )}
                </div>
              );
            }
          }
        }
      }
    });

    return (
      <div className="calendar__bottom_timebox">
        {allOpenTimes.map((tag) => {
          return <>{tag}</>;
        })}
      </div>
    );
  };

  return (
    <>
      <div className="calendar">
        <div className="calendar__top">
          <div className="calendar__top_dateString">
            {getActualMonthFromMonthsArray().name + " " + thisYear}
          </div>
          <div className="calendar__top_changeMonthButtons">
            <div
              className="calendar__top_changeMonthButtons_button"
              onClick={() => changethisMonthMinusOne()}
            >
              <BackIcon />
            </div>
            <div
              className="calendar__top_changeMonthButtons_button"
              onClick={() => changethisMonthPlusOne()}
            >
              <ForwardIcon />
            </div>
          </div>
        </div>

        <div className="calendar__row">{showWeekdays()}</div>
        <div className="calendar__row">{showDaysOfActiveMonth()}</div>
      </div>
      <div className="calendar__bottom">{showTimesOfActiveDay()}</div>
    </>
  );
}

const BackIcon = styled(MdArrowBackIosNew)`
  font-size: 26px;
  color: #000;
`;

const ForwardIcon = styled(MdArrowForwardIos)`
  font-size: 26px;
  color: #000;
`;

// onclick auf den tag muss der weekday berechnet werden
// 1 = mo, 2= di
//
