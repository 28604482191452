import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdCalendarToday, MdFilterList } from "react-icons/md";

import "../styles/Contacts.css";
import ContactPreview from "../components/contacts/ContactPreview";
import { useNavigate } from "react-router";
import AppointmentPreview from "../components/contacts/AppointmentPreview";

export default function Appointments({
  authToken,
  setCreateNewQuestion,
  feedData,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
  openCalenderConfig,
  setOpenCalenderConfig,
  ownerAppointmentsData,
  setOwnerAppointmentsData,
  bookerAppointmentsData,
  setBookerAppointmentsData,
  activeAppointment,
  setActiveAppointment,
}) {
  const [allAppointments, setAppointments] = useState([]);

  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/search");
    }
  }, [authToken]);

  const openCreateNewQuestionView = () => {
    setCreateNewQuestion(true);
  };

  useEffect(() => {
    let helpArray = [];
    ownerAppointmentsData.forEach((element) => {
      helpArray.push(element);
    });
    bookerAppointmentsData.forEach((element) => {
      helpArray.push(element);
    });
    helpArray.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.

      return (
        new Date(
          JSON.parse(a.appointmentDay).thisYear,
          JSON.parse(a.appointmentDay).thisMonth - 1,
          JSON.parse(a.appointmentDay).thisDay,
          parseInt(Math.floor(JSON.parse(a.appointmentTimes)[0] / 60)),
          parseInt(JSON.parse(a.appointmentTimes)[0] % 60)
        ) -
        new Date(
          JSON.parse(b.appointmentDay).thisYear,
          JSON.parse(b.appointmentDay).thisMonth - 1,
          JSON.parse(b.appointmentDay).thisDay,
          parseInt(Math.floor(JSON.parse(b.appointmentTimes)[0] / 60)),
          parseInt(JSON.parse(b.appointmentTimes)[0] % 60)
        )
      );
    });

    setAppointments(helpArray);
  }, [ownerAppointmentsData, bookerAppointmentsData]);

  return (
    <div className="contacts">
      <div className="contacts__fixedTopBar">
        <div className="contacts__fixedTopBar_leftColumn">
          <div className="contacts__fixedTopBar_leftColumn_topText">
            Termine
          </div>
        </div>
        <div className="contacts__fixedTopBar_middleColumn"></div>

        <div
          className="contacts__fixedTopBar_rightColumn"
          onClick={() => setOpenCalenderConfig(true)}
        >
          {!isEmpty(companyData) ? (
            <>
              {companyData[0].calenderActive == "true" ? (
                <div className="contacts__fixedTopBar_rightColumn_sortButton">
                  <div className="contacts__fixedTopBar_rightColumn_sortButton_icon">
                    <CalenderIcon />
                  </div>
                  <div className="contacts__fixedTopBar_rightColumn_sortButton_text">
                    Kalendereinstellungen
                  </div>
                </div>
              ) : (
                <div className="contacts__fixedTopBar_rightColumn_sortButton">
                  <div className="contacts__fixedTopBar_rightColumn_sortButton_icon">
                    <CalenderIcon />
                  </div>
                  <div className="contacts__fixedTopBar_rightColumn_sortButton_text">
                    <pre>Termine anbieten</pre>
                  </div>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="contacts__scrollContent">
        {isEmpty(allAppointments) ? (
          <div className="contacts__scrollContent_infoText">
            Du hast bisher keine Termine gebucht und keine Termine wurden bisher
            bei dir gebucht.
          </div>
        ) : (
          <>
            {allAppointments.map((appointment) => {
              return (
                <AppointmentPreview
                  authToken={authToken}
                  key={"post"}
                  type="question"
                  userData={userData}
                  companyData={companyData}
                  appointmentData={appointment}
                  activeAppointment={activeAppointment}
                  setActiveAppointment={setActiveAppointment}
                  openCalenderConfig={openCalenderConfig}
                  setOpenCalenderConfig={setOpenCalenderConfig}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 20px;
  color: #575757;
  margin-top: 4px;
`;

const CalenderIcon = styled(MdCalendarToday)`
  font-size: 20px;
  color: #575757;
  margin-top: -3px;
`;
