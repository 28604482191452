import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdArrowBack, MdFilterList, MdSearch } from "react-icons/md";

import "../styles/Settings.css";
import { useNavigate, useNavigationType } from "react-router";

export default function Settings({
  authToken,
  logout,
  setOpenSettings,
  openInfoPage,
  setOpenInfoPage,
}) {
  let navigate = useNavigate();
  /*
  useEffect(() => {
    if (isEmpty(authToken)) {
      setOpenSettings(false);
      navigate("/search");
    }
  }, [authToken]);
  */
  return (
    <div className="register">
      <div className="register__fixedTopBar">
        <div onClick={() => setOpenSettings(false)}>
          <BackIcon />
        </div>
        <div className="register__fixedTopBar_text">Einstellungen</div>
      </div>
      <div className="register__scrollContent">
        {!isEmpty(authToken) ? (
          <>
            {" "}
            <div className="register__scrollContent_nameBox" onClick={logout}>
              <div className="register__scrollContent_nameBox_nameText">
                Abmelden
              </div>
            </div>
            <div
              className="register__scrollContent_nameBox"
              onClick={() => setOpenInfoPage("changeProfile")}
            >
              <div className="register__scrollContent_nameBox_nameText">
                Profildaten ändern
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          className="register__scrollContent_nameBox"
          onClick={() => setOpenInfoPage("privacy")}
        >
          <div className="register__scrollContent_nameBox_nameText">
            Datenschutz
          </div>
        </div>
        <div
          className="register__scrollContent_nameBox"
          onClick={() => setOpenInfoPage("imprint")}
        >
          <div className="register__scrollContent_nameBox_nameText">
            Impressum
          </div>
        </div>
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 20px;
  color: #000000;
  margin-top: -1px;
  margin-left: 3px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 30px;
  color: #000;
  padding-right: 10px;
  padding-top: 7px;
`;
