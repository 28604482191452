import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import {
  MdAdd,
  MdArrowBack,
  MdCancel,
  MdFilterList,
  MdOutlineImage,
  MdSearch,
} from "react-icons/md";

import "../styles/Settings.css";
import "../styles/Info.css";
import { useNavigate, useNavigationType } from "react-router";
import Imprint from "../texts/Imprint";
import Privacy from "../texts/Privacy";

export default function Info({
  authToken,
  logout,
  setOpenSettings,
  openInfoPage,
  setOpenInfoPage,
  companyData,
  refresh,
  setRefresh,
  type,
}) {
  let navigate = useNavigate();
  const [imageUploads, setImageUploads] = useState([]);
  const [profileName, setProfileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (!isEmpty(companyData)) {
      setProfileName(companyData[0].companyname);
    }
  }, [companyData]);

  useEffect(() => {
    if (type != "termin" && openInfoPage == "changeProfile") {
      if (isEmpty(imageUploads)) {
        document.getElementById("imgInpLabel").style.display = "flex";
        document.getElementById("imagePreview").style.display = "none";
      } else {
        document.getElementById("imgInpLabel").style.display = "none";
        document.getElementById("imagePreview").style.display = "flex";
      }
    }
  }, [imageUploads, type]);

  const uploadProfileData = async () => {
    const files = document.getElementById("imgInpProfileImage").files;
    const formData = new FormData();
    setIsUploading(true);

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0) {
      const res = await fetch(
        "https://www.cloud.leefes.com/api/images/process.php",
        {
          headers: {
            Authorization: `Bearer ${authToken?.JWT}`,
          },
          method: "POST",
          body: formData,
        }
      );

      await res.text().then((value) => handleuploadJSON(value));
    } else {
      handleuploadJSON("");
    }
  };

  const handleuploadJSON = async (imgURL) => {
    let companyIMG = JSON.stringify([
      JSON.parse(companyData[0].profileImageURL)[0],
    ]);

    let uploadImgURL;

    if (imgURL == "") {
      uploadImgURL = companyData[0].profileImageURL;
    } else {
      uploadImgURL = JSON.stringify([{ url: imgURL }]);
    }
    const resa = await fetch(
      "https://www.cloud.leefes.com/api/companies/changeProfileData.php?id=" +
        companyData[0].id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          profileImageURL: uploadImgURL,
          companyname: profileName,
        }),
      }
    );

    await resa.text().then(() => uploadEnds(imgURL));
  };

  const uploadEnds = (imgURL) => {
    setRefresh(!refresh);
    setOpenInfoPage("");
    setOpenSettings(false);
  };

  const insertLocalImage = (e) => {
    let file = e.target.files[0];
    console.log(file);
    setImageUploads({
      localURL: URL.createObjectURL(file),
      files: file,
    });
  };

  const infoData = () => {
    if (openInfoPage == "privacy") {
      return (
        <div className="info">
          <Privacy />
        </div>
      );
    }
    if (openInfoPage == "imprint") {
      return (
        <div className="info">
          <Imprint />
        </div>
      );
    }

    if (openInfoPage == "changeProfile" && !isEmpty(authToken)) {
      return (
        <div className="info">
          <div className="info_imagePreviewbox" id={"imagePreview"}>
            <img
              src={imageUploads.localURL}
              className="info_imagePreviewbox_imagePreview"
            />
            <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_delete">
              <CancelIcon onClick={() => setImageUploads([])} />
            </div>
          </div>

          <label forhtml={`imgInpProfileImage`} id={"imgInpLabel"} className="">
            <div className="info_imagePreviewbox">
              <img
                src={JSON.parse(companyData[0].profileImageURL)[0].url}
                className="info_imagePreviewbox_imagePreview"
              />
              <div className="info_imagePreviewbox_description">
                Klicken um neues Profilbild auszuwählen
              </div>
            </div>

            <div className="info_replyBox_addImage">
              <input
                accept=".jfif, .pjpeg, .pjp, .jpeg, .jpg, .png"
                type="file"
                id={"imgInpProfileImage"}
                name="files[]"
                className="info_replyBox_addImage_input"
                onChange={(e) => insertLocalImage(e)}
              />
              <label
                htmlFor={`imgInpProfileImage`}
                className="info_replyBox_addImage_label"
              ></label>
            </div>
          </label>

          <div className="info_changeName">
            <input
              className="info_changeName_input"
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
              placeholder={"Profilname"}
            />
          </div>
          {isUploading ||
          isEmpty(companyData) ||
          (isEmpty(imageUploads) &&
            profileName == companyData[0].companyname) ? (
            <div className="info_submitButtonBox">
              <div
                className="info_submitButtonBox_submitButton_disabled"
                id={"submitChangeProfileDisabled"}
              >
                Änderungen speichern
              </div>
            </div>
          ) : (
            <div className="info_submitButtonBox">
              <div
                className="info_submitButtonBox_submitButton"
                id={"submitChangeProfile"}
                onClick={() => uploadProfileData()}
              >
                Änderungen speichern
              </div>
            </div>
          )}
        </div>
      );
    }

    if (type == "termin") {
      return <div>Termin gebucht !</div>;
    }
  };

  return (
    <div className="register">
      <div className="register__fixedTopBar">
        {type != "termin" ? (
          <div onClick={() => setOpenInfoPage("")}>
            <BackIcon />
          </div>
        ) : (
          <div onClick={() => navigate("/")}>
            <BackIcon />
          </div>
        )}

        <div className="register__fixedTopBar_text">Info</div>
      </div>
      {infoData()}
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 20px;
  color: #000000;
  margin-top: -1px;
  margin-left: 3px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 30px;
  color: #000;
  padding-right: 10px;
  padding-top: 7px;
`;

const ImageIcon = styled(MdOutlineImage)`
  font-size: 30px;
  color: #388eff;
  margin-bottom: 5px;
`;
const CancelIcon = styled(MdCancel)`
  font-size: 28px;
  color: #575757;
  margin-bottom: 1px;
`;
