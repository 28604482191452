import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../../functions/isEmpty";
import {
  MdAdd,
  MdArrowForwardIos,
  MdBusiness,
  MdConstruction,
  MdDeveloperBoard,
  MdDocumentScanner,
  MdFitnessCenter,
  MdHealthAndSafety,
  MdHouse,
  MdMedicalServices,
  MdMonetizationOn,
  MdPartyMode,
  MdSearch,
  MdShop,
  MdSpa,
} from "react-icons/md";
import Profile from "../Profile";
import Fragen from "../Fragen";

import "../../styles/Columns.css";
import Search from "../Search";
import Register from "../Register";
import Article from "../Article";
import NewQuestion from "../NewQuestion";
import NotLoggedInRightSidebar from "../NotLoggedInRightSidebar";
import Settings from "../Settings";
import Contacts from "../Contacts";
import Chat from "../Chat";
import Appointments from "../Appointments";
import CalenderConfig from "../CalenderConfig";
import DetailAppointment from "../DetailAppointment";

export default function MiddleAndRightColumnForContacts({
  authToken,
  createNewQuestion,
  setCreateNewQuestion,
  openSettings,
  setOpenSettings,
  logout,
  companyData,
  setCompanyData,
  responseCompanyData,
  setResponseCompanyData,
  userData,
  tagData1,
  tagData2,
  tagData3,
  feedData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  refresh,
  setRefresh,
  companyAnswers,
  setCompanyAnswers,
  openCalenderConfig,
  setOpenCalenderConfig,
  servicesData,
  setServicesData,
  ownerAppointmentsData,
  setOwnerAppointmentsData,
  bookerAppointmentsData,
  setBookerAppointmentsData,
  mobile,
  setMobile
}) {
  const [activeAppointment, setActiveAppointment] = useState([]);

  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  const windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const showActivePage = () => {
    if (createNewQuestion) {
      return <NewQuestion setCreateNewQuestion={setCreateNewQuestion} />;
    } else if (openSettings) {
      return (
        <Settings
          logout={logout}
          authToken={authToken}
          setOpenSettings={setOpenSettings}
        />
      );
    } else if((windowWidth < 1050) && openCalenderConfig){return (
      <CalenderConfig
            authToken={authToken}
            setOpenSettings={setOpenSettings}
            companyData={companyData}
            setCompanyData={setCompanyData}
            responseCompanyData={responseCompanyData}
            setResponseCompanyData={setResponseCompanyData}
            userData={userData}
            tagData1={tagData1}
            tagData2={tagData2}
            tagData3={tagData3}
            numberOfPosts={numberOfPosts}
            setNumberOfPosts={setNumberOfPosts}
            openArticle={openArticle}
            setOpenArticle={setOpenArticle}
            companyAnswers={companyAnswers}
            type={"sidebar"}
            servicesData={servicesData}
            setServicesData={setServicesData}
            refresh={refresh}
            setRefresh={setRefresh}
            openCalenderConfig={openCalenderConfig}
            setOpenCalenderConfig={setOpenCalenderConfig}
            mobile={mobile}
            setMobile={setMobile}
          />
    )}
    else if ((windowWidth < 1050) && !isEmpty(activeAppointment)){return(<DetailAppointment
      authToken={authToken}
      setOpenSettings={setOpenSettings}
      companyData={companyData}
      setCompanyData={setCompanyData}
      responseCompanyData={responseCompanyData}
      setResponseCompanyData={setResponseCompanyData}
      userData={userData}
      tagData1={tagData1}
      tagData2={tagData2}
      tagData3={tagData3}
      numberOfPosts={numberOfPosts}
      setNumberOfPosts={setNumberOfPosts}
      openArticle={openArticle}
      setOpenArticle={setOpenArticle}
      companyAnswers={companyAnswers}
      type={"sidebar"}
      activeAppointment={activeAppointment}
      setActiveAppointment={setActiveAppointment}
      refresh={refresh}
      setRefresh={setRefresh}
      mobile={mobile}
      setMobile={setMobile}
    />)}
    else {
      return (
        <Appointments
          setCreateNewQuestion={setCreateNewQuestion}
          authToken={authToken}
          feedData={feedData}
          userData={userData}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
          openCalenderConfig={openCalenderConfig}
          setOpenCalenderConfig={setOpenCalenderConfig}
          ownerAppointmentsData={ownerAppointmentsData}
          setOwnerAppointmentsData={setOwnerAppointmentsData}
          bookerAppointmentsData={bookerAppointmentsData}
          setBookerAppointmentsData={setBookerAppointmentsData}
          activeAppointment={activeAppointment}
          setActiveAppointment={setActiveAppointment}
        />
      );
    }
  };

  return (
    <div className="home">
      <div className="contacts__scrollPageLeft">{showActivePage()}</div>
      <div className="contacts__fixedPageRight">
        {openCalenderConfig ? (
          <CalenderConfig
            authToken={authToken}
            setOpenSettings={setOpenSettings}
            companyData={companyData}
            setCompanyData={setCompanyData}
            responseCompanyData={responseCompanyData}
            setResponseCompanyData={setResponseCompanyData}
            userData={userData}
            tagData1={tagData1}
            tagData2={tagData2}
            tagData3={tagData3}
            numberOfPosts={numberOfPosts}
            setNumberOfPosts={setNumberOfPosts}
            openArticle={openArticle}
            setOpenArticle={setOpenArticle}
            companyAnswers={companyAnswers}
            type={"sidebar"}
            servicesData={servicesData}
            setServicesData={setServicesData}
            refresh={refresh}
            setRefresh={setRefresh}
            openCalenderConfig={openCalenderConfig}
            setOpenCalenderConfig={setOpenCalenderConfig}
            mobile={mobile}
            setMobile={setMobile}
          />
        ) : (
          <DetailAppointment
            authToken={authToken}
            setOpenSettings={setOpenSettings}
            companyData={companyData}
            setCompanyData={setCompanyData}
            responseCompanyData={responseCompanyData}
            setResponseCompanyData={setResponseCompanyData}
            userData={userData}
            tagData1={tagData1}
            tagData2={tagData2}
            tagData3={tagData3}
            numberOfPosts={numberOfPosts}
            setNumberOfPosts={setNumberOfPosts}
            openArticle={openArticle}
            setOpenArticle={setOpenArticle}
            companyAnswers={companyAnswers}
            type={"sidebar"}
            activeAppointment={activeAppointment}
            setActiveAppointment={setActiveAppointment}
            refresh={refresh}
            setRefresh={setRefresh}
            mobile={mobile}
            setMobile={setMobile}
          />
        )}
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #667781;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 18px;
  color: #667781;
  margin-bottom: -4px;
  margin-right: 11px;
`;

const ArrowRight = styled(MdArrowForwardIos)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const BusinessIcon = styled(MdBusiness)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const HouseIcon = styled(MdHouse)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const ConstructionIcon = styled(MdConstruction)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const SpaIcon = styled(MdSpa)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const HealthIcon = styled(MdHealthAndSafety)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const MedicIcon = styled(MdMedicalServices)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const InfluencerIcon = styled(MdPartyMode)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const ShopIcon = styled(MdShop)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const DeveloperIcon = styled(MdDeveloperBoard)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const TaxIcon = styled(MdMonetizationOn)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const FitnessIcon = styled(MdFitnessCenter)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;

const InsuranceIcon = styled(MdDocumentScanner)`
  font-size: 12px;
  color: lightgrey;
  margin-bottom: -1px;
`;
