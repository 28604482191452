import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MdSearch, MdKeyboardArrowRight, MdArrowBack } from "react-icons/md";

import "../../styles/SearchResultExpert.css";
import Question from "../content/Question";
import Expert from "../content/Expert";
import RelevantTag from "../content/RelevantTag";
import Answer from "../content/Answer";
import { isEmpty } from "../../functions/isEmpty";

export default function SearchResultExpert({
  setDetailPage,
  type,
  feed,
  authToken,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  answerData,
  companyAnswers,
  setCompanyAnswers,
  data,
  pathFromSearch,
  setPathFromSearch,
}) {
  let navigate = useNavigate();

  const handleClickOnSearchResult = (path) => {
    setPathFromSearch(true);
    navigate(path + data.id);
  };

  return (
    <div
      className="searchResultExpert"
      onClick={() => handleClickOnSearchResult("/profile/")}
    >
      <div className="searchResultExpert__left">
        <div className="searchResultExpert__left_imagebox">
          <img
            className="searchResultExpert__left_imagebox_image"
            src={JSON.parse(data.profileImageURL)[0].url}
          />
        </div>
      </div>
      <div className="searchResultExpert__right">
        <div className="searchResultExpert__right_top">{data.companyname}</div>
        <div className="searchResultExpert__right_bottom">
          <div className="searchResultExpert__right_bottom_text">
            Experte für
          </div>
          {data.tag1JSON != "[]" ? (
            <div className="searchResultExpert__right_bottom_tag">
              {JSON.parse(data.tag1JSON)[0].title}
            </div>
          ) : (
            <></>
          )}
          {data.tag2JSON != "[]" ? (
            <div className="searchResultExpert__right_bottom_tag">
              {JSON.parse(data.tag2JSON)[0].title}
            </div>
          ) : (
            <></>
          )}
          {data.tag3JSON != "[]" ? (
            <div className="searchResultExpert__right_bottom_tag">
              {JSON.parse(data.tag3JSON)[0].title}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;
