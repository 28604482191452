import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdFilterList } from "react-icons/md";

import "../styles/Fragen.css";
import PostSingle from "../components/posts/PostSingle";
import { useNavigate } from "react-router";
import { useRef } from "react";

export default function Fragen({
  authToken,
  setCreateNewQuestion,
  feedData,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
  refresh,
  setRefresh,
  likes,
  setLikes,
  refreshLikes,
  setRefreshLikes,
  refreshTags,
  setRefreshTags,
  bottom,
  setBottom,
}) {
  let navigate = useNavigate();
  const [count, setCount] = useState(20);
  const [render, setRender] = useState([]);

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/search");
    }
  }, [authToken]);

  const openCreateNewQuestionView = () => {
    setCreateNewQuestion(true);
  };

  useEffect(() => {
    if (bottom) {
      setCount(count + 20);
      setBottom(false);
    }

    let newCount = count + 20;

    let feedLength = feedData.length;
    if (feedLength > newCount) {
      feedLength = newCount;
    }
    let helpArray = [];

    console.log(feedLength);
    if (!isEmpty(feedData)) {
      for (let i = 0; i < feedLength; i++) {
        helpArray.push(feedData[i]);
      }
    }

    setRender(helpArray);
    console.log(count);
  }, [bottom, feedData]);

  const listInnerRef = useRef();

  // userdata in state packen
  const renderFeed = () => {};

  return (
    <div className="Fragen">
      <div className="fragen__fixedTopBar">
        <div className="fragen__fixedTopBar_leftColumn">
          <div className="fragen__fixedTopBar_leftColumn_topText">
            Fragen & Antworten
          </div>
        </div>
        <div className="fragen__fixedTopBar_middleColumn">
          <div
            className="fragen__fixedTopBar_middleColumn_newQuestionButton"
            onClick={() => openCreateNewQuestionView()}
          >
            <div className="fragen__fixedTopBar_middleColumn_newQuestionButton_icon">
              <AddIcon />
            </div>
            <div className="fragen__fixedTopBar_middleColumn_newQuestionButton_text">
              Frage stellen
            </div>
          </div>
        </div>
        {/*  
        <div className="fragen__fixedTopBar_rightColumn">
          <div className="fragen__fixedTopBar_rightColumn_sortButton">
            <div className="fragen__fixedTopBar_rightColumn_sortButton_icon">
             
             <FilterIcon />  
             
            </div>
            <div className="fragen__fixedTopBar_rightColumn_sortButton_text"></div>
          </div>
        </div>
        */}
      </div>
      <div className="fragen__scrollContent">
        {!isEmpty(feedData) && !isEmpty(render) ? (
          <>
            {render.map((render) => (
              <PostSingle
                authToken={authToken}
                key={"post" + render.id}
                feed={render}
                type="question"
                userData={userData}
                companyData={companyData}
                numberOfPosts={numberOfPosts}
                setNumberOfPosts={setNumberOfPosts}
                openArticle={openArticle}
                setOpenArticle={setOpenArticle}
                activeArticle={activeArticle}
                setActiveArticle={setActiveArticle}
                companyAnswers={companyAnswers}
                setCompanyAnswers={setCompanyAnswers}
                refresh={refresh}
                setRefresh={setRefresh}
                likes={likes}
                setLikes={setLikes}
                refreshLikes={refreshLikes}
                setRefreshLikes={setRefreshLikes}
                refreshTags={refreshTags}
                setRefreshTags={setRefreshTags}
              />
            ))}
          </>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 20px;
  color: #575757;
  margin-top: 4px;
`;

const FilterIcon = styled(MdFilterList)`
  font-size: 20px;
  color: #575757;
  margin-top: 1px;
`;
