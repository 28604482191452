import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdArrowBack, MdFilterList, MdSearch } from "react-icons/md";

import "../styles/Register.css";
import PostThemebox from "../components/posts/PostThemebox";
import CTA from "../components/buttons/CTA";
import Tag from "../components/buttons/Tag";
import { useNavigate } from "react-router";

export default function Register({
  authToken,
  email,
  setEmail,
  password,
  setPassword,
  username,
  setUsername,
  signUp,
  interestedTags,
  setInterestedTags,
  setOpenLogin,
  setOpenRegister,
  isUploading,
  setIsUploading,

  mobile,
  setOpenSettings,
}) {
  const [formIsFilled, setFormIsFilled] = useState(false);
  const [tagCounter, setTagCounter] = useState(0);

  let navigate = useNavigate();
  useEffect(() => {
    if (!isEmpty(authToken)) {
      setOpenLogin(false);
      setOpenRegister(false);
      navigate("/");
    }
  }, [authToken]);

  useEffect(() => {
    if (
      isEmpty(username) ||
      isEmpty(email) ||
      isEmpty(password) ||
      tagCounter == 0 ||
      !validateEmail(email) ||
      password.length < 6
    ) {
      setFormIsFilled(false);
    } else {
      setFormIsFilled(true);
    }
  }, [username, email, password, tagCounter]);

  const signUpAndDisableButton = () => {
    setIsUploading(true);
    signUp();
  };

  const openSettings = () => {
    setOpenLogin(false);
    setOpenRegister(false);
    setOpenSettings(true);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="register">
      <div className="register__fixedTopBar">
        <div onClick={() => setOpenRegister(false)}>
          <BackIcon />
        </div>
        <div className="register__fixedTopBar_text">Registrieren</div>
      </div>
      <div className="register__scrollContent">
        <div className="register__scrollContent_nameBox">
          <div className="register__scrollContent_nameBox_nameText">Name</div>
          <div className="register__scrollContent_nameBox_nameDescription">
            Dieser Name wird anderen angezeigt und kann jederzeit geändert
            werden
          </div>
          <div className="register__scrollContent_nameBox_nameInputbox">
            <input
              className="register__scrollContent_nameBox_nameInputbox_input"
              placeholder="Name eingeben"
              type="text"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="register__scrollContent_nameBox_nameInputbox">
            <input
              className="register__scrollContent_nameBox_nameInputbox_input"
              placeholder="Emailadresse eingeben"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="register__scrollContent_nameBox_nameInputbox">
            <input
              className="register__scrollContent_nameBox_nameInputbox_input"
              placeholder="Passwort eingeben"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="register__scrollContent_interestsBox">
          <div className="register__scrollContent_interestsBox_nameText">
            Interessen
          </div>
          <div className="register__scrollContent_interestsBox_nameDescription">
            Klicke auf mindestens ein Thema in dem du beruflich tätig bist oder
            sein willst
          </div>
          <div className="register__scrollContent_interestsBox_section">
            <div className="register__scrollContent_interestsBox_section_title">
              Gesundheitsbereich
            </div>
            <div className="register__scrollContent_interestsBox_section_tags">
              <Tag
                tagID={1}
                text="Medizin"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={2}
                text="Psychologie"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={3}
                text="Physiotherapie"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={4}
                text="Ergotherapie"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={5}
                text="Schmerztherapie"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={6}
                text="Gesundheitstherapie"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={7}
                text="Heilpraktik"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={8}
                text="Krankenpflege"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={9}
                text="Altenpflege"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={10}
                text="Ernährungsberatung"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={11}
                text="Fitness"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
              <Tag
                tagID={12}
                text="Bodybuilding"
                interestedTags={interestedTags}
                setInterestedTags={setInterestedTags}
                tagCounter={tagCounter}
                setTagCounter={setTagCounter}
              />
            </div>
          </div>
        </div>
        {formIsFilled ? (
          <>
            {!isUploading ? (
              <div
                className="register__scollContent_CTAbox"
                onClick={() => signUpAndDisableButton()}
              >
                <CTA text="Registrieren" />
              </div>
            ) : (
              <div className="register__scollContent_CTAbox">
                <CTA text="Registrieren" type="disabled" />
              </div>
            )}
          </>
        ) : (
          <div className="register__scollContent_CTAbox">
            <CTA text="Registrieren" type="disabled" />
          </div>
        )}

        {mobile && isEmpty(authToken) ? (
          <div className="search__infoLinks">
            <div
              className="sidebar__footer_links"
              onClick={() => openSettings()}
            >
              Impressum. Datenschutz.
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 20px;
  color: #000000;
  margin-top: -1px;
  margin-left: 3px;
`;

const BackIcon = styled(MdArrowBack)`
  font-size: 30px;
  color: #000;
  padding-right: 10px;
  padding-top: 7px;
`;
