import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MdSearch, MdKeyboardArrowRight, MdArrowBack } from "react-icons/md";

import "../../styles/PostSingle.css";
import Question from "../content/Question";
import Expert from "../content/Expert";
import RelevantTag from "../content/RelevantTag";
import Answer from "../content/Answer";
import QuestionWithOwnAnswer from "../content/QuestionWithOwnAnswer";

export default function PostSingleWithOwnAnswer({
  setDetailPage,
  type,
  feed,
  authToken,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  answerData,
  articleID,
  setArticleID
}) {
  const returnContent = () => {
    if (type == "question") {
      return (
        <Question
          feed={feed}
          authToken={authToken}
          key={"question" + feed.id}
          userData={userData}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
        />
      );
    } else if (type == "expert") {
      return <Expert />;
    } else if (type == "tag") {
      return <RelevantTag />;
    } else if (type == "answer") {
      return (
        <Answer
          answerData={answerData}
          key={answerData.id}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
        />
      );
    } else if (type == "ownAnswer") {
      return (
        <QuestionWithOwnAnswer
          answerData={answerData}
          key={"answerData.id"}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          articleID={articleID}
          setArticleID={setArticleID}
          authToken={authToken}
        />
      );
    }
  };
  /* 
  Question, ExpertAvatar, RelevantTag wird unterschieden
  */

  return <div className="postSingleWithOwnAnswer">{returnContent()}</div>;
}

const ArrowFwdIcon = styled(MdKeyboardArrowRight)`
  font-size: 25px;
  color: #ababab;
`;

const ArrowBackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #54656f;
`;
