import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../../styles/Expert.css";

export default function Expert({ store }) {
  return (
    <div className="expert">
      <div className="expert_imagebox">
        <img
          className="expert_imagebox_image"
          src="https://cdn.discordapp.com/attachments/832697220406181924/1075516311045681173/IMG_8206.png"
        />
      </div>
      <div className="expert_description">
        <div className="expert_description_name">Physiotherapie E. Sazegar</div>
        <div className="expert_description_expertFor">
          <div className="expert_description_expertFor_text">Experte für</div>
          <div className="expert_description_expertFor_tag">Schmerzen</div>
        </div>
      </div>
    </div>
  );
}
const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;

const QuestionAnswerIconActive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #303030;
`;

const SearchIconInactive = styled(MdSearch)`
  font-size: 25px;
  color: #575757;
`;

const SearchIconActive = styled(MdSearch)`
  font-size: 25px;
  color: #303030;
`;

const ContactsIconInactive = styled(MdPeopleOutline)`
  font-size: 25px;
  color: #575757;
`;

const ContactsIconActive = styled(MdPeople)`
  font-size: 25px;
  color: #303030;
`;

const MoreIconInactive = styled(MdMore)`
  font-size: 25px;
  color: #575757;
`;

const MoreIconActive = styled(MdMore)`
  font-size: 25px;
  color: #303030;
`;

const ReputationIconInactive = styled(MdStarBorder)`
  font-size: 25px;
  color: #575757;
`;

const ReputationIconActive = styled(MdStar)`
  font-size: 25px;
  color: #303030;
`;
