import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdFilterList } from "react-icons/md";

import "../styles/Chat.css";
import ContactPreview from "../components/contacts/ContactPreview";
import { useNavigate } from "react-router";

export default function Chat({
  authToken,
  setCreateNewQuestion,
  feedData,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
}) {
  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/search");
    }
  }, [authToken]);

  const openCreateNewQuestionView = () => {
    setCreateNewQuestion(true);
  };

  // userdata in state packen
  const renderFeed = () => {
    if (isEmpty(feedData)) {
      return feedData.map((feed) => (
        <contactPreview
          authToken={authToken}
          key={"post" + feed.id}
          feed={feed}
          type="question"
          userData={userData}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
        />
      ));
    } else {
      return (
        <ContactPreview
          authToken={authToken}
          key={"post"}
          type="question"
          userData={userData}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
        />
      );
    }
  };

  return (
    <div className="chat">
      <div className="chat__fixedTopBar">
        <div className="chat__fixedTopBar_leftColumn">
          <div className="chat__fixedTopBar_leftColumn_topText">DJ Donovan</div>
        </div>
        <div className="chat__fixedTopBar_middleColumn"></div>
        {/*  
        <div className="chat__fixedTopBar_rightColumn">
          <div className="chat__fixedTopBar_rightColumn_sortButton">
            <div className="chat__fixedTopBar_rightColumn_sortButton_icon">
             
             <FilterIcon />  
             
            </div>
            <div className="chat__fixedTopBar_rightColumn_sortButton_text"></div>
          </div>
        </div>
        */}
      </div>
      <div className="chat__scrollContent">
      <div className="chat__scrollContent_contactMessageBox">
        <div className="chat__scrollContent_contactMessageBox_contactMessage">
          hallo brudi wieviel kosteta
          asasD
          asasDasD
          asasDasdsa
          asasDasDasad
          asasDasDsadasdasdsadsad
          sadsadsadsad
          sadad
        </div>        </div>
        <div className="chat__scrollContent_ownMessageBox">
        <div className="chat__scrollContent_ownMessageBox_ownMessage">
          Die Erstbehandlung de kost nix. auf korrekt.aaaaa
          aaaaaa
          aaaaa
          aaaaaa
          aaaaaA
          A
          aaaaaasdsadsadasdasdaaaaaaaaaaaaaaaaaaadsasdsad
          sadsadsadaaaaaaaadasssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssssads
          adssssssssssssssss
        </div>

        </div>
        <div className="chat__scrollContent_infoMessageBox">
        <div className="chat__scrollContent_infoMessageBox_infoMessage">
          Termin wurde gebucht amk
        </div>        </div>   <div className="chat__scrollContent_contactMessageBox">
        <div className="chat__scrollContent_contactMessageBox_contactMessage">
          hallo brudi wieviel kosteta
          asasD
          asasDasD
          asasDasdsa
          asasDasDasad
          asasDasDsadasdasdsadsad
          sadsadsadsad
          sadad
        </div>        </div>
        <div className="chat__scrollContent_ownMessageBox">
        <div className="chat__scrollContent_ownMessageBox_ownMessage">
          Die Erstbehandlung de kost nix. auf korrekt.aaaaa
          aaaaaa
          aaaaa
          aaaaaa
          aaaaaA
          A
          aaaaaasdsadsadasdasdaaaaaaaaaaaaaaaaaaadsasdsad
          sadsadsadaaaaaaaadasssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssssads
          adssssssssssssssss
        </div>

        </div>
        <div className="chat__scrollContent_infoMessageBox">
        <div className="chat__scrollContent_infoMessageBox_infoMessage">
          Termin wurde gebucht amk
        </div>        </div>   <div className="chat__scrollContent_contactMessageBox">
        <div className="chat__scrollContent_contactMessageBox_contactMessage">
          hallo brudi wieviel kosteta
          asasD
          asasDasD
          asasDasdsa
          asasDasDasad
          asasDasDsadasdasdsadsad
          sadsadsadsad
          sadad
        </div>        </div>
        <div className="chat__scrollContent_ownMessageBox">
        <div className="chat__scrollContent_ownMessageBox_ownMessage">
          Die Erstbehandlung de kost nix. auf korrekt.aaaaa
          aaaaaa
          aaaaa
          aaaaaa
          aaaaaA
          A
          aaaaaasdsadsadasdasdaaaaaaaaaaaaaaaaaaadsasdsad
          sadsadsadaaaaaaaadasssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssssads
          adssssssssssssssss
        </div>

        </div>
        <div className="chat__scrollContent_infoMessageBox">
        <div className="chat__scrollContent_infoMessageBox_infoMessage">
          Termin wurde gebucht amk
        </div>        </div>   <div className="chat__scrollContent_contactMessageBox">
        <div className="chat__scrollContent_contactMessageBox_contactMessage">
          hallo brudi wieviel kosteta
          asasD
          asasDasD
          asasDasdsa
          asasDasDasad
          asasDasDsadasdasdsadsad
          sadsadsadsad
          sadad
        </div>        </div>
        <div className="chat__scrollContent_ownMessageBox">
        <div className="chat__scrollContent_ownMessageBox_ownMessage">
          Die Erstbehandlung de kost nix. auf korrekt.aaaaa
          aaaaaa
          aaaaa
          aaaaaa
          aaaaaA
          A
          aaaaaasdsadsadasdasdaaaaaaaaaaaaaaaaaaadsasdsad
          sadsadsadaaaaaaaadasssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssssads
          adssssssssssssssss
        </div>

        </div>
        <div className="chat__scrollContent_infoMessageBox">
        <div className="chat__scrollContent_infoMessageBox_infoMessage">
          Termin wurde gebucht amk
        </div>        </div>   <div className="chat__scrollContent_contactMessageBox">
        <div className="chat__scrollContent_contactMessageBox_contactMessage">
          hallo brudi wieviel kosteta
          asasD
          asasDasD
          asasDasdsa
          asasDasDasad
          asasDasDsadasdasdsadsad
          sadsadsadsad
          sadad
        </div>        </div>
        <div className="chat__scrollContent_ownMessageBox">
        <div className="chat__scrollContent_ownMessageBox_ownMessage">
          Die Erstbehandlung de kost nix. auf korrekt.aaaaa
          aaaaaa
          aaaaa
          aaaaaa
          aaaaaA
          A
          aaaaaasdsadsadasdasdaaaaaaaaaaaaaaaaaaadsasdsad
          sadsadsadaaaaaaaadasssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssssads
          adssssssssssssssss
        </div>

        </div>
        <div className="chat__scrollContent_infoMessageBox">
        <div className="chat__scrollContent_infoMessageBox_infoMessage">
          Termin wurde gebucht amk
        </div>        </div>   <div className="chat__scrollContent_contactMessageBox">
        <div className="chat__scrollContent_contactMessageBox_contactMessage">
          hallo brudi wieviel kosteta
          asasD
          asasDasD
          asasDasdsa
          asasDasDasad
          asasDasDsadasdasdsadsad
          sadsadsadsad
          sadad
        </div>        </div>
        <div className="chat__scrollContent_ownMessageBox">
        <div className="chat__scrollContent_ownMessageBox_ownMessage">
          Die Erstbehandlung de kost nix. auf korrekt.aaaaa
          aaaaaa
          aaaaa
          aaaaaa
          aaaaaA
          A
          aaaaaasdsadsadasdasdaaaaaaaaaaaaaaaaaaadsasdsad
          sadsadsadaaaaaaaadasssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssss
          adsssssssssssssssssssssssssssssssssssssssssssads
          adssssssssssssssss
        </div>

        </div>
        <div className="chat__scrollContent_infoMessageBox">
        <div className="chat__scrollContent_infoMessageBox_infoMessage">
          Termin wurde gebucht amk
        </div>        </div>
      </div>
      <div className="chat__fixedBottomBar">
        <div className="chat__fixedBottomBar_inputBox">
          <span
            className="chat__fixedBottomBar_inputBox_input"
            //  onFocus={() => activateEnterButton()}
            //  onBlur={() => deactivateEnterButton()}
            contentEditable={"true"}
            role={"textbox"}
            id={"input" + "feed.id"}
          ></span>
        </div>
        <div className="chat__scrollContent_element_submitButtonBox">
          <div
            className="chat__scrollContent_element_submitButtonBox_submitButton"
            id={"submit" + "feed.id"}
            //    onClick={() => handleupload()}
          >
            senden
          </div>
        </div>
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 20px;
  color: #575757;
  margin-top: 4px;
`;

const FilterIcon = styled(MdFilterList)`
  font-size: 20px;
  color: #575757;
  margin-top: 1px;
`;
