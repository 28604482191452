import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdMore,
  MdPeople,
  MdPeopleOutline,
  MdQuestionAnswer,
  MdSearch,
  MdStar,
  MdStarBorder,
} from "react-icons/md";

import "../../styles/CTA.css";
import { isEmpty } from "../../functions/isEmpty";

export default function CTA({ text, type, size, doWhenClicked }) {
  if (isEmpty(text)) {
    text = "Platzhalter";
  }

  if (type == "disabled") {
    return <div className="cta__disabled">{text}</div>;
  }

  if (type == "outlined" && !(size == "small")) {
    return <div className="cta__outlined">{text}</div>;
  }

  if (type == "disabled" && size == "small") {
    return <div className="cta__disabledSmall">{text}</div>;
  }

  if (type == "outlined" && size == "small") {
    return <div className="cta__outlinedSmall">{text}</div>;
  }

  if (size == "small") {
    return <div className="cta__small">{text}</div>;
  }

  return <div className="cta">{text}</div>;
}
const QuestionAnswerIconInactive = styled(MdQuestionAnswer)`
  font-size: 25px;
  color: #575757;
`;
