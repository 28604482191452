import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import {
  MdAdd,
  MdArrowBack,
  MdCancel,
  MdFilterList,
  MdOutlineImage,
  MdSearch,
} from "react-icons/md";

import "../styles/Article.css";
import PostThemebox from "../components/posts/PostThemebox";
import CTA from "../components/buttons/CTA";
import Tag from "../components/buttons/Tag";
import PostSingle from "../components/posts/PostSingle";
import { getTodayAsUnixDate } from "../functions/getTodayAsUnixDate";

export default function Article({
  authToken,
  feedData,
  activeArticle,
  setOpenArticle,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  refresh,
  setRefresh,
  companyAnswers,
  setCompanyAnswers,
  likes,
  refreshLikes,
  setRefreshLikes,
  refreshTags,
  setRefreshTags,
}) {
  const [allFetchedAnswers, setAllFetchedAnswers] = useState([]);
  const [responseAllFetchedAnswers, setResponseAllFetchedAnswers] =
    useState("");
  const [ownAnswerObject, setOwnAnswerObject] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [imageUploads, setImageUploads] = useState([]);

  const activateEnterButton = () => {
    let submitButton = document.getElementById("submit" + activeArticle.id);
    let inputField = document.getElementById("input" + activeArticle.id);
    console.log(inputField.innerHTML);
    submitButton.style.display = "flex";
  };
  const deactivateEnterButton = () => {
    let submitButton = document.getElementById("submit" + activeArticle.id);
    let inputField = document.getElementById("input" + activeArticle.id);
    console.log(inputField.innerHTML);

    if (inputField.innerHTML == "") {
      submitButton.style.display = "none";
    }
  };

  const uploadLike = async (isLiked, answerID) => {
    if (isLiked) {
      // update status zu 0, unlike
      let status = 0;

      let now = getTodayAsUnixDate();
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/likes/PUTandPOSTLikes.php",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            likerID: companyData[0].id,
            answerID: answerID,
            postID: activeArticle.id,
          }),
        }
      );

      await resa.text().then(() => setRefreshLikes(!refreshLikes));
    } else {
      // erstellen neuer like
      let status = 1;

      let now = getTodayAsUnixDate();
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/likes/PUTandPOSTLikes.php",
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            likerID: companyData[0].id,
            answerID: answerID,
            postID: activeArticle.id,
            status: status,
            createdAt: now,
          }),
        }
      );

      await resa.text().then(() => setRefreshLikes(!refreshLikes));
    }
  };

  const handleUploadForImage = async () => {
    setIsUploading(true);

    const files = document.getElementById("imgInp" + activeArticle.id).files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    if (files.length != 0 && !isEmpty(imageUploads)) {
      const res = await fetch(
        "https://www.cloud.leefes.com/api/images/process.php",
        {
          headers: {
            Authorization: `Bearer ${authToken?.JWT}`,
          },
          method: "POST",
          body: formData,
        }
      );

      await res.text().then((value) => handleuploadJSON(value));
    } else {
      handleuploadJSON("");
    }
  };

  const handleuploadJSON = async (imgURL) => {
    let inputFieldText = document.getElementById(
      "input" + activeArticle.id
    ).innerHTML;

    let submitButton = document.getElementById("submit" + activeArticle.id);
    submitButton.className =
      "question__scrollContent_element_submitButtonBox_submitButton_disabled";

    let companyIMG = JSON.stringify([
      JSON.parse(companyData[0].profileImageURL)[0],
    ]);

    const resa = await fetch(
      "https://www.cloud.leefes.com/api/answers/newAnswer.php",
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          postID: activeArticle.id,
          companyname: companyData[0].companyname,
          likesCount: 0,
          companyID: companyData[0].id,
          text: inputFieldText,
          imageURL: imgURL,
          companyProfileImageURL: companyIMG,
          postTitle: activeArticle.title,
          postTag1Title: activeArticle.tag1Title,
          postTag2Title: activeArticle.tag2Title,
          postTag3Title: activeArticle.tag3Title,
          postTag1ID: activeArticle.tag1ID,
          postTag2ID: activeArticle.tag2ID,
          postTag3ID: activeArticle.tag3ID,
        }),
      }
    );

    await resa.text().then(() => secondUpload(imgURL));
  };

  const secondUpload = async (imgURL) => {
    const addOneToIDs = (id1, id2, id3, id1Title, id2Title, id3Title) => {
      let helpArray = JSON.parse(companyData[0].expertForTags);
      let helpArray2 = [];
      let id1IsNew = true;
      let id2IsNew = true;
      let id3IsNew = true;
      helpArray.map((element) => {
        // es wird gemapt und gesucht, wenn gefunden, um eins erhöht.
        if (element.id == id1 || element.id == id2 || element.id == id3) {
          helpArray2.push({
            id: element.id,
            count: element.count + 1,
            title: element.title,
          });
          // schauen ob ein tag dabei ist, der noch garnicht in expertForTags in companydata enthalten ist
          if (element.id == id1) {
            id1IsNew = false;
          }
          if (element.id == id2) {
            id2IsNew = false;
          }
          if (element.id == id3) {
            id3IsNew = false;
          }
        } else {
          helpArray2.push({
            id: element.id,
            count: element.count,
            title: element.title,
          });
        }
      });

      if (id1IsNew) {
        helpArray2.push({
          id: id1,
          count: 1,
          title: id1Title,
        });
      }
      if (id2IsNew) {
        helpArray2.push({
          id: id2,
          count: 1,
          title: id2Title,
        });
      }
      if (id3IsNew) {
        helpArray2.push({
          id: id3,
          count: 1,
          title: id3Title,
        });
      }

      return JSON.stringify(helpArray2);
    };
    let expertsForPlusOne = addOneToIDs(
      activeArticle.tag1ID,
      activeArticle.tag2ID,
      activeArticle.tag3ID,
      activeArticle.tag1Title,
      activeArticle.tag2Title,
      activeArticle.tag3Title
    );

    let helpArrayImages = [];
    if (!isEmpty(JSON.parse(companyData[0].postImagesURL))) {
      for (
        let i = 0;
        i < JSON.parse(companyData[0].postImagesURL).length;
        i++
      ) {
        helpArrayImages.push(JSON.parse(companyData[0].postImagesURL)[i]);
      }
    }
    helpArrayImages.push(imgURL);

    if (imgURL != "") {
      helpArrayImages.push(imgURL);

      const resa = await fetch(
        "https://www.cloud.leefes.com/api/companies/?id=" + companyData[0].id,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            expertForTags: expertsForPlusOne,
            postImagesURL: JSON.stringify(helpArrayImages),
          }),
        }
      );

      await resa.text().then(() => thirdUpload(imgURL));
    } else {
      thirdUpload(imgURL);
    }
  };

  const thirdUpload = async (imgURL) => {
    let companyIMG = JSON.stringify([
      JSON.parse(companyData[0].profileImageURL)[0],
    ]);
    let inputFieldText = document.getElementById(
      "input" + activeArticle.id
    ).innerHTML;

    if (isEmpty(activeArticle.topAnswerID)) {
      // wenn es die erste antwort ist
      const resa = await fetch(
        "https://www.cloud.leefes.com/api/posts/?id=" + activeArticle.id,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            topAnswerID: companyData[0].id,
            topAnswerName: companyData[0].companyname,
            topAnswerProfileImage: companyIMG,
            topAnswerText: inputFieldText,
            topAnswerLikes: 0,
            topAnswerImages: imgURL,
          }),
        }
      );

      await resa.text().then(() => uploadEnds());
    } else {
      uploadEnds(imgURL);
    }
  };

  const uploadEnds = (imgURL) => {
    setRefresh(!refresh);

    setIsUploading(false);
    let submitButton = document.getElementById("submit" + activeArticle.id);
    let inputFieldText = document.getElementById(
      "input" + activeArticle.id
    ).innerHTML;
    var timeNowUnix = getTodayAsUnixDate();

    let companyIMG = JSON.stringify(
      JSON.stringify([JSON.parse(companyData[0].profileImageURL)[0]])
    );

    // setLocalAnswer(inputFieldText);
    let helpAnswer = [];
    let ownAnswerObjectString = JSON.stringify({
      id: timeNowUnix,
      createdAt: timeNowUnix,
      likesCount: 0,
      postID: activeArticle.id,
      companyname: companyData[0].companyname,
      likesCount: 0,
      companyID: companyData[0].id,
      text: inputFieldText,
      imageURL: imgURL,
      companyProfileImageURL: companyData[0].profileImageURL,
      postTitle: activeArticle.title,
      postTag1Title: activeArticle.tag1Title,
      postTag2Title: activeArticle.tag2Title,
      postTag3Title: activeArticle.tag3Title,
    });

    setOwnAnswerObject(JSON.parse(ownAnswerObjectString));
    let helpArray = companyAnswers;
    helpArray.unshift(JSON.parse(ownAnswerObjectString));
    setCompanyAnswers(helpArray);

    setNumberOfPosts(numberOfPosts + 1);

    /* in companyanswers die neue antwort einfügen */
  };

  //get companyData as String
  useEffect(async () => {
    if (!isEmpty(authToken)) {
      // customerdaten bekommen anhand der companyid
      const res = await fetch(
        "https://www.cloud.leefes.com/api/answers/?postID=" + activeArticle.id,
        {
          headers: {
            Authorization: `Bearer ${authToken.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseAllFetchedAnswers(value));
    }
  }, [authToken, activeArticle]);

  // companyDataString als JSON in state packen
  useEffect(() => {
    if (responseAllFetchedAnswers != "") {
      setAllFetchedAnswers(JSON.parse(responseAllFetchedAnswers));
    }
  }, [responseAllFetchedAnswers, authToken]);

  // alle gefetchten answers durchloopen nach der eigenen antwort und in state packen
  useEffect(() => {
    if (!isEmpty(allFetchedAnswers) && isEmpty(ownAnswerObject)) {
      for (let i = 0; i < allFetchedAnswers.length; i++) {
        console.log(allFetchedAnswers[i].id);
        console.log(companyData[0].id);
        if (allFetchedAnswers[i].companyID == companyData[0].id) {
          setOwnAnswerObject(allFetchedAnswers[i]);
        }
      }
    }
  }, [allFetchedAnswers, ownAnswerObject]);

  const goBackAndRefreshFeed = () => {
    setOpenArticle(false);
    setRefresh(!refresh);
  };

  const insertLocalImage = (e) => {
    let file = e.target.files[0];
    console.log(file);
    setImageUploads({
      localURL: URL.createObjectURL(file),
      files: file,
    });
  };

  return (
    <div className="article">
      <div className="article__fixedTopBar">
        <div onClick={() => goBackAndRefreshFeed()}>
          <BackIcon />
        </div>
        <div className="article__fixedTopBar_text">Beitrag</div>
      </div>
      <div className="article__scrollContent">
        <div className="article__scrollContent_top">
          <div className="article__scrollContent_top_tags">
            <Tag text={activeArticle.tag1Title} />{" "}
            <Tag text={activeArticle.tag2Title} />
            <Tag text={activeArticle.tag3Title} />
          </div>
          <div className="article__scrollContent_top_headline">
            {activeArticle.title}{" "}
          </div>
          <div className="article__scrollContent_top_meta">
            {!isEmpty(allFetchedAnswers) ? (
              <>{allFetchedAnswers.length} Antworten</>
            ) : (
              <>0 Antworten</>
            )}
          </div>
        </div>

        {isEmpty(ownAnswerObject) ? (
          <div className="question__scrollContent_element_reply">
            <div className="question__scrollContent_element_reply_ownImageThumbnailbox">
              <img
                className="question__scrollContent_element_reply_ownImageThumbnailbox_image"
                src={JSON.parse(companyData[0].profileImageURL)[0].url}
              />
            </div>
            <div className="question__scrollContent_element_replyBox">
              {!isEmpty(imageUploads) ? (
                <div className="question__scrollContent_element_reply_imagePreviewbox">
                  <img
                    src={imageUploads.localURL}
                    className="question__scrollContent_element_reply_imagePreviewbox_imagePreview"
                  />
                  <div className="newQuestion__scrollContent_keywordBox_questionDescription_chosenTags_delete">
                    <CancelIcon onClick={() => setImageUploads([])} />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="question__scrollContent_element_replyBox_input_flexWrapper">
                <span
                  className="question__scrollContent_element_replyBox_input"
                  placeholder="Frage beantworten"
                  onFocus={() => activateEnterButton()}
                  onBlur={() => deactivateEnterButton()}
                  contentEditable={"true"}
                  role={"textbox"}
                  id={"input" + activeArticle.id}
                ></span>
                <label forhtml={`imgInp` + activeArticle.id} className="">
                  <div className="question__scrollContent_element_replyBox_addImage">
                    <input
                      accept=".jfif, .pjpeg, .pjp, .jpeg, .jpg, .png"
                      type="file"
                      id={"imgInp" + activeArticle.id}
                      name="files[]"
                      className="question__scrollContent_element_replyBox_addImage_input"
                      onChange={(e) => insertLocalImage(e)}
                    />
                    <label
                      htmlFor={"imgInp" + activeArticle.id}
                      className="question__scrollContent_element_replyBox_addImage_label"
                    >
                      <ImageIcon />
                    </label>
                  </div>
                </label>
              </div>
            </div>
            {isUploading ? (
              <div className="question__scrollContent_element_submitButtonBox">
                <div
                  className="question__scrollContent_element_submitButtonBox_submitButton_disabled"
                  id={"submit" + activeArticle.id}
                >
                  senden
                </div>
              </div>
            ) : (
              <div className="question__scrollContent_element_submitButtonBox">
                <div
                  className="question__scrollContent_element_submitButtonBox_submitButton"
                  id={"submit" + activeArticle.id}
                  onClick={() => handleUploadForImage()}
                >
                  senden
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="article__scrollContent_answers">
            <div className="article__scrollContent_answers_answer">
              <PostSingle
                type="answer"
                key={"test" + ownAnswerObject.id}
                answerData={ownAnswerObject}
                uploadLike={uploadLike}
                likes={likes}
              />
            </div>
          </div>
        )}

        <div className="article__scrollContent_answers">
          <div className="article__scrollContent_answers_answer">
            {isEmpty(allFetchedAnswers) ? (
              <></>
            ) : (
              allFetchedAnswers.map((fetchedAnswer) => {
                if (fetchedAnswer.companyID != companyData[0].id) {
                  return (
                    <PostSingle
                      type="answer"
                      key={fetchedAnswer.id}
                      answerData={fetchedAnswer}
                      uploadLike={uploadLike}
                      likes={likes}
                    />
                  );
                }
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
const BackIcon = styled(MdArrowBack)`
  font-size: 25px;
  color: #575757;
  margin-top: 5px;
  margin-right: 15px;
`;

const CancelIcon = styled(MdCancel)`
  font-size: 28px;
  color: #575757;
  margin-bottom: 1px;
`;

const ImageIcon = styled(MdOutlineImage)`
  font-size: 30px;
  color: #388eff;
  margin-bottom: 5px;
`;

/*
fetch alle antworten zur ID und daraus answers aufbauen
*/
