import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdClose, MdFilterList, MdSearch } from "react-icons/md";

import "../styles/Search.css";
import PostThemebox from "../components/posts/PostThemebox";
import PostSingle from "../components/posts/PostSingle";
import SearchResultExpert from "../components/posts/SearchResultExpert";
import TagFetched from "../components/buttons/TagFetched";
import Tag from "../components/buttons/Tag";

export default function Search({
  searchInput,
  setSearchInput,
  searchResults,
  setSearchResults,
  pathFromSearch,
  setPathFromSearch,
  similarExperts,
  tagData1,
  tagData2,
  tagData3,
  searchFeedData,

  authToken,

  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
  refresh,
  setRefresh,
  likes,
  setLikes,
  refreshLikes,
  setRefreshLikes,
  refreshTags,
  setRefreshTags,
  bottom,
  setBottom,
  mobile,
  setMobile,
  setOpenSettings,
}) {
  const [count, setCount] = useState(10);
  const [renderSearchResults, setRenderSearchResults] = useState([]);
  const [renderSearchFeedData, setRenderSearchFeedData] = useState([]);
  const [searchResults2, setSearchResults2] = useState([]);
  const [similarExperts2, setSimilarExperts2] = useState([]);

  const handleCloseButtonClicked = () => {
    setSearchInput("");
    setSearchResults([]);
  };

  useEffect(() => {
    if (bottom) {
      setCount(count + 10);
      setBottom(false);
    }

    let newCount = count + 10;

    let feedLength = searchFeedData.length;

    let feed2Length = searchResults.length;
    if (feedLength > newCount) {
      feedLength = newCount;
    }
    if (feed2Length > newCount) {
      feedLength = newCount;
    }
    let helpArray = [];
    let helpArray2 = [];

    console.log(feedLength);
    if (!isEmpty(searchFeedData)) {
      for (let i = 0; i < feedLength; i++) {
        helpArray.push(searchFeedData[i]);
      }
    }

    if (!isEmpty(searchResults)) {
      for (let i = 0; i < feed2Length; i++) {
        helpArray.push(searchResults[i]);
      }
    }

    setRenderSearchFeedData(helpArray);
    setRenderSearchResults(helpArray2);

    console.log(count);
  }, [bottom, searchFeedData, searchResults]);

  useEffect(() => {
    let helpArray = [];
    if (!isEmpty(companyData) && !isEmpty(searchResults)) {
      for (let i = 0; i < searchResults.length; i++) {
        if (searchResults[i].id != companyData[0].id) {
          helpArray.push(searchResults[i]);
        }
      }
    }
    let helpArray2 = [];
    if (!isEmpty(companyData) && !isEmpty(similarExperts)) {
      for (let i = 0; i < similarExperts.length; i++) {
        if (similarExperts[i].id != companyData[0].id) {
          helpArray.push(similarExperts[i]);
        }
      }
    }
    setSearchResults2(searchResults);
    setSimilarExperts2(helpArray2);
  }, [searchResults, companyData, similarExperts]);

  return (
    <div className="search__">
      <div className="search__fixedTopBar">
        <div className="search__fixedTopBar_searchBar">
          <div className="search__fixedTopBar_searchBar_icon">
            <SearchIcon />
          </div>
          <input
            value={searchInput}
            className="search__fixedTopBar_searchBar_input"
            placeholder="Suche eingeben"
            onChange={(e) => setSearchInput(e.target.value)}
          />
          {searchInput != "" ? (
            <div className="search__fixedTopBar_searchBar_closeButton">
              <CloseIcon onClick={() => handleCloseButtonClicked()} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="search__scrollContent">
        {searchInput == "" && isEmpty(authToken) ? (
          <div className="search__scrollContent_contentbox">
            <div className="search__scrollContent_contentbox_title">
              Häufige Suchbegriffe
            </div>
            <div
              className="search__scrollContent_contentbox_ownTag"
              onClick={() => setSearchInput("Schmerz")}
            >
              <Tag type={"search"} text={"Schmerz"} />
            </div>
            <div
              className="search__scrollContent_contentbox_ownTag"
              onClick={() => setSearchInput("Psychosomatik")}
            >
              <Tag type={"search"} text={"Psychosomatik"} />
            </div>
            <div
              className="search__scrollContent_contentbox_ownTag"
              onClick={() => setSearchInput("Therapie")}
            >
              <Tag type={"search"} text={"Therapie"} />
            </div>
          </div>
        ) : (
          <></>
        )}
        {searchInput == "" && !isEmpty(authToken) ? (
          <>
            <div className="search__scrollContent_contentbox">
              <div className="search__scrollContent_contentbox_title">
                Deine Themen
              </div>
              <>
                {!isEmpty(tagData1[0]) ? (
                  <div
                    className="search__scrollContent_contentbox_ownTag"
                    onClick={() => setSearchInput(tagData1[0].title)}
                  >
                    <TagFetched tagData={tagData1} />
                  </div>
                ) : (
                  <></>
                )}
                {!isEmpty(tagData2[0]) ? (
                  <div
                    className="search__scrollContent_contentbox_ownTag"
                    onClick={() => setSearchInput(tagData2[0].title)}
                  >
                    <TagFetched tagData={tagData2} />
                  </div>
                ) : (
                  <></>
                )}
                {!isEmpty(tagData3[0]) ? (
                  <div
                    className="search__scrollContent_contentbox_ownTag"
                    onClick={() => setSearchInput(tagData3[0].title)}
                  >
                    <TagFetched tagData={tagData3} />
                  </div>
                ) : (
                  <></>
                )}
              </>
            </div>
            <div className="search__scrollContent_contentbox">
              <div className="search__scrollContent_contentbox_title">
                Dir ähnliche Experten
              </div>
              <>
                {!isEmpty(similarExperts2) ? (
                  <>
                    {similarExperts2?.map((result) => (
                      <SearchResultExpert
                        data={result}
                        key={"similarExpert" + result.id}
                        pathFromSearch={pathFromSearch}
                        setPathFromSearch={setPathFromSearch}
                        tagData1={tagData1}
                        tagData2={tagData2}
                        tagData3={tagData3}
                      />
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            </div>
          </>
        ) : (
          // wenn die suche objekte gefunden hat
          <>
            {searchResults2.map((result) => (
              <SearchResultExpert
                data={result}
                key={"searchResult" + result.id}
                pathFromSearch={pathFromSearch}
                setPathFromSearch={setPathFromSearch}
                tagData1={tagData1}
                tagData2={tagData2}
                tagData3={tagData3}
              />
            ))}
            {!isEmpty(authToken) &&
            !isEmpty(companyData) &&
            !isEmpty(searchFeedData) ? (
              searchFeedData.map((feed) => (
                <PostSingle
                  authToken={authToken}
                  key={"post" + feed.id}
                  feed={feed}
                  type="question"
                  userData={userData}
                  companyData={companyData}
                  numberOfPosts={numberOfPosts}
                  setNumberOfPosts={setNumberOfPosts}
                  openArticle={openArticle}
                  setOpenArticle={setOpenArticle}
                  activeArticle={activeArticle}
                  setActiveArticle={setActiveArticle}
                  companyAnswers={companyAnswers}
                  setCompanyAnswers={setCompanyAnswers}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  likes={likes}
                  setLikes={setLikes}
                  refreshLikes={refreshLikes}
                  setRefreshLikes={setRefreshLikes}
                  refreshTags={refreshTags}
                  setRefreshTags={setRefreshTags}
                />
              ))
            ) : (
              <></>
            )}
          </>
        )}
        {mobile && isEmpty(authToken) ? (
          <div
            className="search__infoLinks"
            onClick={() => setOpenSettings(true)}
          >
            <div className="sidebar__footer_links">Impressum. Datenschutz.</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 25px;
  color: #575757;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 20px;
  color: #000000;
  margin-top: -1px;
  margin-left: 3px;
`;

const CloseIcon = styled(MdClose)`
  font-size: 30px;
  color: #575757;
`;
