import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../functions/isEmpty";
import { MdAdd, MdFilterList } from "react-icons/md";

import "../styles/Contacts.css";
import ContactPreview from "../components/contacts/ContactPreview";
import { useNavigate } from "react-router";

export default function Contacts({
  authToken,
  setCreateNewQuestion,
  feedData,
  userData,
  companyData,
  numberOfPosts,
  setNumberOfPosts,
  openArticle,
  setOpenArticle,
  activeArticle,
  setActiveArticle,
  companyAnswers,
  setCompanyAnswers,
}) {
  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/search");
    }
  }, [authToken]);

  const openCreateNewQuestionView = () => {
    setCreateNewQuestion(true);
  };

  // userdata in state packen
  const renderFeed = () => {
    if (1 > 2) {
      return feedData.map((feed) => (
        <contactPreview
          authToken={authToken}
          key={"post" + feed.id}
          feed={feed}
          type="question"
          userData={userData}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
        />
      ));
    } else {
      return (
        <ContactPreview
          authToken={authToken}
          key={"post"}
          type="question"
          userData={userData}
          companyData={companyData}
          numberOfPosts={numberOfPosts}
          setNumberOfPosts={setNumberOfPosts}
          openArticle={openArticle}
          setOpenArticle={setOpenArticle}
          activeArticle={activeArticle}
          setActiveArticle={setActiveArticle}
          companyAnswers={companyAnswers}
          setCompanyAnswers={setCompanyAnswers}
        />
      );
    }
  };

  return (
    <div className="contacts">
      <div className="fragen__fixedTopBar">
        <div className="fragen__fixedTopBar_leftColumn">
          <div className="fragen__fixedTopBar_leftColumn_topText">Kontakte</div>
        </div>
        <div className="fragen__fixedTopBar_middleColumn"></div>
        {/*  
        <div className="fragen__fixedTopBar_rightColumn">
          <div className="fragen__fixedTopBar_rightColumn_sortButton">
            <div className="fragen__fixedTopBar_rightColumn_sortButton_icon">
             
             <FilterIcon />  
             
            </div>
            <div className="fragen__fixedTopBar_rightColumn_sortButton_text"></div>
          </div>
        </div>
        */}
      </div>
      <div className="fragen__scrollContent">
        { renderFeed() }
      </div>
    </div>
  );
}
const AddIcon = styled(MdAdd)`
  font-size: 20px;
  color: #575757;
  margin-top: 4px;
`;

const FilterIcon = styled(MdFilterList)`
  font-size: 20px;
  color: #575757;
  margin-top: 1px;
`;
